import { Component } from 'react';
import { ChatList } from 'react-chat-elements';
import { withTranslation } from 'react-i18next';

const initalLength = 24;

class Chatlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatLength: initalLength,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.conversations.length
      && nextProps.conversations.length
      && this.props.conversations.length === nextProps.conversations.length
      && this.props.conversations[0].date === nextProps.conversations[0].date
      && this.state.chatLength === nextState.chatLength
    ) {
      return false;
    }
    return true;
  }

  makeactive = (conversation) => {
    if (conversation.id === -1) {
      this.setState((prevState) => ({ chatLength: prevState.chatLength + initalLength }));
    } else {
      this.props.makeactive(conversation);
    }
  };

  render() {
    const { t } = this.props;
    const loadMore = [
      {
        id: -1,
        title: t('Chatlist.moreconversations'),
        date: '',
        avatar: '/downarrow.svg',
      },
    ];
    let conversationList = [];
    if (this.props.conversations.length > this.state.chatLength) {
      conversationList = [
        ...this.props.conversations.slice(0, this.state.chatLength),
        ...loadMore,
      ];
    } else {
      conversationList = this.props.conversations;
    }
    return this.props.conversations.length ? (
      <ChatList
        className={`chat-list${this.props.className}`}
        dataSource={conversationList}
        onClick={(e) => this.makeactive(e)}
      />
    ) : (
      <p>{t('Chatlist.noconversations')}</p>
    );
  }
}

export default withTranslation()(Chatlist);
