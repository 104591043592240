import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { apiPost } from './requests';

export const initializeFCM = () => {
  const firEnv = process.env.REACT_APP_FIR_MESSAGING === '37267869948' ? '-dev' : '';
  return navigator.serviceWorker
    .register(`/firebase-messaging-sw${firEnv}.js`)
    .then((registration) => {
      firebase.messaging().useServiceWorker(registration);
      firebase.messaging().onMessage((payload) => console.log('push: ', payload));
      return firebase.messaging().getToken();
    });
};

export const askForPermissionToReceiveNotifications = async (existingToken) => {
  try {
    const messaging = firebase.messaging();
    const token = await messaging.getToken();
    if (token !== existingToken) {
      apiPost('/device/add', {
        os: 'web',
        device_token: token,
      });
    }
    return token;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getFCMToken = () => {
  const messaging = firebase.messaging();
  return messaging.getToken();
};
