import { useTranslation, withTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createRef } from 'react';
import AlertDialog from './AlertDialog';

const WrappedAlertDialog = withTranslation('translation', { withRef: true })(
  AlertDialog,
);

function PricingDynamic({
  selectPlan,
  selected,
  products = [],
  skills,
  productType = null,
}) {
  const { t } = useTranslation();
  const skillsDialog = createRef();
  const isSelectedClass = (plan) => {
    let classText = 'pricing-plan-card';
    if (
      plan
      && selected
      && plan.apple_product_id === selected.apple_product_id
    ) {
      classText += ' pricing-plan-selected';
    }
    return classText;
  };
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  if (products.length === 0) {
    return (
      <h2>
        {t('PricingCredits.loading')}
        ...
      </h2>
    );
  }
  return (
    <div id="pricing_holder">
      <WrappedAlertDialog
        ref={skillsDialog}
        message={{
          title: t('PricingCredits.skillsΝumberΤitle'),
          text: (
            <>
              { skills.map((item) => (
                <span className="skills-table" key={item.display_name}>
                  <span>{item.display_name}</span>
                  <span>{`${item.shortlists_to_consume} ${t('PricingCredits.shortListsLabel')}`}</span>
                </span>
              ))}
            </>
          ),
          confirm: t('ReferFriend.alertBtn'),
          confirmColor: 'primary',
        }}
        fullScreen={isMobile}
        hideCancel
        onConfirm={() => false}
      />
      {products.map((p) => (
        <Card
          key={p.apple_product_id}
          className={isSelectedClass(p)}
          onClick={() => selectPlan(p)}
          raised
          style={{
            flex: 1,
            marginTop: '0px',
            marginBottom: '20px',
            paddingTop: '20px',
            paddingBottom: '25px',
            color: '#555555',
          }}
        >
          <CardContent>
            <h3 className="pricingName">
              {t(`PricingCredits.${productType}`, {
                count: p.job_credits || p.extra_shortlists,
              })}
            </h3>
            <h3 className="pricing-price">
              €
              {(Math.round(p.price_without_vat * 100) / 100).toFixed(2)}
            </h3>
            <span className="pricingSubtitle">
              +
              {t('PricingCredits.vat')}
            </span>
            {productType === 'job' ? (
              <>
                <span className="pricing-features">
                  {t('PricingCredits.positionfordays', {
                    count: p.job_credits,
                    days: p.days_duration,
                  })}
                </span>
                <button
                  type="button"
                  className="skills-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    skillsDialog.current.handleClickOpen(e);
                  }}
                >
                  {t('PricingCredits.checkSkillsNumber')}
                </button>
              </>
            ) : (
              false
            )}
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              size="medium"
              className="pricing-button"
            >
              {t('PricingCredits.select')}
            </Button>
          </CardActions>
        </Card>
      ))}
      <div className="clearfix" />
    </div>
  );
}

export default PricingDynamic;
