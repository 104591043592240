import { Component, createRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import UserManage from '../Components/UserManage';
import ReferFriend from '../Components/ReferFriend';
import { apiPost } from '../Lib/requests';

function messages(t) {
  return {
    on_trial: {
      title: t('Profile.on_trial'),
    },
    trial_over: {
      title: t('Profile.trial_over'),
    },
    has_credits: {
      title: t('Profile.has_credits'),
    },
    no_credits: {
      title: t('Profile.no_credits'),
    },
    banned: {
      title: t('Profile.banned'),
    },
    none: {
      title: '',
    },
  };
}

const WrappedUserManage = withTranslation('translation', { withRef: true })(
  UserManage,
);

class Profile extends Component {
  constructor(props) {
    super(props);

    // this.manageUsersButton = createRef();
    this.manageUserDialog = createRef();
    this.handleUserEdit = this.handleUserEdit.bind(this);
    // this.closeSnackBar = this.resetSnackBar.bind(this);
  }

  handleUserEdit = () => {
    this.props.refreshUser();
  };

  handleClickOpen = (editUser = false) => {
    if (this.props.user.allow_multiuser === true) {
      this.manageUserDialog.current.handleClickOpen(editUser);
    } else {
      this.props.showCredits(null, 'multiuser');
    }
  };

  changeLang = (e) => {
    const lang = e.target.value;
    this.props.i18n.changeLanguage(lang);
    this.props.moment.locale(lang);
    apiPost('/user/set_locale', { locale: lang }).catch();
  };

  render() {
    const {
      user, showCredits, history, companyUsers, t,
    } = this.props;
    const companies = [user.default_company, ...user.companies];
    let { language } = this.props.i18n;
    if (language && language.includes('-')) {
      // eslint-disable-next-line prefer-destructuring
      language = language.split('-')[0];
    }
    return (
      <>
        {companies && companies.length ? (
          <WrappedUserManage
            ref={this.manageUserDialog}
            onSuccess={this.handleUserEdit}
            showCredits={this.props.showCredits}
            user={this.props.user}
            companies={companies}
          />
        ) : false}

        <div>
          <h2 style={{ float: 'left' }}>{t('Profile.heading')}</h2>
          <button
            onClick={() => history.push('/signout')}
            style={{
              background: 'none',
              border: 0,
              float: 'right',
              color: '#26a2f0',
              textDecoration: 'none',
              cursor: 'pointer',
              paddingTop: '16px',
              fontSize: '16px',
            }}
            type="button"
            onKeyDown={this.handleKeyDown}
          >
            {t('Profile.logout')}
          </button>
          <div style={{ clear: 'both' }} />
          <ReferFriend
            referralCode={this.props.user.referral_code}
            referral_url={this.props.user.referral_url}
          />
          <Avatar
            alt="profile"
            src={user.photo_url}
            className="application-details-photo"
          />
          <h2 className="application-details-name">
            {`${user.first_name} ${user.last_name}`}
          </h2>
          <div style={{ clear: 'both' }} />
          <button
            onClick={() => showCredits()}
            type="button"
            tabIndex={0}
            style={
              {
                cursor: 'pointer',
                background: 'none',
                fontSize: '18px',
                border: 0,
                padding: '0px',
                margin: '15px 0',
                fontWeight: 'bold',
              }
            }
          >
            {t('Profile.accountstatus')}
            {' '}
            <span style={{ fontWeight: 'normal', color: '#26a2f0' }}>
              {messages(t)[user.status_as_client].title}
            </span>
          </button>
          <h3>
            {t('Profile.language')}
            :
            {' '}
            <FormControl>
              <Select
                value={language}
                onChange={this.changeLang}
                variant="standard"
                // style={{ minWidth: "150px" }}
              >
                <MenuItem value="el">
                  <span role="img" aria-label="greek flag">
                    🇬🇷
                    {' '}
                  </span>
                  {' '}
                  Ελληνικά
                </MenuItem>
                <MenuItem value="it">
                  <span role="img" aria-label="italian flag">
                    🇮🇹
                    {' '}
                  </span>
                  {' '}
                  Italiano
                </MenuItem>
                <MenuItem value="en">
                  <span role="img" aria-label="english flag">
                    🇬🇧
                    {' '}
                  </span>
                  {' '}
                  English
                </MenuItem>
              </Select>
            </FormControl>
          </h3>
          <div style={{ overflow: 'hidden' }}>
            <h3>{t('Profile.activecompanies')}</h3>
            {user.is_superuser ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push('/company/create/')}
                size="large"
                className="manageUsersButton"
                style={{ float: 'right', marginBottom: '24px' }}
              >
                {t('Profile.addbusiness')}
              </Button>
            ) : false}
            <br style={{ clear: 'both' }} />
            {companies && companies.length > 0 ? (
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('Profile.companyname')}</TableCell>
                      <TableCell>{t('Profile.companyaddress')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {companies.map((c) => (
                      <TableRow key={c.id}>
                        <TableCell
                          style={{ cursor: 'pointer', color: '#26a2f0' }}
                          onClick={() => history.push(`/company/edit/${c.id}`)}
                        >
                          {c.name}
                          {/* <EditIcon /> */}
                        </TableCell>
                        <TableCell>{c.location.address_text}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            ) : false}
          </div>
          {user.allow_multiuser && user.is_superuser ? (
            <div style={{ overflow: 'hidden', marginTop: '32px' }}>
              <h3>{t('Profile.manageusers')}</h3>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handleClickOpen()}
                size="large"
                className="manageUsersButton"
                style={{ float: 'right', marginBottom: '24px' }}
              >
                {t('Profile.adduser')}
              </Button>
              <br style={{ clear: 'both' }} />
              {companyUsers ? (
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('Profile.username')}</TableCell>
                        <TableCell>{t('Profile.numbercompanies')}</TableCell>
                        <TableCell>{t('Profile.isadmin')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {companyUsers.map((u) => (
                        <TableRow key={u.id}>
                          <TableCell
                            style={
                              u.id === user.id
                                ? { cursor: 'default', color: '#555' }
                                : { cursor: 'pointer', color: '#26a2f0' }
                            }
                            onClick={() => (u.id === user.id
                              ? false
                              : this.handleClickOpen(u))}
                          >
                            {u.first_name}
                            {' '}
                            {u.last_name}
                          </TableCell>
                          <TableCell>{u.companies_managing.length}</TableCell>
                          <TableCell>{u.is_superuser ? '✔' : ''}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              ) : false}
            </div>
          ) : false}
        </div>
      </>
    );
  }
}

export default withTranslation()(Profile);
