export default {};

export const openCrispChatbot = () => {
  if (window.$crisp === undefined) return;

  window.$crisp.push(['do', 'chat:open']);
};

export const hideCrispChatBox = () => {
  if (window.$crisp === undefined) return;

  window.$crisp.push(['do', 'chat:hide']);
};

export const showCrispChatBox = () => {
  if (window.$crisp === undefined) return;

  window.$crisp.push(['do', 'chat:show']);
};

export const resetCrispSession = (tokenId = null, force = false) => {
  if (window.$crisp === undefined) return;

  if ((window.CRISP_TOKEN_ID === tokenId) && !force) return;

  window.CRISP_TOKEN_ID = tokenId;

  window.$crisp.push(['do', 'session:reset']);
};

export const crispChangeEmail = (email, signature = null) => {
  if (window.$crisp === undefined) return;

  try {
    if (signature) {
      window.$crisp.push(['set', 'user:email', [email, signature]]);
    } else {
      window.$crisp.push(['set', 'user:email', [email]]);
    }
  } catch (e) {
    // continue regardless of error
  }
};

export const crispChangeName = (name) => {
  if (window.$crisp === undefined) return;

  try {
    window.$crisp.push(['set', 'user:nickname', [name]]);
  } catch (e) {
    // continue regardless of error
  }
};

export const crispSetSessionData = (data) => {
  if (window.$crisp === undefined) return;

  try {
    const sessionData = [];
    Object.keys(data).forEach((key) => {
      const value = data[key];
      sessionData.push([key, value]);
    });

    window.$crisp.push(['set', 'session:data', [sessionData]]);
  } catch (e) {
    // continue regardless of error
  }
};

export const crispSetSessionEvent = (eventName, params) => {
  if (window.$crisp === undefined) return;

  try {
    window.$crisp.push(['set', 'session:event', [[[eventName, params]]]]);
  } catch (e) {
    // continue regardless of error
  }
};

export const crispShowMessage = (message) => {
  if (window.$crisp === undefined) return;

  try {
    window.$crisp.push(['set', 'message:text', [message]]);
    window.$crisp.push(['do', 'chat:open']);
  } catch (e) {
    // continue regardless of error
  }
};
