import React from 'react';
import PropTypes from 'prop-types';
import Hls from 'hls.js';

class ReactHls extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playerId: Date.now(),
    };

    this.hls = null;
  }

  componentDidMount() {
    this.initPlayer();
  }

  componentDidUpdate() {
    this.initPlayer();
  }

  componentWillUnmount() {
    const { hls } = this;

    if (hls) {
      hls.destroy();
    }
  }

  initPlayer() {
    if (this.hls) {
      this.hls.destroy();
    }

    const { url, autoplay, hlsConfig } = this.props;
    const { video } = this.video;
    const hls = new Hls(hlsConfig);

    hls.loadSource(url);
    hls.attachMedia(video);
    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      if (autoplay) {
        video.play();
      }
    });

    this.hls = hls;
  }

  render() {
    const { playerId } = this.state;
    const {
      controls, width, height, poster, videoProps, onPlay,
    } = this.props;

    return (
      <div key={playerId} className="player-area">
        <video
          ref={(video) => { this.video = video; }}
          className="hls-player"
          id={`react-hls-${playerId}`}
          controls={controls}
          width={width}
          height={height}
          poster={poster}
          onPlay={onPlay}
        >
          <track kind="captions" {...videoProps} />
        </video>
      </div>
    );
  }
}

ReactHls.defaultProps = {
  autoplay: false,
  hlsConfig: {},
  controls: true,
  width: 500,
  height: 310,
  poster: '',
  videoProps: {},
  onPlay() {},
};

ReactHls.propTypes = {
  url: PropTypes.string.isRequired,
  autoplay: PropTypes.bool,
  hlsConfig: PropTypes.instanceOf(Object), // https://github.com/dailymotion/hls.js/blob/master/API.md#fine-tuning
  controls: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.number,
  poster: PropTypes.string,
  videoProps: PropTypes.instanceOf(Object),
  onPlay: PropTypes.func,
};

export default ReactHls;
