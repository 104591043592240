import { Component, createRef, forwardRef } from 'react';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Checkbox from '@mui/material/Checkbox';
import Slide from '@mui/material/Slide';
import { apiPost } from '../Lib/requests';
import { hideCrispChatBox, showCrispChatBox } from '../Lib/crispUtils';

const Transition = forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

class UserManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      user: {
        id: false,
        email: '',
        is_superuser: false,
        companies_managing: [],
      },
    };
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.alertDialog = createRef();
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleClickOpen = (editUser = false) => {
    if (editUser) {
      this.setState({
        user: editUser,
        open: true,
      });
    } else {
      this.setState({
        open: true,
      });
    }

    if (window.innerWidth < 800) {
      hideCrispChatBox();
    }
  };

  handleClose = () => {
    this.setState({
      user: {
        id: false,
        email: '',
        is_superuser: false,
        companies_managing: [],
      },
      open: false,
    });

    showCrispChatBox();
  };

  handleChange = (name) => (e) => {
    e.persist();
    if (name === 'company') {
      if (e.target.checked === true) {
        this.setState((prevState) => ({
          user: {
            ...prevState.user,
            companies_managing: [
              ...prevState.user.companies_managing,
              +e.target.value,
            ],
          },
        }));
      } else {
        this.setState((prevState) => ({
          user: {
            ...prevState.user,
            companies_managing: prevState.user.companies_managing.filter(
              (c) => c !== +e.target.value,
            ),
          },
        }));
      }
    } else if (name === 'user.is_superuser') {
      const namekeys = name.split('.');
      this.setState((prevState) => ({
        [namekeys[0]]: {
          ...prevState[namekeys[0]],
          [namekeys[1]]: e.target.checked,
        },
      }));
    } else {
      const namekeys = name.split('.');
      this.setState((prevState) => ({
        [namekeys[0]]: {
          ...prevState[namekeys[0]],
          [namekeys[1]]: e.target.value,
        },
      }));
    }
  };

  handleSubmit = () => {
    const edit = this.state.user.id;
    const endpoint = edit ? '/user/edit_employer_companies' : '/user/invite';
    const payload = this.state.user;
    if (edit) {
      payload.user_id = this.state.user.id;
    }
    apiPost(endpoint, payload, 'v1')
      .then(() => {
        this.props.onSuccess(
          edit
            ? this.props.t('UserManage.edituser')
            : this.props.t('UserManage.newuser'),
        );
        this.handleClose(true);
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 402) {
          this.props.showCredits(null, 'multiuser');
        }
        this.handleClose(true);
      });
  };

  render() {
    const { t } = this.props;
    const { companies } = this.props;
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          TransitionComponent={Transition}
        >
          <DialogTitle id="form-dialog-title">
            {!this.state.user.id
              ? t('UserManage.adduserTitle')
              : t('UserManage.edituserTitle')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginBottom: '24px' }}>
              {!this.state.user.id
                ? t('UserManage.addemail')
                : t('UserManage.editemail')}
            </DialogContentText>

            <TextField
              // id="multiline-flexible"
              style={{ width: '80%', marginRight: '16px' }}
              label="Email"
              value={this.state.user.email}
              placeholder="name@company.com"
              onChange={this.handleChange('user.email')}
              margin="normal"
              disabled={!!this.state.user.id}
              className="data-hj-whitelist"
            />

            <br />
            <FormControlLabel
              className="renewJob"
              control={(
                <Switch
                  checked={this.state.user.is_superuser}
                  onChange={this.handleChange('user.is_superuser')}
                  value="active"
                  color="primary"
                />
              )}
              label={t('UserManage.isadmin')}
            />
            <br />
            <br />
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {t('UserManage.companies')}
              </FormLabel>
              <br />
              {companies && companies.length ? (
                <FormGroup style={{ textAlign: 'left' }}>
                  {companies.map((c) => (
                    <FormControlLabel
                      key={c.id}
                      control={(
                        <Checkbox
                          checked={
                            this.state.user
                            && this.state.user.companies_managing
                            && this.state.user.companies_managing.includes(c.id)
                          }
                          onChange={this.handleChange('company')}
                          disabled={!!this.state.user.is_superuser}
                          value={`${c.id}`}
                        />
                      )}
                      label={c.name}
                    />
                  ))}
                </FormGroup>
              ) : false}
              {/* <FormHelperText>Be careful</FormHelperText> */}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {t('UserManage.cancel')}
            </Button>
            <Button
              onClick={this.handleSubmit}
              color="primary"
              variant="contained"
              disabled={
                !(
                  this.state.user.email
                  && (this.state.user.companies_managing.length
                    || this.state.user.is_superuser)
                )
              }
            >
              {!this.state.user.id ? t('UserManage.add') : t('UserManage.edit')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default UserManage;
