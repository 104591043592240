import { Component, forwardRef } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Call from '@mui/icons-material/Call';
import Save from '@mui/icons-material/CloudDownload';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { withTranslation } from 'react-i18next';
import { trackEvent } from '../Lib/analytics';
import { apiDownload } from '../Lib/requests';
import ReactHLS from './VideoHls';
import { hideCrispChatBox, showCrispChatBox } from '../Lib/crispUtils';
// import "moment/locale/el";
// import "moment/locale/it";

const Transition = forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

function durationToText(durationIndex, t) {
  switch (durationIndex) {
    case 0:
      return t('ApplicationDetails.less6months');
    case 1:
      return t('ApplicationDetails.between612months');
    case 2:
      return t('ApplicationDetails.between13years');
    case 3:
      return t('ApplicationDetails.between35years');
    case 4:
      return t('ApplicationDetails.less5years');
    default:
      return '';
  }
}

class ApplicationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      justShortlisted: false,
      profile: {
        name: '',
      },
    };
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    showCrispChatBox();
    this.setState({ open: false, profile: false, justShortlisted: false });
    this.props.closer();
  };

  handleShortlist = (id) => {
    // this.handleClose();
    this.setState({ justShortlisted: true });
    this.props.shortlist(id);
  };

  handleReject = (id) => {
    this.handleClose();
    this.props.reject(id);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      (nextProps.profile === prevState.profile && prevState.open === false)
      || !nextProps.profile
    ) {
      return { open: false };
    }

    // this code gets fired when the modal opens
    if (window.innerWidth < 800) {
      hideCrispChatBox();
    }

    trackEvent('EMP_Action_ViewCandidateProfile', {
      user_id: nextProps.user.id,
      seeker: nextProps.profile.id,
      has_video: !!nextProps.profile.video_url,
      has_complete_profile:
        !!nextProps.profile.video_url
        && !!nextProps.profile.tagline
        && nextProps.profile.spoken_languages.length
        && !!nextProps.profile.education
        && nextProps.profile.experience.length,
    });
    return { profile: nextProps.profile, open: true };
  }

  playVideo = () => {
    trackEvent('EMP_Action_PlaySeekerVideo', {
      user_id: this.props.user.id,
      seeker: this.props.profile.id,
      source: 'details',
    });
  };

  downloadCV = () => {
    if (!this.props.ispro) {
      return false;
    }
    const { profile } = this.props;
    apiDownload('/job_seeker/cv_pdf', {
      job_seeker_id: profile.id,
    })
      .then((res) => {
        const file = new Blob([res.data], {
          type: 'application/pdf',
        });
        saveAs(
          file,
          `MyJobNow-CV-${
            profile.first_name
          }-${
            profile.last_name
          }.pdf`,
          { autoBom: true },
        );
        // const fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      })
      .catch(() => {
        console.log("can't download pdf");
      });
    return null;
  };

  getApplicantName = () => {
    const { first_name: firstName, last_name: lastName, isPending } = this.props.profile;
    if (isPending) {
      return firstName;
    }

    return `${firstName} ${lastName}`;
  };

  render() {
    const { t } = this.props;
    const { profile } = this.state;
    const timeLeft = (exp) => {
      const now = new Date();
      const expTime = new Date(exp);
      const minsLeft = (expTime.getTime() - now.getTime()) / (1000 * 60);
      const hoursLeft = Math.floor(minsLeft / 60);
      if (hoursLeft < 0) return t('ApplicationDetails.soon');
      if (hoursLeft > 1) {
        return (
          t('ApplicationDetails.timein')
          + hoursLeft
          + t('ApplicationDetails.hours')
        );
      }
      if (hoursLeft === 1) {
        return (
          t('ApplicationDetails.timein')
          + hoursLeft
          + t('ApplicationDetails.hour')
        );
      }
      return (
        t('ApplicationDetails.timein')
        + Math.floor(minsLeft)
        + t('ApplicationDetails.minutes')
      );
    };
    const getAge = (dob) => {
      const ageDifMs = Date.now() - new Date(dob);
      const ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    };
    const showLanguage = (lang) => {
      let displayLanguageText = lang.display_name;
      if (lang.knowledge_level) {
        displayLanguageText
          += ` - ${t(`ApplicationDetails.langlevel${lang.knowledge_level}`)}`;
      }
      return displayLanguageText;
    };
    const getExperienceDuration = (start, end, oldDuration) => {
      if (!start) {
        return durationToText(oldDuration, t);
      }
      const duration = moment.duration(
        moment(end || new Date()).diff(moment(start)),
      );

      const years = Math.floor(duration.asYears());
      const yearsText = years === 1
        ? t('ApplicationDetails.year')
        : years + t('ApplicationDetails.years');
      let months = Math.floor(duration.asMonths()) % 12;
      if (Math.floor(duration.asDays()) % 365 > 15 && months !== 12) {
        months += 1;
      }
      const monthsText = months === 1
        ? t('ApplicationDetails.month')
        : months + t('ApplicationDetails.months');
      return years ? `${yearsText} ${monthsText}` : monthsText;
    };
    const relevanceColor = (score) => {
      let color = '#f44336';
      if (score > 80) color = '#4caf50';
      else if (score > 60) color = '#ffc107';
      return color;
    };
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        fullWidth
        // maxWidth={""}
        // style={{ width: "300px" }}
      >
        <DialogContent className="application-details">
          {profile.isPending && profile.app_expiring && profile.expires_at ? (
            <div className="expiring warning">
              {t('ApplicationDetails.expires1')}
              {' '}
              {timeLeft(profile.expires_at)}
              {'. '}
              {t('ApplicationDetails.expires2')}
            </div>
          ) : false }
          <div className="application-details-header">
            <a
              href={profile.photo_url}
              target="_blank"
              rel="noopener noreferrer"
              title="full size image"
            >
              <Avatar
                alt="profile"
                src={profile.photo_url}
                className="application-details-photo"
              />
            </a>
            {/* <img
            src={profile.photo_url}
            alt="profile"
            style={{
              marginRight: "24px",
              marginBottom: "24px",
              width: "100px",
              float: "left"
              // borderRadius: "50px"
            }}
          /> */}
            <div style={{ textAlign: 'right' }}>
              <h2 className="application-details-name">
                {this.getApplicantName()}
                {profile.relevance_score !== undefined
                && profile.isShortlisted === false ? (
                  <p
                    style={{
                      color: relevanceColor(profile.relevance_score),
                      fontSize: '14px',
                    }}
                  >
                    {profile.relevance_score}
                    {t('ApplicationDetails.compatibility')}
                  </p>
                  ) : false }
              </h2>
              {profile.location ? (
                <p>
                  <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    {t('ApplicationDetails.area')}
                  </span>
                  {' '}
                  {profile.location.address_text}
                </p>
              ) : false }
              {profile.date_of_birth ? (
                <p>
                  <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    {t('ApplicationDetails.age')}
                  </span>
                  {' '}
                  {getAge(profile.date_of_birth)}
                  <span>
                    {' '}
                    {t('ApplicationDetails.ageold')}
                  </span>
                </p>
              ) : false }
            </div>
          </div>
          {profile.video_url ? (
            <ReactHLS
              width="100%"
              poster={profile.video_thumb_url}
              url={profile.video_url}
              onPlay={this.playVideo}
            />
          ) : false }

          {profile.interaction_history
          && profile.interaction_history.length > 0 ? (
            <>
              <h3>{t('ApplicationDetails.history')}</h3>
              <ul className="few-words">
                {profile.interaction_history.map((h) => (
                  <li key={`${h.company_name}_${h.date}`}>
                    {t(`ApplicationDetails.${h.status}`)}
                    {' - '}
                    {new Date(h.date)
                      .toLocaleDateString(this.props.i18n.language, {
                        month: 'short',
                        year: 'numeric',
                      })
                      .toUpperCase()}
                    {' - '}
                    {h.company_name}
                    {h.skill ? ` - ${h.skill.display_name}` : ''}
                  </li>
                ))}
              </ul>
            </>
            ) : false }

          {profile.tagline ? (
            <>
              <h3>{t('ApplicationDetails.tagline')}</h3>
              <p className="few-words">{profile.tagline}</p>
            </>
          ) : false }
          <div className="application-details-experience-wrapper">
            <h3>{t('ApplicationDetails.experience')}</h3>
            {/* eslint-disable-next-line no-nested-ternary */}
            {profile.experience && profile.experience.length ? (
              <>
                {profile.experience.map((exp) => (
                  <div
                    key={exp.id}
                    className="application-details-experience-container"
                  >
                    <div className="application-details-dates">
                      {exp.start_date ? (
                        <div className="application-details-start-end">
                          {new Date(exp.start_date)
                            .toLocaleDateString(this.props.i18n.language, {
                              month: 'short',
                              year: 'numeric',
                            })
                            .toUpperCase()}
                          {' '}
                          -
                          {' '}
                          {exp.end_date
                            ? new Date(exp.end_date)
                              .toLocaleDateString(this.props.i18n.language, {
                                month: 'short',
                                year: 'numeric',
                              })
                              .toUpperCase()
                            : t('ApplicationDetails.uptonow')}
                        </div>
                      ) : (
                        <div className="application-details-start-end" />
                      )}
                      <div className="application-details-duration">
                        {getExperienceDuration(
                          exp.start_date,
                          exp.end_date,
                          exp.duration,
                        )}
                      </div>
                    </div>
                    <div className="application-details-company-skill">
                      <div className="application-details-company">
                        {exp.start_date ? exp.company_name : ''}
                      </div>
                      <div className="applicationDetailsSkill">
                        {exp.skill.display_name}
                      </div>
                    </div>

                    <div className="applicationDetailsText">
                      {exp.start_date ? exp.a_few_words : exp.company_name}
                      <div className="application-separator" />
                    </div>
                  </div>
                ))}
                {/* <ul className="application-details-experience">
                {profile.experience.map(exp => (
                  <li key={exp.company_name}>
                    <Chip
                      label={exp.skill.display_name}
                      className="application-details-experience-bubble"
                    />
                    <span className="detailsExperienceCompany">
                      {exp.company_name}
                    </span>
                    <span
                      className="detailsExperienceDuration"
                      style={{ float: "right", color: "orange" }}
                    >
                      {durationToText(exp.duration, t)}
                    </span>
                  </li>
                ))}
              </ul> */}
              </>
            ) : profile.declared_no_experience ? (
              <p>{t('ApplicationDetails.noexperience')}</p>
            ) : (
              <p>{t('ApplicationDetails.noexperiencedeclared')}</p>
            )}
          </div>

          {profile.education ? (
            <>
              <h3>{t('ApplicationDetails.education')}</h3>
              {profile.education_level
              && profile.education_level.display_name ? (
                <p>{profile.education_level.display_name}</p>
                ) : false }
              <p>{profile.education}</p>
              <div className="application-separator-long" />
            </>
          ) : false }
          <h3>{t('ApplicationDetails.skills')}</h3>
          {profile.spoken_languages && profile.spoken_languages.length ? (
            <>
              <h4>{t('ApplicationDetails.languages')}</h4>
              {profile.spoken_languages.map((lang) => (
                <Chip
                  key={lang.id}
                  label={showLanguage(lang)}
                  style={{ marginRight: '10px' }}
                />
              ))}
            </>
          ) : false }
          {profile.transportation
          && profile.transportation.available_vehicles
          && profile.transportation.available_vehicles.length ? (
            <>
              <h4>{t('ApplicationDetails.transportation')}</h4>
              {profile.transportation.available_vehicles.map((vehicle) => {
                const greekVehicle = vehicle === 'motorcycle'
                  ? t('ApplicationDetails.motorbike')
                  : t('ApplicationDetails.car');
                return (
                  <Chip
                    key={greekVehicle}
                    label={greekVehicle}
                    style={{ marginRight: '10px' }}
                  />
                );
              })}
            </>
            ) : false }
          {profile.transportation
          && profile.transportation.drivers_license_categories
          && profile.transportation.drivers_license_categories.length ? (
            <>
              <h4>{t('ApplicationDetails.licenses')}</h4>
              {profile.transportation.drivers_license_categories
                .sort()
                .map((license) => {
                  let greekLicense = '';
                  switch (license) {
                    case 'C':
                      greekLicense = t('ApplicationDetails.licenseC');
                      break;
                    case 'D':
                      greekLicense = t('ApplicationDetails.licenseD');
                      break;
                    default:
                      greekLicense = license;
                  }
                  return (
                    <Chip
                      key={greekLicense}
                      label={`${greekLicense}'`}
                      style={{ marginRight: '10px' }}
                    />
                  );
                })}
              {profile.transportation.has_cpc ? (
                <Chip
                  label={t('ApplicationDetails.cpc')}
                  style={{ marginRight: '10px' }}
                />
              ) : false }
              {profile.transportation.has_tacho_card ? (
                <Chip
                  label={t('ApplicationDetails.dtc')}
                  style={{ marginRight: '10px' }}
                />
              ) : false }
            </>
            ) : false }
          { profile.certificates
          && profile.certificates.forklift
          && (profile.certificates.forklift.forklift_license !== null
            || profile.certificates.forklift.forklift_certificate !== null) ? (
              <div style={{ overflowX: 'auto' }}>
                <h4>{t('ApplicationDetails.Forklift')}</h4>
                {profile.certificates.forklift.forklift_license ? (
                  <Chip
                    label={t('ApplicationDetails.forklift_license')}
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                  />
                ) : false }
                {profile.certificates.forklift.forklift_certificate ? (
                  <Chip
                    label={t('ApplicationDetails.forklift_certificate')}
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                  />
                ) : false }
              </div>
            ) : false }
          <div className="application-details-next">
            {profile.isPending
              ? t('ApplicationDetails.applytospeak')
              : profile.conversation
              && t('ApplicationDetails.contactdirectly')}
          </div>
        </DialogContent>
        {profile.isPending ? (
          <DialogActions>
            <Button
              onClick={() => this.handleReject(profile.id)}
              color="secondary"
              size="large"
            >
              {t('ApplicationDetails.reject')}
            </Button>
            <Button
              onClick={() => this.handleShortlist(profile.id)}
              color="primary"
              variant="contained"
              size="large"
            >
              {t('ApplicationDetails.shortlist')}
            </Button>
          </DialogActions>
        ) : false }
        {profile.isShortlisted ? (
          <DialogActions>
            {profile.hideCV !== true ? (
              <Button
                color="primary"
                onClick={this.downloadCV}
                disabled={!this.props.ispro}
              >
                <Save />
                &nbsp;
                {' '}
                {t('ApplicationDetails.cv')}
              </Button>
            ) : false }
            {profile.phone ? (
              <Chip
                avatar={(
                  <Avatar style={{ backgroundColor: 'green', color: '#fff' }}>
                    <Call />
                  </Avatar>
                )}
                label={profile.phone}
                onClick={() => window.location.replace(`tel:${profile.phone}`)}
              />
            ) : false }
            {profile.conversation ? (
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={() => this.props.history.push(`/messages/${profile.conversation}`)}
              >
                {this.state.justShortlisted
                  ? t('ApplicationDetails.contactnow')
                  : t('ApplicationDetails.communication')}
              </Button>
            ) : false }
          </DialogActions>
        ) : false }
      </Dialog>
    );
  }
}

export default withTranslation()(withRouter(ApplicationDetails));
