import { Route, Redirect } from 'react-router-dom';

function GuestRoute({
  isLoading,
  isAuthenticated,
  component: Component,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => (!isAuthenticated ? (
        !isLoading && (
          <Component {...props} {...rest} />
        )
      ) : (
        <Redirect
          to={
            props.location.state
            && props.location.state.from
            && props.location.state.from.pathname
            && props.location.state.from.pathname.indexOf('/signout') !== 0
              ? props.location.state.from.pathname
              : '/jobs'
          }
        />
      ))}
    />
  );
}

export default GuestRoute;
