import { Component, createRef } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/messaging';
import ReactGA from 'react-ga';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import CssBaseline from '@mui/material/CssBaseline';
import moment from 'moment';
import MediaQuery from 'react-responsive';
import 'typeface-ubuntu';
import './App.css';
import LinearProgress from '@mui/material/LinearProgress';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import GuestRoute from './HOC/GuestRoute';
import ProtectedRoute from './HOC/ProtectedRoute';

import GAnalytics from './Components/GAnalytics';
import { trackEvent } from './Lib/analytics';
import 'moment/locale/el';

import 'moment/locale/it';
import { apiGet, apiPost, apiGetMulti } from './Lib/requests';
import Header from './Components/Header';
import Navbar from './Components/Navbar';
// import MobileScreen from "./Components/MobileScreen";
import Jobs from './Pages/Jobs';
import Messages from './Pages/Messages';
import Interviews from './Pages/Interviews';
import Profile from './Pages/Profile';
import Payments from './Pages/Payments';
import NewCompany from './Pages/NewCompany';
import Signup from './Pages/Signup';
import Signin from './Pages/Signin';
import Signout from './Pages/Signout';
import { Verify } from './Pages/Verify';

import { ErrorPage } from './Pages/ErrorPage';
import CreditsNotice from './Components/CreditsNotice';
import { askForPermissionToReceiveNotifications } from './Lib/pushnotifications';
import {
  crispChangeEmail,
  crispChangeName,
  crispSetSessionData,
  resetCrispSession,
} from './Lib/crispUtils';

ReactGA.initialize(process.env.REACT_APP_GA);
window.GA = ReactGA;

const theme = createTheme({
  palette: {
    primary: {
      main: '#26a2f0',
      contrastText: '#fff',
    },
  },
});

// Initialize Firebase
const config = {
  apiKey: process.env.REACT_APP_FIR_API,
  authDomain: process.env.REACT_APP_FIR_DOMAIN,
  databaseURL: process.env.REACT_APP_FIR_DB,
  projectId: process.env.REACT_APP_FIR_PROJECT,
  storageBucket: process.env.REACT_APP_FIR_STORAGE,
  messagingSenderId: process.env.REACT_APP_FIR_MESSAGING,
  appId: process.env.REACT_APP_FIR_APPID,
};

firebase.initializeApp(config);

function AnalyticsTracker() {
  return <Route component={GAnalytics} />;
}

const WrappedCreditsNotice = withTranslation('translation', { withRef: true })(
  CreditsNotice,
);

const getUrlParams = () => {
  if (window.location && window.location.search) {
    const hashes = window.location.search;
    const params = new URLSearchParams(hashes);
    return Object.fromEntries([...params]);
  }
  return {};
};

class App extends Component {
  firebaseUIConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful.
    // Alternatively you can provide a callbacks.signInSuccess function.
    // signInSuccessUrl: "/profile",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: (e) => {
        if (
          e.additionalUserInfo
          && e.additionalUserInfo.providerId === 'password'
        ) {
          // renew token by resolving promise
          e.user.getIdToken(true).then((token) => this.signinUser(e, token));
        } else {
          this.signinUser(e);
        }
        return false;
      },
      signInFailure: (e) => {
        console.log('SIGNIN ERROR', e);
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      firebaseUser: false,
      user: false,
      isLoading: true,
      fcmToken: false,
      countryCode: null,
      companiesUnread: {},
      unread: {
        applications: 0,
        conversations: 0,
      },
    };
    this.refreshUser = this.refreshUser.bind(this);
    this.showCredits = this.showCredits.bind(this);
    this.refreshToken = this.refreshToken.bind(this);
    this.hideLoader = this.hideLoader.bind(this);
    this.showLoader = this.showLoader.bind(this);
    this.setCountry = this.setCountry.bind(this);

    this.CreditsDialog = createRef();

    moment.locale(this.props.i18n.language);
  }

  componentDidMount() {
    console.log(process.env.REACT_APP_VERSION);

    trackEvent('App_Activate');

    window.refreshToken = this.refreshToken;

    firebase
      .messaging()
      .getToken()
      .then((fcmToken) => {
        if (fcmToken) {
          // console.log("already have token");
          this.setState({ fcmToken });
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        return false;
      });

    this.unreadInterval = setInterval(this.getUnread.bind(this), 30000);
    // Check if user is already logged in when refreshing/reloading page
    firebase.auth().onAuthStateChanged((u) => {
      if (JSON.parse(localStorage.getItem('mjnuser'))) {
        this.setState({ user: JSON.parse(localStorage.getItem('mjnuser')) });

        resetCrispSession(JSON.parse(localStorage.getItem('mjnuser'))?.crisp_token_id);
        this.setAuthenticatedState({ user: u });
        // this.refreshUser(); //update user on page refresh
      } else {
        // handle case of logging in too early on signup
        // eslint-disable-next-line no-lonely-if
        if (this.state.user) {
          this.setAuthenticatedState({ user: u });
        }
      }
      this.hideLoader();
    });
  }

  componentWillUnmount() {
    clearInterval(this.unreadInterval);
    trackEvent('App_Deactivate', { persona: 'employer' });
    try {
      window.FB.AppEvents.clearUserID();
    } catch (e) {
      console.log("Can't shutdown analytics");
    }
  }

  setCountry = (country = 'GR') => {
    this.setState({ countryCode: country });
  };

  setAuthenticatedState(firUser, isInvited = false) {
    this.hideLoader();
    if (firUser && firUser.user) {
      firUser.user
        .getIdToken()
        .then((token) => {
          localStorage.setItem('mjnkodikos', token);
          this.setState({
            isAuthenticated: !!firUser.user,
            firebaseUser: firUser.user,
          });
          this.refreshUser(isInvited);
          this.getUnread();
        })
        .catch(() => this.setAuthenticatedState(false));
    } else {
      this.setState({
        isAuthenticated: false,
        firebaseUser: false,
        user: false,
        unread: {
          applications: 0,
          conversations: 0,
        },
      });
    }
  }

  getUnread() {
    if (this.state.isAuthenticated) {
      //   apiGet("/get_state").then(res => {
      //     if (
      //       res.data.applications !== this.state.unread.applications ||
      //       res.data.conversations !== this.state.unread.conversations
      //     ) {
      //       this.setState({ unread: res.data });
      //     }
      //   });

      apiGet('/state/per_company').then((res) => {
        if (res?.data) {
          let newState = { applications: 0, conversations: 0 };
          let hasStateChanged = false;
          const companiesUnread = {};

          newState = res.data.reduce((acc, cur) => {
            if (
              !this.state.companiesUnread[cur.id]
              || cur.jobs !== this.state.companiesUnread[cur.id].applications
              || cur.conversations
              !== this.state.companiesUnread[cur.id].conversations
            ) {
              hasStateChanged = true;
            }
            companiesUnread[cur.id] = {
              applications: cur.jobs,
              conversations: cur.conversations,
              openJobs: cur.num_open_jobs,
            };
            return {
              applications: acc.applications + cur.jobs,
              conversations: acc.conversations + cur.conversations,
            };
          }, newState);

          if (hasStateChanged) {
            this.setState({
              unread: newState,
              companiesUnread,
            });
          }
        }

        // console.log(newState);
        // console.log(companiesUnread);
      });
    }
  }

  showLoader() {
    this.setState({ isLoading: true });
  }

  hideLoader() {
    this.setState({ isLoading: false });
  }

  refreshToken() {
    this.setAuthenticatedState({ user: this.state.firebaseUser });
    // this.setAuthenticatedState(false);
  }

  // eslint-disable-next-line no-unused-vars
  showCredits(e = null, statusOverride = false, jobId = null) {
    const status = statusOverride === false
      ? this.state.user.status_as_client
      : statusOverride;
    this.CreditsDialog.current.openCreditsInfo(
      status,
      this.state.user.job_credits,
      this.state.user.subscription_expires_at,
      jobId,
    );
  }

  refreshUser(onboarded = false, askForFCM = false) {
    apiGetMulti(['/user/me', '/employer/get_users'], 'v1', 'web').then(
      axios.spread((res, resCompanyUsers) => {
        if (onboarded || askForFCM) {
          askForPermissionToReceiveNotifications(this.state.fcmToken).then(
            // eslint-disable-next-line no-unused-vars
            (token) => {
              // console.log("got the mutha", token);
            },
          );
        }

        const companyUsers = resCompanyUsers?.data || null;

        this.setState((prevState) => {
          const userUpdate = {
            crisp_user_hash: '',
            ...res.data,
            onboarding_done:
              onboarded
              || prevState.user.onboarding_done
              || res.data.onboarding_done,
          };
          resetCrispSession(res.data?.crisp_token_id);
          localStorage.setItem('mjnuser', JSON.stringify(userUpdate));
          return { user: userUpdate, companyUsers };
        });
      }),
    );
  }

  signinUser(firUser, token = null) {
    if (firUser && firUser.user) {
      this.showLoader();
      const jwtInvitation = getUrlParams().invite
        ? getUrlParams().invite
        : null;
      if (firUser.credential === null && token) {
        // check to see if user signed up with email
        firUser.credential = {
          accessToken: token,
          providerId: 'password',
        };
      }

      let { language } = this.props.i18n;
      if (language && language.includes('-')) {
        // eslint-disable-next-line prefer-destructuring
        language = language.split('-')[0];
      }

      let { countryCode } = this.state;
      // Handle null country probably coming from login page
      if (countryCode === null) {
        if (language.includes('el')) {
          countryCode = 'GR';
        } else if (this.props.i18n.language.includes('it')) {
          countryCode = 'IT';
        }
      }

      if (window.branchInit && window.branch && window.branch.first) {
        const that = this;
        window.branch.first((err, branchfirstdata) => {
          let branchLink = null;
          let referralCode = null;
          try {
            const branchMeta = JSON.parse(branchfirstdata.data);
            if (branchMeta && branchMeta.$marketing_title) {
              branchLink = branchMeta.$marketing_title;
              if (branchMeta.referral_code) {
                referralCode = branchMeta.referral_code;
              }
            }
          } catch (tcerr) {
            // continue regardless of error
          }
          window.branch.data((error, branchcurrentdata) => {
            let branchSource = null;
            let branchCampaign = null;
            let branchAdset = null;
            try {
              const branchMeta = JSON.parse(branchcurrentdata.data);
              branchSource = branchMeta.utm_source;
              branchCampaign = branchMeta.utm_campaign;
              branchAdset = branchMeta.utm_content;
              if ((referralCode === null) && branchcurrentdata.referral_code) {
                referralCode = branchcurrentdata.referral_code;
              }
            } catch (tcerr) {
              // continue regardless of error
            }
            apiPost('/user/login', {
              firebase_token: firUser.credential.accessToken,
              provider_id: firUser.credential.providerId,
              locale: language, // "el"
              web_branch: branchLink,
              source: branchSource,
              campaign: branchCampaign,
              adset: branchAdset,
              jwt_invitation: jwtInvitation,
              country_code: countryCode,
            })
              .then((res) => {
                that.setState({
                  user: res.data,
                  country_code: res.data.country_code,
                });
                resetCrispSession(res.data?.crisp_token_id);
                localStorage.setItem('mjnuser', JSON.stringify(res.data));
                that.setAuthenticatedState(firUser, !!jwtInvitation);
                if (referralCode) {
                  apiPost('/user/activate_referral_code', {
                    code: referralCode,
                  });
                }
              })
              .catch(() => that.setAuthenticatedState(false));
          });
        });
      } else {
        apiPost('/user/login', {
          firebase_token: firUser.credential.accessToken,
          provider_id: firUser.credential.providerId,
          locale: language, // "el"
          jwt_invitation: jwtInvitation,
          country_code: countryCode,
          web_branch: null,
          client: 'web',
        })
          .then((res) => {
            this.setState({
              user: res.data,
              countryCode: res.data.country_code,
            });
            resetCrispSession(res.data?.crisp_token_id);
            localStorage.setItem('mjnuser', JSON.stringify(res.data));
            this.setAuthenticatedState(firUser, !!jwtInvitation);
          })
          .catch(() => this.setAuthenticatedState(false));
      }
    } else {
      resetCrispSession();
      this.setAuthenticatedState(false);
    }
  }

  render() {
    const { t } = this.props;
    let fullUserData = null;
    let facebookUser = null;
    let allCompanies = [];
    if (this.state.user.default_company) {
      allCompanies = [
        this.state.user.default_company,
        ...this.state.user.companies,
      ];
    }
    if (this.state.user) {
      const { crisp_user_hash: crispUserHashFromState } = this.state.user;

      fullUserData = {
        email: this.state.user.email,
        name: `${this.state.user.first_name} ${this.state.user.last_name}`,
        user_id: this.state.user.id,
        status_as_client: this.state.user.status_as_client,
        mjn_credits: this.state.user.job_credits,
        user_type: 'employer',
        web_version: process.env.REACT_APP_VERSION,
        db_country: this.state.user.countryCode ?? 'GR', // "GR"
      };
      const crispUserSessionData = {
        user_id: this.state.user.id,
        status_as_client: this.state.user.status_as_client,
        mjn_credits: this.state.user.job_credits,
        user_type: 'employer',
        web_version: process.env.REACT_APP_VERSION,
        db_country: this.state.user.countryCode ?? 'GR', // "GR"
      };
      facebookUser = {
        email: this.state.user.email,
        name: `${this.state.user.first_name} ${this.state.user.last_name}`,
        user_id: this.state.user.id,
        status_as_client: this.state.user.status_as_client,
        mjn_credits: this.state.user.job_credits,
        $user_type: 'employer',
        $language: this.props.i18n.language, // "el"
      };
      try {
        window.bugsnagClient.user = { id: this.state.user.id.toString() };
      } catch (e) {
        console.log('Bugsnag no user');
      }
      try {
        window.FB.AppEvents.setUserID(this.state.user.id.toString());
        window.FB.AppEvents.updateUserProperties({ facebookUser });
      } catch (e) {
        console.log('FB not initialized yet');
      }
      ReactGA.set(fullUserData);

      crispChangeEmail(fullUserData?.email, (crispUserHashFromState ?? null));
      crispChangeName(fullUserData?.name);
      crispSetSessionData(crispUserSessionData);
    }

    const signOut = () => <Signout firebase={firebase} />;

    return (
      <>
        <CssBaseline />
        {/* <MediaQuery maxDeviceWidth={800}>
          <MobileScreen />
        </MediaQuery> */}
        {/* <MediaQuery minDeviceWidth={801}> */}
        <ThemeProvider theme={theme}>
          {this.state.isAuthenticated ? (
            <Header
              isAuthenticated={this.state.isAuthenticated}
              user={this.state.user}
              unread={this.state.unread}
              showCredits={this.showCredits}
            />
          ) : false}
          <Route
            render={(props) => (
              <WrappedCreditsNotice
                {...props}
                ref={this.CreditsDialog}
                user={this.state.user}
              />
            )}
          />
          {this.state.isLoading ? (
            <LinearProgress
              style={{
                flexGrow: 1,
                width: '100%',
                zIndex: '11',
                position: 'fixed',
                top: '0px',
              }}
              color="secondary"
              variant="query"
            />
          ) : false}

          <div id="main_holder">
            {window.outdated ? (
              <button
                style={{
                  backgroundColor: '#ffa000',
                  width: '100%',
                  padding: '8px 16px',
                  borderRadius: '8px',
                  color: '#fff',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  border: 0,
                }}
                onClick={() => window.location.reload(true)}
                type="button"
              >
                {t('App.update')}
              </button>
            ) : false}
            {this.state.user
            && this.state.user.onboarding_done === true
            && this.state.isAuthenticated ? (
              <Switch>
                <Route path="/messages/:id" render={() => false} />
                <Route
                  path="/"
                  render={() => (this.state.user.status_as_client === 'on_trial'
                  || this.state.user.status_as_client === 'trial_over' ? (
                    <button id="trialnotice" onClick={this.showCredits} type="button">
                      {t('App.trialnotice')}
                    </button>
                    ) : false)}
                />
              </Switch>
              ) : false}

            {this.state.user.status_as_client === 'banned' ? (
              <Switch>
                <Route path="/payments" render={() => false} />
                <Route
                  path="/"
                  render={() => (
                    <button id="catch_click" onClick={this.showCredits} type="button" aria-label="Show" />
                  )}
                />
              </Switch>
            ) : false}

            {/* eslint-disable-next-line no-nested-ternary */}
            {this.state.user
            && this.state.user.email_verified === false
            && this.state.isAuthenticated ? (
              <Switch>
                <ProtectedRoute
                  path="/signout"
                  component={signOut}
                  isAuthenticated={this.state.isAuthenticated}
                  user={this.state.user}
                />
                <ProtectedRoute
                  path="/verify"
                  component={Verify}
                  isAuthenticated={this.state.isAuthenticated}
                  user={this.state.user}
                  refreshUser={this.refreshUser}
                  showCredits={this.showCredits}
                  edit={false}
                />
                <Redirect from="/" to="/verify" />
              </Switch>
              ) : this.state.user
            && this.state.user.email_verified === true
            && this.state.user.onboarding_done === false
            && this.state.isAuthenticated ? (
              <Switch>
                <ProtectedRoute
                  path="/signout"
                  component={signOut}
                  isAuthenticated={this.state.isAuthenticated}
                  user={this.state.user}
                />
                <ProtectedRoute
                  path="/company/create"
                  component={NewCompany}
                  isAuthenticated={this.state.isAuthenticated}
                  user={this.state.user}
                  refreshUser={this.refreshUser}
                  showCredits={this.showCredits}
                  edit={false}
                />
                <Redirect from="/" to="/company/create" />
              </Switch>
                ) : (
                  <Switch>
                    <GuestRoute
                      path="/signup"
                      component={() => false}
                      isAuthenticated={this.state.isAuthenticated}
                      isLoading={this.state.isLoading}
                      setCountry={this.setCountry}
                    />
                    <GuestRoute
                      path="/signin"
                      component={() => false}
                      isAuthenticated={this.state.isAuthenticated}
                      isLoading={this.state.isLoading}
                    />
                    <ProtectedRoute
                      path="/jobs"
                      component={Jobs}
                      isAuthenticated={this.state.isAuthenticated}
                      user={this.state.user}
                      markAsRead={() => false}
                      companiesUnread={this.state.companiesUnread}
                      unread={this.state.unread.applications}
                      showCredits={this.showCredits}
                      refreshUser={this.refreshUser}
                      loader={{ show: this.showLoader, hide: this.hideLoader }}
                    />
                    <ProtectedRoute
                      path="/interviews"
                      component={Interviews}
                      isAuthenticated={this.state.isAuthenticated}
                      user={this.state.user}
                      markAsRead={() => false}
                      companiesUnread={this.state.companiesUnread}
                      unread={this.state.unread.applications}
                      showCredits={this.showCredits}
                      refreshUser={this.refreshUser}
                      loader={{ show: this.showLoader, hide: this.hideLoader }}
                    />
                    <ProtectedRoute
                      path="/messages/:id?"
                      component={Messages}
                      isAuthenticated={this.state.isAuthenticated}
                      user={this.state.user}
                      companyUsers={this.state.companyUsers}
                      markAsRead={() => false}
                      companiesUnread={this.state.companiesUnread}
                      unread={this.state.unread.conversations}
                      refreshUser={this.refreshUser}
                      loader={{ show: this.showLoader, hide: this.hideLoader }}
                    />
                    <ProtectedRoute
                      path="/company/create"
                      component={NewCompany}
                      isAuthenticated={this.state.isAuthenticated}
                      user={this.state.user}
                      refreshUser={this.refreshUser}
                      showCredits={this.showCredits}
                      edit={false}
                    />
                    <ProtectedRoute
                      path="/company/edit/:id?"
                      component={NewCompany}
                      isAuthenticated={this.state.isAuthenticated}
                      user={this.state.user}
                      company={this.state.user.default_company}
                      companies={allCompanies}
                      refreshUser={this.refreshUser}
                      edit
                    />
                    <ProtectedRoute
                      path="/profile"
                      component={Profile}
                      isAuthenticated={this.state.isAuthenticated}
                      user={this.state.user}
                      companyUsers={this.state.companyUsers}
                      showCredits={this.showCredits}
                      refreshUser={this.refreshUser}
                      moment={moment}
                    />
                    <ProtectedRoute
                      path="/payments/:type?/:job_id?"
                      component={Payments}
                      isAuthenticated={this.state.isAuthenticated}
                      user={this.state.user}
                      refreshUser={this.refreshUser}
                    />
                    <ProtectedRoute
                      path="/signout"
                      component={signOut}
                      isAuthenticated={this.state.isAuthenticated}
                      user={this.state.user}
                    />
                    <Redirect exact from="/verify" to="/jobs" />
                    <Redirect exact from="/" to="/jobs" />
                    <Route component={ErrorPage} />
                    <Route render={(props) => <ErrorPage {...props} />} />
                  </Switch>
                )}

            {!this.state.isAuthenticated && (
              <Switch>
                <GuestRoute
                  path="/signup"
                  component={Signup}
                  isAuthenticated={this.state.isAuthenticated}
                  isLoading={this.state.isLoading}
                  setCountry={this.setCountry}
                />
                <GuestRoute
                  path="/signin"
                  component={Signin}
                  isAuthenticated={this.state.isAuthenticated}
                  isLoading={this.state.isLoading}
                />
              </Switch>
            )}
            <div
              id="firebase_cont"
              style={{
                display:
                  this.state.isAuthenticated || this.state.isLoading === true
                    ? 'none'
                    : 'flex',
              }}
            >
              <StyledFirebaseAuth
                uiConfig={this.firebaseUIConfig}
                firebaseAuth={firebase.auth()}
              />
            </div>
          </div>
          {this.state.isAuthenticated ? (
            <MediaQuery maxDeviceWidth={800}>
              <Route
                render={(props) => (
                  <Navbar
                    {...props}
                    unread={this.state.unread}
                    user={this.state.user}
                  />
                )}
              />
            </MediaQuery>
          ) : false}
          <AnalyticsTracker />
        </ThemeProvider>
        {/* </MediaQuery> */}
      </>
    );
  }
}

export default withTranslation()(App);
