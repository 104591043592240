import { crispSetSessionEvent } from './crispUtils';

export function trackFBpixel(eventName, params = null) {
  try {
    window.fbq('track', eventName, params);
  } catch (e) {
    console.log('no facebook analytics');
  }
}

function crispEvent(eventName, params) {
  try {
    crispSetSessionEvent(eventName, params);
  } catch (e) {
    console.log('no crisp analytics');
  }
}

function facebookEvent(eventName, params) {
  try {
    window.FB.AppEvents.logEvent(eventName, null, params);
  } catch (e) {
    console.log('no facebook analytics');
  }
}

export function trackFBstandard(eventName = null, params = null) {
  if (!eventName || !window.FB) return;
  const e = window.FB.AppEvents.EventNames[eventName];
  let p = {};
  if (params) {
    Object.keys(params).forEach((k) => {
      const fbp = window.FB.AppEvents.ParameterNames[k];
      if (fbp) {
        p[fbp] = params[k];
      }
    });
  } else {
    p = null;
  }

  facebookEvent(e, p);
}

function googleAnalyticsEvent(eventName) {
  try {
    window.GA.event({ category: 'employer', action: eventName });
  } catch (e) {
    console.log('no google analytics');
  }
}

export function trackEvent(eventName, params = null) {
  if (!eventName) return;
  let newParams = params;
  if (params) {
    newParams.persona = 'employer';
  } else {
    newParams = { persona: 'employer' };
  }
  crispEvent(eventName, newParams);
  facebookEvent(eventName, newParams);
  googleAnalyticsEvent(eventName);
}
