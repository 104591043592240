import { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { apiPost, apiDelete } from '../Lib/requests';

class InterviewFeedbackDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      currentQuestion: 0,
      didtheycome: null,
      review: null,
      report: null,
      hire: null,
      conversationId: null,
    };
  }

  questionsAndAnswers = () => [
    {
      q: 'didtheycome',
      a1: { text: this.props.t('Feedback.yes'), value: true, nextQuestion: 1 },
      a2: { text: this.props.t('Feedback.no'), value: false, nextQuestion: 2 },
    },
    {
      q: 'review',
      a1: {
        text: this.props.t('Feedback.positive'),
        value: true,
        nextQuestion: 3,
      },
      a2: {
        text: this.props.t('Feedback.negative'),
        value: false,
        nextQuestion: 4,
      },
    },
    {
      q: 'report',
      a1: { text: this.props.t('Feedback.yes'), value: true, nextQuestion: 4 },
      a2: { text: this.props.t('Feedback.no'), value: false, nextQuestion: 4 },
    },
    {
      q: 'hire',
      a1: {
        text: this.props.t('Feedback.yes'),
        value: true,
        nextQuestion: null,
      },
      a2: { text: this.props.t('Feedback.no'), value: false, nextQuestion: 4 },
    },
    {
      q: 'delete',
      a1: {
        text: this.props.t('Feedback.yes'),
        value: true,
        nextQuestion: null,
      },
      a2: {
        text: this.props.t('Feedback.no'),
        value: false,
        nextQuestion: null,
      },
    },
  ];

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleClickOpen = (conversationId = null) => {
    this.setState({ open: true, conversationId });
  };

  handleClose = (refresh, submitOnClose = false) => {
    if (submitOnClose) {
      if (this.state.currentQuestion !== 0) {
        this.submitFeedback(null, null);
      }
    }

    this.setState({
      open: false,
      currentQuestion: 0,
      didtheycome: null,
      review: null,
      report: null,
      hire: null,
      conversationId: null,
    });
    if (refresh && this.props.refresh) {
      this.props.refresh();
    }
  };

  handleAnswer = (answer, nextQuestion) => {
    const questionAnswered = this.questionsAndAnswers()[
      this.state.currentQuestion
    ].q;

    if (nextQuestion === null) {
      this.submitFeedback(questionAnswered, answer);
      this.handleClose(true, false);
      return;
    }

    this.setState({
      [questionAnswered]: answer,
      currentQuestion: nextQuestion,
    });
  };

  submitFeedback = (questionAnswered, answer) => {
    apiPost('/interview/feedback', {
      conversation_id: this.state.conversationId || this.props.conversation.id,
      took_place: this.state.didtheycome,
      was_positive: this.state.review,
      did_hire: questionAnswered === 'hire' ? answer : this.state.hire,
    });

    if (this.state.report === true) {
      this.reportUser();
    }

    if (questionAnswered === 'delete' && answer === true) {
      this.deleteConversation();
    }
  };

  reportUser = () => {
    apiPost('/user/report', {
      conversation_id: this.state.conversationId || this.props.conversation.id,
      message: this.props.t('Feedback.noshow'),
    });
  };

  deleteConversation = () => {
    apiDelete('/conversation', {
      conversation_id: this.state.conversationId || this.props.conversation.id,
    }).then(() => {
      this.handleClose(true);
      if (this.props.history) {
        this.props.history.push('/messages');
      }
    });
  };

  render() {
    const { t } = this.props;
    const currentQ = this.questionsAndAnswers()[this.state.currentQuestion];
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t('Feedback.reviewtitle')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t(`Feedback.${currentQ.q}`)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleAnswer(currentQ.a2.value, currentQ.a2.nextQuestion)}
              color="secondary"
            >
              {currentQ.a2.text}
            </Button>
            <Button
              onClick={() => this.handleAnswer(currentQ.a1.value, currentQ.a1.nextQuestion)}
              color="primary"
            >
              {currentQ.a1.text}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default InterviewFeedbackDialog;
