import { Component, createRef } from 'react';
import moment from 'moment';
import CalendarToday from '@mui/icons-material/DateRange';
import { withTranslation } from 'react-i18next';
import { trackEvent } from '../Lib/analytics';
import AlertDialog from './AlertDialog';

const WrappedAlertDialog = withTranslation('translation', { withRef: true })(
  AlertDialog,
);

class CalendarIcon extends Component {
  constructor(props) {
    super(props);
    this.nofirstmessage = createRef();
  }

  render() {
    const { t, isMobile, lastMessage } = this.props;
    let interviewText = t('BookCalendar.interviewText');
    let interviewStatusSymbol = '';
    let interviewColor = 'inherit';
    if (this.props.interview) {
      switch (this.props.interview.status) {
        case 'pending':
          interviewColor = '#ffc107';
          interviewStatusSymbol = '? ';
          break;
        case 'accepted':
          interviewColor = '#388E3C';
          interviewStatusSymbol = '✔ ';
          break;
        case 'rejected':
          interviewColor = '#f50057';
          interviewStatusSymbol = '✘ ';
          break;
        default:
          interviewText = '...';
      }
      if (isMobile) {
        interviewText = moment(this.props.interview.date).format(
          `${interviewStatusSymbol}D/M HH:mm`,
        );
      } else {
        interviewText = moment(this.props.interview.date).format(
          interviewStatusSymbol + t('BookCalendar.dateformat'),
        );
      }
    }

    return (
      <>
        <WrappedAlertDialog
          ref={this.nofirstmessage}
          message={{
            title: t('BookCalendar.nofirstmessageTitle'),
            text: t('BookCalendar.nofirstmessageMsg'),
            confirm: t('BookCalendar.nofirstmessageBtn'),
            confirmColor: 'secondary',
          }}
          hideCancel={false}
          onConfirm={this.props.onClick}
        />
        <div
          onClick={() => {
            trackEvent('EMP_Action_TapSetInterview');
            if (lastMessage) {
              this.props.onClick();
            } else {
              this.nofirstmessage.current.handleClickOpen();
            }
          }}
          role="button"
          tabIndex={0}
          aria-hidden
        >
          <CalendarToday style={{ color: interviewColor }} />
          <div style={{ color: interviewColor, margin: '-24px 0 0 26px' }}>
            {interviewText}
          </div>
        </div>
      </>
    );
  }
}

export default CalendarIcon;
