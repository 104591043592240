import { Component } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import MJNSnackbar from '../Components/Snackbar';
import './Payments.css';
import { apiGet, apiGetMulti, apiPost } from '../Lib/requests';
import { trackEvent, trackFBpixel } from '../Lib/analytics';
import PricingDynamic from '../Components/PricingDynamic';
import ReferFriend from '../Components/ReferFriend';

class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productType: null,
      job_id: null,
      products: [],
      selectedPlan: null,
      showSnackbar: false,
      snackbarMessage: '',
      snackbarColor: false,
      submitDisabled: true,
      discount: '',
      discountCheck: false,
      skills_available: [],
      discountStatus: props.user.referral_status,
      company: {
        billing_name: '',
        billing_address: '',
        billing_city: '',
        billing_zip_code: '',
        vat: '',
        doy: '',
        telephone: '',
      },
      loading: false,
    };
    this.closeSnackBar = this.resetSnackBar.bind(this);
  }

  componentDidMount() {
    const productType = this.props.match.params.type
    && this.props.match.params.type === 'shortlist'
    && this.props.match.params.job_id
      ? 'shortlist'
      : 'job';

    trackEvent('EMP_Screen_Payments', {
      user_id: this.props.user.id,
    });

    apiGetMulti(
      ['/employer/billing_details', '/all_products'],
      'v2',
    ).then(
      axios.spread((billingDetailsRes, productsRes) => {
        let stateChange = {
          products: productsRes.data[`${productType}_credits`],
          productType,
        };

        if (this.props.match.params.job_id) {
          stateChange = {
            ...stateChange,
            job_id: this.props.match.params.job_id,
          };
        }
        if (
          billingDetailsRes.data
          && billingDetailsRes.data.vat_number
          && billingDetailsRes.data.vat_number !== 'GB117223643'
          && billingDetailsRes.data.vat_number !== 'GB117223643GB117223643'
          && billingDetailsRes.data.vat_number !== 'GB117223643GB11321412423423'
          && billingDetailsRes.data.vat_number !== 'IE6388047V'
        ) {
          // check if we have existing billing details
          const billingDetails = {
            billing_name: billingDetailsRes.data.name,
            billing_address: billingDetailsRes.data.address,
            billing_city: billingDetailsRes.data.city,
            billing_zip_code: billingDetailsRes.data.zip_code,
            vat: billingDetailsRes.data.vat_number,
            doy: billingDetailsRes.data.tax_office,
            telephone: billingDetailsRes.data.phone,
          };
          stateChange = { ...stateChange, company: billingDetails };
          this.setState(stateChange);
        }

        apiGet('/skills_available').then((skills) => {
          this.setState({ skills_available: skills.data });
        });

        this.setState({
          ...stateChange,
          productType,
          submitDisabled: this.validateFields(),
        });
      }),
    );
  }

  selectPlan = (plan) => {
    trackEvent('EMP_Action_SelectProduct', {
      user_id: this.props.user.id,
      plan_id: plan.id,
    });
    this.setState({ selectedPlan: plan });
    setTimeout(() => {
      this.billingDetails.scrollIntoView();
      window.scroll(0, window.scrollY - 100);
    }, 500);
  };

  validateFields = () => !(
    !!this.state.company.billing_name
      && !!this.state.company.billing_address
      && !!this.state.company.billing_city
      && !!this.state.company.billing_zip_code
      && !!this.state.company.vat
      && !!this.state.company.doy
      && !!this.state.company.telephone
  );

  changeFormHandler = (e, field, blur = false) => {
    e.persist();
    if (field === 'discount') {
      this.setState({ discount: e.target.value });
      return;
    }
    if (blur) {
      trackEvent('EMP_Action_AddBillingDetails', {
        user_id: this.props.user.id,
        plan_id: this.state.selectedPlan.id,
        field,
      });
    }

    this.setState((state) => ({
      company: { ...state.company, [field]: e.target.value },
      submitDisabled: this.validateFields(),
    }));
  };

  checkCoupon = () => {
    this.setState({ discountCheck: true });
    apiPost('/user/activate_referral_code', { code: this.state.discount }).then(
      (res) => {
        if (res.data.is_valid === true) {
          this.setState({ discountStatus: 'referred' });
        } else {
          this.setState({ discountStatus: 'error', discountCheck: false });
        }
      },
    );
  };

  submitPayment = (e) => {
    this.setState({ submitDisabled: true, loading: true });
    trackEvent('EMP_Action_StartSubscription', {
      user_id: this.props.user.id,
      plan_id: this.state.selectedPlan.friendly_name,
      payment_type: 'Payment API',
    });
    e.preventDefault();
    apiPost(
      '/mt09/checkout_pay',
      {
        vat_number: this.state.company.vat,
        billing_name: this.state.company.billing_name,
        billing_address: this.state.company.billing_address,
        billing_city: this.state.company.billing_city,
        billing_zip_code: this.state.company.billing_zip_code,
        phone: this.state.company.telephone,
        tax_office: this.state.company.doy,
        products: [
          {
            product: this.state.selectedPlan.friendly_name,
            amount: this.state.selectedPlan.job_credits
              ? this.state.selectedPlan.job_credits
              : 1,
            price: this.state.selectedPlan.price,
          },
        ],
        discount_code: this.state.discount.toLowerCase(),
        job_id:
          this.state.productType === 'shortlist' ? this.state.job_id : null,
      },
      'v2',
    )
      .then((response) => {
        trackEvent('EMP_Action_PaymentSuccess', {
          user_id: this.props.user.id,
          plan_id: this.state.selectedPlan.id,
          payment_type: 'Payment API',
        });

        // trackFBstandard("SPENT_CREDITS", {
        //   CONTENT_ID: this.state.selectedPlan.friendly_name,
        //   CONTENT_TYPE: this.state.productType
        // });
        trackFBpixel('Purchase', {
          currency: 'EUR',
          value: this.state.selectedPlan.price,
        });
        if (document.location.hostname === 'localhost') {
          document.location.href = `http://dev-app.myjobnow.com/checkout/payments/${response.data.id}`;
        } else {
          document.location.href = `${window.location.origin}/checkout/payments/${response.data.id}`;
        }
      })
      .catch((err) => {
        trackEvent('EMP_Action_PaymentError', {
          user_id: this.props.user.id,
          plan_id: this.state.selectedPlan.id,
          payment_type: 'Payment API',
        });
        console.log(err);
        console.log('CREATE SUB ERROR');
        this.setState({
          showSnackbar: true,
          snackbarMessage: this.props.t('Payments.error'),
          snackbarColor: 'red',
          submitDisabled: false,
          loading: false,
        });
      });
  };

  resetSnackBar = () => {
    this.setState({ showSnackbar: false, snackbarMessage: '' });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <MJNSnackbar
          show={this.state.showSnackbar}
          hide={this.closeSnackBar}
          message={this.state.snackbarMessage}
          color={this.state.snackbarColor}
        />
        <h1>{t('Payments.title')}</h1>
        <PricingDynamic
          selectPlan={this.selectPlan}
          selected={this.state.selectedPlan ? this.state.selectedPlan : null}
          products={this.state.products}
          skills={this.state.skills_available}
          productType={this.state.productType}
        />
        {this.state.products && this.state.products.length ? (
          <ReferFriend
            referralCode={this.props.user.referral_code}
          />
        ) : false}
        <div
          id="invoice_step_two"
          ref={(billingDetails) => { this.billingDetails = billingDetails; }}
          style={{ display: this.state.selectedPlan ? 'block' : 'none' }}
        >
          <Card>
            <div id="invoice_details">
              <h2>{t('Payments.invoice')}</h2>
              <TextField
                required
                label={t('Payments.companyname')}
                className="companyName"
                value={this.state.company.billing_name}
                placeholder={t('Payments.companynameExample')}
                onChange={(e) => this.changeFormHandler(e, 'billing_name')}
                onBlur={(e) => this.changeFormHandler(e, 'billing_name', 1)}
                fullWidth
                margin="normal"
                variant="standard"
              />
              <TextField
                required
                className="companyAddress"
                value={this.state.company.billing_address}
                placeholder={t('Payments.billing_addressExample')}
                label={t('Payments.billing_address')}
                onChange={(e) => this.changeFormHandler(e, 'billing_address')}
                onBlur={(e) => this.changeFormHandler(e, 'billing_address', 1)}
                fullWidth
                margin="normal"
                variant="standard"
              />
              <TextField
                required
                className="companyAddress"
                value={this.state.company.billing_city}
                placeholder={t('Payments.billing_cityExample')}
                label={t('Payments.billing_city')}
                onChange={(e) => this.changeFormHandler(e, 'billing_city')}
                onBlur={(e) => this.changeFormHandler(e, 'billing_city', 1)}
                fullWidth
                margin="normal"
                variant="standard"
              />
              <TextField
                required
                type="number"
                className="companyAddress"
                value={this.state.company.billing_zip_code}
                placeholder={t('Payments.billing_zip_codeExample')}
                label={t('Payments.billing_zip_code')}
                onChange={(e) => this.changeFormHandler(e, 'billing_zip_code')}
                onBlur={(e) => this.changeFormHandler(e, 'billing_zip_code', 1)}
                fullWidth
                margin="normal"
                variant="standard"
              />
              <TextField
                required
                className="companyTelephone billing-small"
                value={this.state.company.telephone}
                placeholder={t('Payments.companyphoneExample')}
                label={t('Payments.companyphone')}
                onChange={(e) => this.changeFormHandler(e, 'telephone')}
                onBlur={(e) => this.changeFormHandler(e, 'telephone', 1)}
                margin="normal"
                variant="standard"
              />
              <br />
              <TextField
                required
                className="companyVAT billing-small"
                value={this.state.company.vat}
                placeholder={t('Payments.companyvatExample')}
                label={t('Payments.companyvat')}
                onChange={(e) => this.changeFormHandler(e, 'vat')}
                onBlur={(e) => this.changeFormHandler(e, 'vat', 1)}
                margin="normal"
                variant="standard"
              />
              <br />
              <TextField
                required
                className="companyDOY billing-small"
                value={this.state.company.doy}
                label={t('Payments.companyvatoffice')}
                placeholder={t('Payments.companyvatofficeExample')}
                onChange={(e) => this.changeFormHandler(e, 'doy')}
                onBlur={(e) => this.changeFormHandler(e, 'doy', 1)}
                margin="normal"
                variant="standard"
              />
            </div>
            {this.state.discountStatus === 'not_referred' ? (
              <>
                <TextField
                  error={this.state.discountStatus === 'error'}
                  className="discountCode billing-small"
                  value={this.state.discountCode}
                  label={t('Payments.discountcode')}
                  placeholder=""
                  disabled={this.state.discountCheck !== false}
                  onChange={(e) => this.changeFormHandler(e, 'discount')}
                  margin="normal"
                  style={{ margin: '24px 8px 48px 24px', maxWidth: '250px' }}
                  variant="standard"
                />
                <Button
                  onClick={this.checkCoupon}
                  disabled={
                    this.state.discount === ''
                    || this.state.discountCheck !== false
                  }
                >
                  {t('Payments.redeem')}
                </Button>
              </>
            ) : this.state.discountStatus === 'referred' && (
              <div
                style={{
                  margin: '0 24px 12px',
                  fontWeight: 'bold',
                  color: '#388E3C',
                }}
              >
                {t('Payments.codeisredeemed')}
              </div>
            )}
            <br />
            {this.state.selectedPlan && (
              <div>
                <Button
                  id="start_subscription_button"
                  onClick={this.submitPayment}
                  variant="contained"
                  color="primary"
                  style={{ marginBottom: '24px' }}
                  size="large"
                  disabled={this.state.submitDisabled}
                >
                  {t('Payments.continue')}
                </Button>
                {this.state.loading && <CircularProgress size={24} />}
              </div>
            )}
          </Card>
          {/* <p className="sub-disclaimer">
            *Η συνδρομή θα ανανεώνεται αυτόματα στη λήξη της χρονικής περιόδου
            που έχετε επιλέξει.
          </p> */}
        </div>
      </>
    );
  }
}

export default withTranslation()(Payments);
