export default {
  translation: {
    App: {
      update: 'Please refresh the page to use the new version of MyJobNow or click here',
      trialnotice: 'You are in a 4-day trial period',
    },
    Error: {
      message: 'Oops... An error occurred',
      return: 'Return to the home page',
    },
    Jobs: {
      purchasesuccess: 'Successful credits purchase',
      success: 'Success',
      firstpositionTitle: 'Congratulations, you just created your first job!',
      firstpositionMsg1: 'The job has been published to users of our application who are searching for this skill. Soon, you will start receiving applications from candidates and you will be notified by email.',
      firstpositionMsg2: 'Once you receive the first applications, you will be able to evaluate the profiles of the candidates and shortlist them so that you can get in touch.',
      firstpositionButton: 'Close',
      title: 'Jobs',
      createjob: 'Create a New Job',
      nojobsopen: "You don't have a job open.",
      closedjobs: 'Closed Positions',
    },
    Messages: {
      all: 'All',
      dayprefixneutral: 'on ',
      dayprefixfemale: 'on ',
      thecompany: '📅 The company ',
      setaninterview: ' set an interview ',
      dateformat: 'dddd Do MMMM, [at] HH:mm',
      theaddressis: '. The address is ',
      deleteconvanduserTitle: 'Delete conversation and reject the candidate',
      deleteconvanduserMsg: "If you delete the conversation, all the candidate's applications will automatically be rejected.",
      deleteconvanduserBtn: 'Delete',
      messages: 'Messages',
      position: 'Position',
      status: 'Status',
      statusAll: 'All',
      statusUnread: 'Unread',
      statusNointerview: 'Without an interview',
      statusWithinterview: 'With an Interview',
      statusNoreview: 'Without a Rating',
      statusWithgoodreview: 'With a positive rating',
      noshortlist: 'You have not shortlisted a candidate yet or deleted all the conversations',
      bookinterview: 'Set an interview',
      reportuser: 'Report User',
      deleteconversation: 'Delete Conversation',
      selectconversation: 'Select a conversation',
    },
    NewCompany: {
      steps: 'Find Company#Company Name#Add phone#Select Photo#Confirm',
      changeAddress: 'Change Address',
      addName: 'Add your business name',
      changeName: 'Change Name',
      addPhone: 'Add your business phone',
      changePhone: 'Change Phone',
      selectPhoto: 'Select Photo',
      help: 'Can you help me add my business?',
      congratulations: 'Congratulations, you just added your business!',
      changesSaved: 'Your changes were saved',
      openFirstjob: 'Create your first job',
      returnTojobs: 'Return to your jobs',
      cantFindCompany: 'Did you not find your business?',
      previous: 'Previous',
      confirm: 'Confirm',
      nextStep: 'Next',
    },
    Payments: {
      success: 'Successful credits purchase',
      error: 'There was an error charging your card',
      title: 'Buy MyJobNow Credits',
      invoice: 'Billing Details',
      companyname: 'Company Name',
      companynameExample: 'e.g. MyJobNow',
      billing_address: 'Address',
      billing_addressExample: 'e.g. 151 Oxford Street',
      billing_city: 'City',
      billing_cityExample: 'e.g. London',
      billing_zip_code: 'Zip code',
      billing_zip_codeExample: 'e.g. 11523',
      companyphone: 'Phone',
      companyphoneExample: 'e.g. 2106777777',
      companyvat: 'VAT number',
      companyvatExample: 'e.g. EL888888888',
      companyvatoffice: 'Tax Office',
      companyvatofficeExample: 'e.g. Athens',
      cardinfo: 'Card details',
      discountcode: 'Discount Code',
      redeem: 'redeem',
      codeisredeemed: 'You have activated the discount code. When you buy a credit you will receive another for free!',
      buy: 'Purchase Credits',
      continue: 'CONTINUE',
    },
    Profile: {
      on_trial: 'Trial Period',
      trial_over: 'End of Trial Period',
      has_credits: 'Active Subscription',
      no_credits: 'End of Subscription',
      banned: 'Blocked Account',
      heading: 'Profile',
      logout: 'Log out',
      accountstatus: 'Account Status:',
      activecompanies: 'Active Businesses / Stores:',
      addbusiness: 'Add a Business',
      companyname: 'Name',
      companyaddress: 'Address',
      manageusers: 'User Management:',
      adduser: 'Add User',
      username: "User's Name",
      numbercompanies: 'Number of businesses',
      isadmin: 'Admin',
      language: 'Language',
    },
    Signin: {
      heading: 'Sign in to my account',
      note1: "*If you don't have an account",
      note2: 'sign up here',
      cta: 'Find the staff you want',
      bullet1: 'Set your preferences in seconds',
      bullet2: 'Receive applications in minutes',
      bullet3: 'Cover your needs today',
      tagline: 'Forget the hassle of the finding staff and focus on growing your business.',
      terms: 'Terms of use',
      privacy: 'privacy policy',
      and: ' and ',
    },
    Signout: {
      heading: 'Log out',
    },
    Signup: {
      heading: 'Create a new account',
      acceptterms1: '*By registering you accept the',
      acceptterms2: 'Terms of Use',
      acceptterms3: 'and the',
      acceptterms4: 'Privacy Policy',
      adblock: '*Disable adblock for the application to work',
      heading2: 'Looking for Staff?',
      description: 'Upload jobs and receive applications from candidates.',
      carousel1: 'Create a Job Post',
      carousel2: 'Receive applications in the app',
      carousel3: "Review the CV's of candidates",
      carousel4: 'Get in touch with them to book an interview',
      pickcountry: 'Choose your country',
      greece: 'Greece',
      italy: 'Italy',
    },
    Verify: {
      heading: 'Welcome to MyJobNow!',
      text1: 'Please confirm the email address with which you registered.',
      text2: 'We just sent you a confirmation email which you need to open and click on the "Confirm" button.',
      text3: '*If you have not received an email, please check your spam folder.',
    },
    AlertDialog: {
      cancel: 'Cancel',
    },
    ApplicantCard: {
      compatibility: '% Match ',
      details: 'Details',
      contact: 'Contact',
      reject: 'Reject',
    },
    ApplicationDetails: {
      less6months: '<6 months',
      between612months: '6-12 months',
      between13years: '1-3 years',
      between35years: '3-5 years',
      less5years: '>5 years',
      soon: ' soon',
      timein: 'in ',
      hours: ' hours',
      hour: ' hour',
      minutes: ' minutes',
      year: '1 YEAR',
      years: ' YEARS',
      month: '1 MONTH',
      months: ' MONTHS',
      expires1: 'The application will expire',
      expires2: "If you don't respond, it will be automatically rejected.",
      compatibility: '% Match ',
      area: 'Area:',
      age: 'Age:',
      ageold: 'years old',
      tagline: 'A few words about me:',
      experience: 'Work experience:',
      uptonow: 'TODAY',
      noexperience: 'Does not have any work experience',
      noexperiencedeclared: 'Has not declared any work experience',
      education: 'Education:',
      skills: 'Skills:',
      languages: 'Languages - Level',
      langlevelbeginner: 'Beginner',
      langlevelintermediate: 'Intermediate',
      langleveladvanced: 'Advanced',
      langlevelnative: 'Native',
      transportation: 'Transport Vehicle',
      motorbike: 'Motorbike',
      car: 'Car',
      licenses: "Driver's Licenses",
      licenseC: 'C',
      licenseD: 'D',
      cpc: 'Driver CPC',
      dtc: 'Digital Tachograph Card',
      Forklift: 'Forklift Certifications',
      forklift_license: 'Forklift License',
      forklift_certificate: 'Forklift Certificate',
      applytospeak: '*Shortlist the application to contact the candidate directly',
      contactdirectly: '*Contact the candidate',
      reject: 'Reject',
      shortlist: 'Shortlist',
      contactnow: 'Contact',
      communication: 'Messages',
      cv: 'Download CV',
      history: 'Interaction History',
      shortlisted: 'Shortlisted',
      reported: 'Reported',
      rejected: 'Rejected',
      rejected_after_interview: 'Rejected after interview',
      expired: 'Application Expired',
      confirmed_interview: 'Confirmed interview',
      came_to_interview: 'Came to the interview',
    },
    ApplicationHolder: {
      crisp: "I'm interested in buying the package StaffNow for my position.",
      onboardTitle: 'Congratulations, you have just shortlisted the first candidate for your job!',
      onboardText: 'Now you can contact them directly via chat or phone to find out more information and arrange an interview.',
      onboardConfirm: 'Close',
      rejectTitle: 'Reject candidate',
      rejectText: 'If you reject the candidate, you will no longer be able to contact them.',
      rejectConfirm: 'Reject',
      applications: 'Applications',
      application: 'Application',
      expires: 'Expiry:',
      renews: 'Renewal:',
      yes: 'Yes',
      no: 'No',
      pending: 'Pending',
      shortlisted: 'Shortlisted',
      shortlistedInfo: 'Shortlists',
      suggested: 'Recommended',
      more: 'More',
      reopenCTA: 'Reactivate Job Position',
      reopenText1: 'Your job position is deactivated because you reached the maximum number of pending applications without reviewing them.',
      reopenText2: 'You may reactivate the position by clicking the button below.',
      nonewapplications: 'No new applications',
      noapplications1: 'You have not received an application yet.',
      noapplications2: 'All applications appear here. We will notify you via email when you receive your first application from a candidate.',
      noshortlists: 'You have not shortlisted any candidates',
      numbermatching: 'We found {{numseekers}} candidates that match your preferences',
      contactnow: 'Notify them now',
      addprefs1: 'You can add or remove preference criteria in',
      addprefs2: 'settings',
      addprefs3: 'of your job post',
      hidden: 'Job post is hidden',
      expiring: 'Applications expiring soon',
      pendinglimit: 'Pending application limit',
      pendingsoon: 'You are approaching the pending application limit.  Review them so that your position does not get hidden.',
      reviewall: 'You have reached the maximum number of pending applications.  Review them so that you can receive more.',
      jobactivatedagain: 'The job post is visible again to all candidates',
      noapplications3: '*Every application expires automatically 48 hours after receiving it.',
    },
    BookCalendar: {
      time: 'Time',
      choosedatetime: 'Select the date and time for the interview',
      pickaddress: 'Select the address for the interview',
      add: 'Add',
      confirm: 'Confirm',
      cancel: 'Cancel',
      interviewText: 'You have not set an interview',
      dateformat: 'ddd Do MMM, [at] HH:mm',
      notconfirmed: 'Not confirmed',
      nofirstmessageTitle: 'You have not communicated with the candidate yet',
      nofirstmessageMsg: 'We recommend that you communicate with the candidate first to determine the best available time.',
      nofirstmessageBtn: 'Set interview',
    },
    Chatlist: {
      moreconversations: 'OLDER MESSAGES',
      noconversations: 'You do not have any conversations with the selected filters',
    },
    Chatmessages: {
      interviewtext: 'Can you come for an interview',
      dateformat: 'dddd Do MMMM, [at] HH:mm[?]',
      address: 'The address is',
      interviewconfirm: 'Yes, I will come to the interview. Thanks',
      interviewreject: "Unfortunately, I can't come to the interview",
      setinterview: 'Click here to set an interview for',
      placeholder: 'Message...',
    },
    ClosedJobs: {
      success: 'The position was reopened',
      alert: 'Once you open the position, it will reappear to the candidates and you will receive new applications.',
      alertcredit: 'By opening the position again you will be charged for a MyJobNow credit.',
      promptTitle: 'Do you want to open the position again?',
      promptConfirm: 'Open Position',
      open: 'OPEN',
    },
    CompanyFind: {
      interviewaddress: 'New address for interview',
      addcompany: 'Add new business',
      welcome: 'Welcome to MyJobNow!',
      startbyaddingcompany: 'Start by adding your business',
      noresults: 'No results were found. Try using the address.',
      nameoraddress: 'Name or Address of the business',
      instructions: '*Enter your business name or address in the field to see the search results. Once you locate the business or address in the results list, click on the selection.',
    },
    CompanyPreview: {
      confirmcompany: 'Verify business details',
      phone: 'Phone:',
      address: 'Address',
    },
    CreditsNotice: {
      trialTitle: 'You are in a trial period.',
      trialText: 'The position will be open for <strong>4 days</strong> and you can shortlist <strong>1 candidate</strong> using MyJobNow.<br /><br />Buy a MyJobNow credit so that you can shortlist <strong>more candidates</strong> and for your position to remain open for <strong>21 days</strong>.<br />',
      trialMsg: 'Hello,\n\nI want to purchase MyJobNow credits for my business',
      trialoverTitle: 'End of Free Trial',
      trialoverText: 'You have already shortlisted the 1 candidate offered by the free trial period. <br /> <br /> To shortlist <strong> all candidates </strong> purchase a MyJobNow credit and your position will remain open for <strong> 21 days </strong> . <br />',
      trialoverMsg: 'Hello,\n\nI want to purchase MyJobNow credits for my business',
      hassubTitle: 'Active subscription until {{xxx}}',
      hassubText: 'Congratulations, your subscription is active! <span role="img" aria-label="smiling">🙂</span> <br /><br /> You can open job positions and shortlist unlimited candidates. <br /><br /> To renew your subscription you will need to buy one of our new MyJobNow credits. <br /><br /> Need help?<br />',
      hassubMsg: 'Hello,\n\nI want to purchase MyJobNow credits for my business',
      hascreditsTitle: 'You have {{count}} MyJobNow credit',
      hascreditsTitle_plural: 'You have {{count}} MyJobNow credits',
      hascreditsText: 'You can buy more credits to open additional job positions or to keep your existing one open for more days. <br /> <br /> Need help? <br />',
      hascreditsMsg: 'Hello,\n\nI want to purchase MyJobNow credits for my business',
      nocreditsTitle: 'You do not have any MyJobNow credits',
      nocreditsText: 'Buy more credits to open job positions and shortlist unlimited candidates. <br />',
      nocreditsMsg: 'Hello,\n\nI want to purchase MyJobNow credits for my business',
      bannedTitle: 'End of Trial Period',
      bannedText: 'We are sorry. Your trial period has expired and your job position was closed <span role="img" aria-label="disappointed">😞</span> <br /> <br /> To publish a new job post and shortlist candidates, you must <strong>purchase MyJobNow credits</strong>.<br />',
      bannedMsg: 'Hello,\n\nI want to purchase MyJobNow credits for my business',
      multiuserTitle: 'Multi-User Package',
      multiuserText: 'To add new users to your account, you need to <strong>upgrade your billing package</strong>.<br />',
      multiuserMsg: 'Hello,\n\nI want to add more users to my account',
      noshortlistsTitle: 'No shortlists left',
      noshortlistsText: 'You have used all the shortlists that were included with your job post.  You may purchase additional shortlists while your job post is open.',
      noshortlistsMsg: 'Hello,\n\nI want to purchase more shortlists for my job post',
      noshortlistsBTN: 'PURCHASE SHORTLISTS',
      shortlistsTitle: 'Approaching shortlist limit',
      shortlistsText: 'Once you reach the shortlist limit included with your job post, you will need to purchase additional shortlist credits in order to continue shortlisting more candidates.',
      shortlistsMsg: 'Hello,\n\nI want to purchase more shortlists for my job post',
      shortlistsBTN: 'PURCHASE SHORTLISTS',
      contact: 'Contact',
      purchase: 'Purchase Credits',
    },
    Header: {
      profile: 'Profile',
      signout: 'Log out',
      jobs: 'Jobs',
      messages: 'Messages',
      addcompany: 'Add new business',
      verify: 'Verify email',
      interviews: 'Interviews',
    },
    Feedback: {
      yes: 'Yes',
      no: 'No',
      report: 'Do you want to report the candidate?',
      didtheycome: 'Did the candidate come to the interview?',
      review: 'How would you rate the candidate?',
      positive: 'Positive',
      negative: 'Negative',
      reviewtitle: 'Candidate Rating',
      noshow: '(1) They did not come to the interview',
      hire: 'Will you hire them?',
      delete: 'Do you want to delete the candidate?',
    },
    JobNew: {
      positionClosed: 'The position has closed',
      positionEdit: 'The position was modified',
      positionNew: 'The new job post was published',
      closepositionTitle: 'Are you sure you want to close the position?',
      closepositionText: 'Closing the position will notify all candidates and it will no longer appear in the search results.',
      closepositionBtn: 'Close Position',
      nophoneTitle: 'You can not add a phone number or an email to the job description, nor can you leave it blank.',
      nophoneText: 'Once you shortlist a candidate, you can send your contact details in a private chat message.',
      nophoneBtn: 'Close',
      create: 'Create new job position',
      edit: 'Edit job position',
      active: 'Active',
      instructionsNew: 'Select the skill and add a description of the position.',
      instructionsEdit: 'Edit preferences or disable the job position.',
      requiredfields: 'Required Fields',
      skill: 'Skill',
      pickskill: 'Select skill',
      fulltime: 'Full-time',
      parttime: 'Part-time',
      typeofwork: 'Employment',
      seasonal: 'Seasonal',
      description: 'Description',
      descriptionplaceholder: 'Some information about the position',
      descriptionhelper: 'Brief description of the position',
      havemotorbike: 'Has a motorbike',
      licensecategory: "Driver's License Category",
      motorcycleA: "A' (Motorbike)",
      carB: "B' (Car)",
      truckC: "C' (Truck)",
      busD: "D' (Bus)",
      trailerE: "E' (Trailer)",
      required: '(*Required)',
      cpc: 'Has a Driver Certificate of Professional Competence (CPC)',
      dtc: 'Has a Digital Tachograph Card',
      forklift_license: 'Forklift License',
      forklift_certificate: 'Forklift Certificate',
      hiddenpreferences: 'Hidden Preferences',
      gender: 'Gender',
      allgender: 'All',
      male: 'Male',
      female: 'Female',
      exallgender: '(e.g.: All)',
      agefrom: 'Age from',
      exagefrom: '(e.g.: 20)',
      ageto: 'Age to',
      exageto: '(e.g.: 35)',
      distance: 'Distance',
      km2: '2 km',
      km5: '5 km',
      km10: '10 km',
      km20p: '20+ km',
      exdistance: '(e.g.: 20+ km)',
      optionalfields: 'Optional Fields',
      shift: 'Shift',
      morning: 'Morning',
      noon: 'Afternoon',
      night: 'Night',
      rollingshift: 'Rolling',
      shifthelp: '(Shift)',
      yearsworkexperience: 'Years of Work Experience',
      notrequired: 'Not required',
      year: 'year',
      years: 'years',
      inthespecificskill: '(In this skill)',
      salaryfrom: 'Salary from',
      exsalaryfrom: '(e.g.: €3)',
      salaryto: 'Salary to',
      exsalaryto: '(e.g.: €10)',
      salaryper: 'per',
      hour: 'hour',
      day: 'day',
      month: 'month',
      exsalaryper: '(e.g.: Day)',
      showsalary: 'Show the salary',
      education: 'Education',
      educationdescription: '(Minimum Level)',
      languages: 'Languages',
      exlanguages: '(e.g.: English)',
      langlevelbeginner: 'Beginner',
      langlevelintermediate: 'Intermediate',
      langleveladvanced: 'Advanced',
      langlevelnative: 'Native',
      language: 'Language',
      picklanguage: 'Pick Language',
      languagelevel: 'Level',
      languageadd: 'Add',
      languageedit: 'Edit',
      languageremove: 'Remove',
      autorenew: 'Auto-Renew',
      cancel: 'Cancel',
      publish: 'Publish',
      save: 'Save',
      next: 'Next',
      back: 'Previous',
      enablePro: 'Enable PRO features',
      procv: "Download CV's in PDF format",
      proshortlist: 'Automatically Shortlist candidates',
      prochat: 'Automatic Message after Shortlist',
      prointerview: 'Automatic Interview via Chat',
      probooking: 'Automatic Scheduling of Interviews',
      upgradeRequest: "Hello,\n\nI'd like to upgrade my Job to MyJobNow PRO",
      havecar: 'Has a car',
      isexternal: 'Is an external position',
      proheadline: 'MyJobNow PRO Automations',
    },
    MobileScreen: {
      title: 'Download the app on your mobile!',
      cta: 'DOWNLOAD',
      text: 'In order to provide you with the best experience, MyJobNow on mobile is only supported by the native app.',
    },
    Navbar: {
      jobs: 'Jobs',
      messages: 'Messages',
      profile: 'Profile',
      interviews: 'Interviews',
    },
    PhotoUpload: {
      uploadphoto: 'Upload Photo',
    },
    Pricing: {
      month1: '1 MONTH',
      plusvat: '+ VAT',
      unlimitedpositions: 'Unlimited Job Posts',
      unlimitedshortlists: 'Unlimited shortlists of candidates',
      select: 'Select',
      cancel: 'Cancel',
      months3: '3 MONTHS',
      freedays15: '15 DAYS FREE',
      months12: '12 MONTHS',
      freemonths2: '2 MONTHS FREE',
    },
    PricingCredits: {
      jobpost1: '1 JOB POST',
      positionfordays: '{{count}} job post for {{days}} days',
      positionfordays_plural: '{{count}} job posts for {{days}} days',
      skillsΝumberΤitle: 'Number of shortlists per skill',
      checkSkillsNumber: 'Find here the number of shortlists per skill',
      shortListsLabel: 'shortlists',
      select: 'Select',
      cancel: 'Cancel',
      jobposts3: '3 JOB POSTS',
      discount: 'discount',
      job: '{{count}} JOB POST',
      job_plural: '{{count}} JOB POSTS',
      shortlist: '{{count}} SHORTLIST',
      shortlist_plural: '{{count}} SHORTLISTS',
      vat: 'VAT',
      loading: 'Loading Products',
    },
    ReferFriend: {
      alertTitle: '1 free Job post for you, 1 free job post for who you invite!',
      alertText: 'Your invitation code is: {{refcode}}',
      alertBtn: 'Close',
      title: 'Earn free job posts for any new user you invite to MyJobNow!',
      text: '*When they <strong>buy</strong> a job post, both of you will receive one more job post for free.',
      instructions: 'Instructions',
    },
    ReportUserDialog: {
      noshow: 'They did not come to the interview',
      noreply: 'Is not responding to messages',
      fakeprofile: 'Inaccurate profile',
      badbehavior: 'Bad behavior',
      other: 'Other',
      reportuserTitle: 'Report User',
      reportuserText: 'Choose the reason of the report',
      reason: 'Reason',
      description: 'Description',
      placeholder: 'The reason you want to report the candidate',
      sorry: "We're sorry this happened, however, <strong>we no longer accept reports for interviews that were not confirmed by they applicant via the app.</strong><br /><br />We suggest that you set interviews through the app, <strong>by tapping the calendar icon</strong>, to alert candidates of the appointment.",
      cancel: 'Cancel',
      report: 'REPORT',
      future: 'This interview is scheduled to take place at a future time.',
    },
    UserManage: {
      edituser: 'User was modified',
      newuser: 'New user was added',
      adduserTitle: 'Add User',
      edituserTitle: 'Edit User',
      addemail: "Add the user's email and select which businesses to access.",
      editemail: "Edit the user's access to the business",
      active: 'Active',
      isadmin: 'Admin (access to all businesses)',
      companies: 'Businesses',
      cancel: 'Cancel',
      add: 'Add',
      edit: 'Save',
    },
    Interviews: {
      title: 'Interviews',
      reschedule: 'RESCHEDULE',
      messages: 'MESSAGES',
      review: 'REVIEW',
      nointerviews1: 'You have not yet scheduled an interview to any candidate',
      nointerviews2: 'You may propose interviews from the conversations screen',
      filter: 'Filter',
      filterAll: 'All',
      filterConfirmed: 'Confirmed',
      filterNotConfirmed: 'Not Confirmed',
      filterNoreview: 'No Review',
      filterWithgoodreview: 'With Positive Review',
    },
    CompanySwitcher: {
      search: 'Search',
    },
  },
};
