import { Route, Redirect } from 'react-router-dom';

function ProtectedRoute({ isAuthenticated, component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (isAuthenticated ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect
          to={{ pathname: '/signin', state: { from: props.location } }}
        />
      ))}
    />
  );
}

export default ProtectedRoute;
