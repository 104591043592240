import { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

class PickLanguage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      languageId: '',
      knowledge_level: '',
      isEdit: false,
      selectedLanguages: [],
    };
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleClickOpen = (lang = null, selectedLanguages = []) => {
    if (lang) {
      this.setState({
        open: true,
        languageId: lang.id,
        knowledge_level: lang.knowledge_level,
        isEdit: true,
      });
    } else {
      this.setState({
        open: true,
        selectedLanguages: selectedLanguages.map((l) => l.id),
      });
    }
  };

  handleClose = () => {
    this.setState({
      open: false,
      languageId: '',
      knowledge_level: '',
      isEdit: false,
      selectedLanguages: [],
    });
  };

  handleConfirm = () => {
    this.props.addLanguage(
      {
        id: this.state.languageId,
        knowledge_level: this.state.knowledge_level,
      },
      this.state.isEdit,
    );
    this.handleClose();
  };

  handleChange = (name) => (e) => {
    this.setState({ [name]: e.target.value });
  };

  render() {
    const { t } = this.props;
    return (
      <Dialog open={this.state.open} onClose={this.handleClose}>
        <DialogTitle>{t('JobNew.picklanguage')}</DialogTitle>
        <DialogContent style={{ paddingTop: '6px' }}>
          <FormControl>
            <InputLabel htmlFor="language-native-helper">
              {t('JobNew.language')}
            </InputLabel>
            <Select
              className="formSelect"
              value={this.state.languageId}
              onChange={this.handleChange('languageId')}
              input={<Input id="language-native-helper" />}
              disabled={!!this.state.isEdit}
              variant="standard"
            >
              <MenuItem value="" disabled />
              {this.props.availableLanguages
                .filter(
                  (language) => this.state.selectedLanguages.includes(language.id) === false,
                )
                .map((language) => (
                  <MenuItem key={language.id} value={language.id}>
                    {language.display_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="level-native-helper">
              {t('JobNew.languagelevel')}
            </InputLabel>
            <Select
              className="formSelect"
              value={this.state.knowledge_level}
              onChange={this.handleChange('knowledge_level')}
              input={<Input id="level-native-helper" />}
              variant="standard"
            >
              {['beginner', 'intermediate', 'advanced', 'native'].map(
                (level) => (
                  <MenuItem key={level} value={level}>
                    {t(`JobNew.langlevel${level}`)}
                  </MenuItem>
                ),
              )}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            {t('JobNew.cancel')}
          </Button>
          <Button
            onClick={this.handleConfirm}
            color="primary"
            variant="contained"
            disabled={
              this.state.languageId === ''
              || this.state.knowledge_level === ''
              || this.state.knowledge_level === null
            }
          >
            {this.state.isEdit ? t('JobNew.save') : t('JobNew.languageadd')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default PickLanguage;
