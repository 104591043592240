import { Component, Fragment, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment';
import { apiGet } from '../Lib/requests';
import ApplicationDetails from '../Components/ApplicationDetails';
import InterviewFeedbackDialog from '../Components/InterviewFeedbackDialog';
import CompanySwitcher from '../Components/CompanySwitcher';
import 'moment/locale/el';
import 'moment/locale/it';

const WrappedInterviewFeedbackDialog = withTranslation('translation', {
  withRef: true,
})(InterviewFeedbackDialog);

const groupInterviews = (conv = []) => {
  const conversations = conv
    .filter((d) => d.interview && d.interview.date)
    .sort((a, b) => new Date(a.interview.date) - new Date(b.interview.date));
  const interviews = [];
  let arrayIndexDate = null;
  let arrayGroup = null;
  let lastInterviewAddress = null;
  let addressCounter = 0;
  const ignoreBeforeDate = moment().subtract(7, 'days');
  conversations.forEach((c) => {
    const currentDay = moment(c.interview.date);
    // skip interviews more than a week ago
    if (currentDay.isAfter(ignoreBeforeDate, 'day') === true) {
      if (
        c.interview.location
        && c.interview.location.address_text
        && lastInterviewAddress !== c.interview.location.address_text
      ) {
        lastInterviewAddress = c.interview.location.address_text;
        addressCounter += 1;
      }
      if (currentDay.isSame(arrayIndexDate, 'day') === false) {
        // interview is on a new day
        if (arrayGroup) {
          interviews.push(arrayGroup);
        }
        arrayIndexDate = currentDay;
        arrayGroup = { day: currentDay, interviews: [c] };
      } else {
        // interview is on same day
        arrayGroup.interviews.push(c);
      }
    }
  });
  interviews.push(arrayGroup);
  return { interviews, addressCounter };
};

class Interviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interviews: [],
      addressCounter: 0,
      profileDetails: false,
      filterStatus: 0,
      loaded: false,
    };
    this.interviewFeedback = createRef();
  }

  componentDidMount() {
    this.getInterviews();
  }

  shouldComponentUpdate(nextProps) {
    let companyChanged = false;

    if (
      this.props.user
      && this.props.user.default_company
      && this.props.user.default_company.id
    ) {
      companyChanged = nextProps.user.default_company.id
        !== this.props.user.default_company.id;
    }
    if (companyChanged) {
      this.refreshInterviews();
    }

    return true;
  }

  getInterviews() {
    this.props.loader.show();
    this.setState({ loaded: false });
    apiGet('/conversations').then((res) => {
      if (res.data) {
        const { interviews, addressCounter } = groupInterviews(res.data);
        if (interviews && interviews[0]) {
          this.setState({
            interviews,
            addressCounter,
            loaded: true,
          });
        } else {
          // handle no interviews
          this.setState({ interviews: [], addressCounter: 0, loaded: true });
        }
      }
      this.props.loader.hide();
    });
  }

  avatarClick = (jobSeekerId = null, conversationId = null) => {
    if (jobSeekerId) {
      apiGet('/job_seeker/view_details_as_employer', {
        id: jobSeekerId,
      }).then((res) => {
        this.openProfile(res.data, conversationId);
      });
    }
  };

  openProfile = (profile, conversationId = null) => {
    profile.isPending = false;
    profile.isShortlisted = true;
    profile.conversation = conversationId;
    profile.hideCV = true;
    this.setState({ profileDetails: profile });
  };

  closeProfile = () => {
    this.setState({ profileDetails: false });
  };

  handleChange = (event) => {
    if (event.target.name === 'statuses') {
      this.setState({ filterStatus: event.target.value });
    }
  };

  refreshInterviews = () => {
    this.getInterviews();
  };

  shouldFilter = (candidate) => {
    switch (this.state.filterStatus) {
      case 1:
        return candidate.interview.status === 'accepted';
      case 2:
        return candidate.interview.status === 'accepted';
      case 3:
        return (
          candidate.interview.status === 'accepted'
          && candidate.interview.feedback === null
        );
      case 4:
        return candidate.interview.feedback === 'positive';
      default:
        return true;
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="main-jobs-container">
        <CompanySwitcher
          user={this.props.user}
          unread={this.props.companiesUnread}
          type="applications"
          refreshUser={this.props.refreshUser}
        />
        <div className="fixed-fixer" style={{ width: '100%' }}>
          <div id="interview_holder">
            <h1>{t('Interviews.title')}</h1>
            <WrappedInterviewFeedbackDialog
              ref={this.interviewFeedback}
              refresh={this.refreshInterviews}
            />
            <ApplicationDetails
              profile={this.state.profileDetails}
              closer={this.closeProfile}
              user={this.props.user}
            />
            {(() => {
              if (this.state.interviews.length) {
                return (
                  <>
                    <div style={{ flex: 1, marginTop: '24px' }}>
                      <FormControl style={{ width: '30%' }}>
                        <InputLabel shrink htmlFor="statuses-helper">
                          {t('Interviews.filter')}
                        </InputLabel>
                        <Select
                          value={this.state.filterStatus}
                          onChange={this.handleChange}
                          input={<Input name="statuses" id="statuses-helper" />}
                          displayEmpty
                          name="statuses"
                        >
                          <MenuItem value={0}>
                            {t('Interviews.filterAll')}
                          </MenuItem>
                          <MenuItem value={1}>
                            {t('Interviews.filterConfirmed')}
                          </MenuItem>
                          <MenuItem value={2}>
                            {t('Interviews.filterNotConfirmed')}
                          </MenuItem>
                          <MenuItem value={3}>
                            {t('Interviews.filterNoreview')}
                          </MenuItem>
                          <MenuItem value={4}>
                            {t('Interviews.filterWithgoodreview')}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <Paper>
                      <Table>
                        {this.state.interviews.map((date) => (
                          <Fragment key={date}>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  className="intleft"
                                  style={{
                                    color: date.day.isSame(moment(), 'day')
                                      ? '#26a2f0'
                                      : 'inherit',
                                  }}
                                >
                                  {date.day.format('ddd MMM D')}
                                </TableCell>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell>&nbsp;</TableCell>
                              </TableRow>
                            </TableHead>
                            {date.interviews.map((candidate) => (this.shouldFilter(candidate) ? (
                              <TableBody key={candidate.id}>
                                <TableRow>
                                  <TableCell
                                    className={
                                              `int${
                                                candidate.interview.status
                                              } intleft`
                                            }
                                  >
                                    {moment(candidate.interview.date).format(
                                      'h:mm A',
                                    )}
                                  </TableCell>
                                  <TableCell
                                    className={
                                              `int${
                                                candidate.interview.status
                                              } intright`
                                            }
                                  >
                                    <div
                                      className="candidate-holder"
                                      onClick={() => this.avatarClick(
                                        candidate.job_seeker_id,
                                        candidate.id,
                                      )}
                                      role="button"
                                      tabIndex={0}
                                      aria-hidden
                                    >
                                      <Avatar
                                        src={candidate.photo_url}
                                        className="intavatar"
                                      />
                                      <div className="inttext">
                                        {candidate.name}
                                        {' '}
                                        (
                                        {candidate.skill.display_name}
                                        )
                                        <br />
                                        {this.state.addressCounter > 1
                                        && candidate.interview.location
                                          ? candidate.interview.location.address_text
                                          : null}

                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell className="tactions">
                                    {(() => {
                                      if (candidate.interview.status
                                      === 'rejected') {
                                        return (
                                          <Button
                                            color="primary"
                                            onClick={() => this.props.history.push(
                                              `/messages/${candidate.id}`,
                                            )}
                                            size="small"
                                          >
                                            {t('Interviews.reschedule')}
                                          </Button>
                                        );
                                      }
                                      if (candidate.interview.status
                                          === 'accepted'
                                          && moment(
                                            candidate.interview.date,
                                          ).isBefore(moment())) {
                                        if (candidate.interview.feedback
                                            === null) {
                                          return (
                                            <Button
                                              color="primary"
                                              /* eslint-disable-next-line max-len */
                                              onClick={() => this.interviewFeedback.current.handleClickOpen(
                                                candidate.id,
                                              )}
                                              size="small"
                                            >
                                              {t('Interviews.review')}
                                            </Button>
                                          );
                                        } return (
                                          <Button
                                            color="primary"
                                            onClick={() => this.props.history.push(
                                              `/messages/${candidate.id}`,
                                            )}
                                            size="small"
                                          >
                                            {t('Interviews.messages')}
                                          </Button>
                                        );
                                      }
                                      return (
                                        <Button
                                          color="primary"
                                          onClick={() => this.props.history.push(
                                            `/messages/${candidate.id}`,
                                          )}
                                          size="small"
                                        >
                                          {t('Interviews.messages')}
                                        </Button>
                                      );
                                    })()}

                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ) : null))}
                          </Fragment>
                        ))}
                      </Table>
                    </Paper>
                  </>
                );
              }
              if (this.state.loaded) {
                return (
                  <div>
                    <h3>{t('Interviews.nointerviews1')}</h3>
                    {t('Interviews.nointerviews2')}
                  </div>
                );
              }
              return (
                null
              );
            })()}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Interviews);
