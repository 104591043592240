import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

class MJNSnackbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
    this.props.hide();
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.show === prevState.open) {
      return null;
    }
    return { open: true };
  }

  render() {
    // const { classes } = this.props;
    const snackStyle = this.props.color ? this.props.color : '';
    if (!this.props.message) {
      return null;
    } return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
            className: `mjnsnack snack${snackStyle}`, // classes
          }}
          message={<span id="message-id">{this.props.message}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

export default MJNSnackbar;
