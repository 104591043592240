import { Component, createRef } from 'react';
import axios from 'axios';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import MediaQuery from 'react-responsive';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import SendIcon from '@mui/icons-material/Send';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import WbSunny from '@mui/icons-material/WbSunny';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import ApplicationIcon from '@mui/icons-material/Mail';
import NewIcon from '@mui/icons-material/NewReleases';
import ShortlistedIcon from '@mui/icons-material/DoneAll';
import { withTranslation } from 'react-i18next';
import AlertDialog from './AlertDialog';
import ApplicantCard from './ApplicantCard';
import ApplicationDetails from './ApplicationDetails';
import { apiGet, apiGetMulti, apiPost } from '../Lib/requests';
import { trackEvent } from '../Lib/analytics';
import { crispShowMessage } from '../Lib/crispUtils';

const WrappedAlertDialog = withTranslation('translation', { withRef: true })(
  AlertDialog,
);

const initalLength = 24;

class ApplicationHolder extends Component {
  constructor(props) {
    super(props);

    let {
      pending: pendingLimit,
      shortlists: shortlistLimit,
    } = props.application.limits;
    if (pendingLimit === null) {
      pendingLimit = { remaining: null, total: null };
    }
    if (shortlistLimit === null) {
      shortlistLimit = { remaining: null, total: null };
    }

    this.state = {
      pendingLength: initalLength,
      shortlistLength: initalLength,
      value: 0,
      profileDetails: false,
      isExpanded: false,
      rejectShortlisted: null,
      conversationsMap: {},
      seekers: {
        pendingSeekers: Array(props.application.applications_info.num_pending),
        shortlistedSeekers: Array(
          props.application.applications_info.num_shortlisted,
        ),
        rejectedSeekers: Array(props.application.applications_info.num_rejected),
      },
      numMatchingSeekers: 0,
      showPushOffer: false,
      isHidden: props.application.hidden,
      shortlistLimit,
      pendingLimit,
    };
    this.reject = this.reject.bind(this);
    this.shortlist = this.shortlist.bind(this);
    this.openProfile = this.openProfile.bind(this);
    this.onPanelExpanded = this.onPanelExpanded.bind(this);

    this.onboardShortlistDialog = createRef();
    this.confirmReject = createRef();
  }

  componentDidMount() {
    this.refreshApplications();
    if (this.props.totalJobs === 1) {
      this.markApplicationsAsRead();
    }
    this.setState({ isExpanded: this.props.totalJobs === 1 });
  }

  shouldComponentUpdate(nextProps) {
    const oldApplicationCount = this.props.application.applications_info.num_pending
      + this.props.application.applications_info.num_shortlisted
      + this.props.application.applications_info.num_rejected;
    const newApplicationCount = nextProps.application.applications_info.num_pending
      + nextProps.application.applications_info.num_shortlisted
      + nextProps.application.applications_info.num_rejected;
    if (
      oldApplicationCount !== newApplicationCount
      || this.props.application.basic_info.updated_at
      !== nextProps.application.basic_info.updated_at
    ) {
      this.refreshApplications();
    }
    return true;
  }

  onPanelExpanded = (e, expanded) => {
    if (expanded === true) {
      this.setState({ isExpanded: true });
      this.markApplicationsAsRead();
      trackEvent('EMP_Screen_PositionSeekers', {
        user_id: this.props.user.id,
        job_id: this.props.application.id,
        pending: this.props.application.applications_info.num_pending,
        shortlisted: this.props.application.applications_info.num_shortlisted,
        rejected: this.props.application.applications_info.num_rejected,
      });
    } else {
      this.setState({ isExpanded: false });
    }
  };

  pushRequest = () => {
    try {
      crispShowMessage(this.props.t('ApplicationHolder.crisp'));
    } catch (e) {
      // continue regardless of error
    }
  };

  loadMore = (listId) => {
    if (listId === 2) {
      this.setState((prevState) => ({ shortlistLength: prevState.shortlistLength + initalLength }));
    } else if (listId === 1) {
      this.setState((prevState) => ({ pendingLength: prevState.pendingLength + initalLength }));
    }
  };

  closeProfile = () => {
    this.setState({ profileDetails: false });
  };

  openProfile = (applicationId, status) => {
    apiGet(`/applied_seekers?application_id=${applicationId}`, {}, 'v1').then(((res) => {
      if (!res?.data) {
        this.setState({ profileDetails: false });
        return;
      }

      const profile = res.data;

      profile.isPending = status === 1;
      profile.isShortlisted = status === 2;
      profile.conversation = this.state.conversationsMap[profile.id];
      this.setState({ profileDetails: profile });
    }));
  };

  handleChange = (event, value) => {
    this.appRow.scrollLeft = 0;
    this.setState({ value });
  };

  reject(applicationId, isShortlisted = false) {
    if (isShortlisted) {
      this.setState({ rejectShortlisted: applicationId });
      this.confirmReject.current.handleClickOpen();
      return;
    }

    const applicationIndex = this.state.seekers.pendingSeekers.findIndex(
      (app) => app.id === applicationId,
    );

    const applicationIndexShortlisted = this.state.seekers.shortlistedSeekers.findIndex(
      (app) => app.id === applicationId,
    );

    apiPost('/application/reject', {
      job_id: this.props.application.id,
      job_seeker_id: applicationId,
    }).then(() => {
      this.setState((prevState) => {
        const {
          pendingSeekers,
          shortlistedSeekers,
          rejectedSeekers,
        } = prevState.seekers;
        const newPendingSeekers = pendingSeekers.filter(
          (a, i) => i !== applicationIndex,
        );

        const pLL = prevState.pendingLimit;
        if (pLL.remaining !== null) {
          pLL.remaining += 1;
        }

        if (prevState.isHidden && newPendingSeekers.length === 0) {
          this.unhideApplication();
        }
        return {
          pendingLimit: pLL,
          rejectShortlisted: null,
          seekers: {
            pendingSeekers: newPendingSeekers,
            shortlistedSeekers: shortlistedSeekers.filter(
              (a, i) => i !== applicationIndexShortlisted,
            ),
            rejectedSeekers: [
              ...rejectedSeekers,
              pendingSeekers[applicationIndex],
            ],
          },
        };
      });
      trackEvent('EMP_Action_RejectCandidate', {
        user_id: this.props.user.id,
        seeker: applicationId,
      });
    });
  }

  shortlist(applicationId) {
    const applicationIndex = this.state.seekers.pendingSeekers.findIndex(
      (app) => app.id === applicationId,
    );

    apiPost(
      '/application/shortlist',
      {
        job_id: this.props.application.id,
        job_seeker_id: applicationId,
      },
      'v2',
    )
      .then((res) => {
        apiGet('/conversations').then((conv) => {
          const conversationsMap = {};
          let shortlistedCandidate = {};
          conv.data.forEach((con) => {
            conversationsMap[con.job_seeker_id] = con.id;
          });
          this.setState(
            (prevState) => {
              const {
                pendingSeekers,
                shortlistedSeekers,
                rejectedSeekers,
              } = prevState.seekers;
              const { last_name: lastName } = res.data.seeker_info;
              let phone = null;
              if (res.data.seeker_info.phone) {
                phone = res.data.seeker_info.phone;
              }
              shortlistedCandidate = {
                ...pendingSeekers[applicationIndex],
                lastName,
                phone,
              };

              const sLL = prevState.shortlistLimit;
              if (sLL.remaining !== null && sLL.remaining !== 0) {
                sLL.remaining -= 1;
              }

              const pLL = prevState.pendingLimit;
              if (pLL.remaining !== null) {
                pLL.remaining += 1;
              }
              return {
                shortlistLimit: sLL,
                pendingLimit: pLL,
                conversationsMap,
                seekers: {
                  pendingSeekers: pendingSeekers.filter(
                    (a, i) => i !== applicationIndex,
                  ),
                  shortlistedSeekers: [
                    shortlistedCandidate,
                    ...shortlistedSeekers,
                  ],
                  rejectedSeekers,
                },
              };
            },
            () => {
              this.openProfile(shortlistedCandidate.application_id, 2);
              if (this.state.seekers.shortlistedSeekers.length === 1) {
                this.onboardShortlistDialog.current.handleClickOpen();
              }
            },
          );
          this.props.refreshUser();
        });

        trackEvent('EMP_Action_ShortlistCandidate', {
          user_id: this.props.user.id,
          seeker: applicationId,
        });
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 402) {
            this.props.showCredits();
          } else if (
            err.response.status === 400
            && err.response.data
            && err.response.data.error
            && err.response.data.error.type === 'shortlist_limit'
          ) {
            this.props.showCredits(
              null,
              'no_shortlists',
              this.props.application.id,
            );
          }
        }
      });
  }

  refreshApplications() {
    apiGet(`/applications?job_id=${this.props.application.id}`, {}, 'v3').then(((res) => {
      this.setState({
        seekers: {
          pendingSeekers: res.data.pending,
          shortlistedSeekers: res.data.shortlisted,
          rejectedSeekers: res.data.rejected,
        },
      });
    }));

    apiGetMulti([
      '/conversations',
      `/job/num_matching_seekers?id=${this.props.application.id}`,
    ]).then(
      axios.spread((conv, matching) => {
        const conversationsMap = {};
        conv.data.forEach((con) => {
          conversationsMap[con.job_seeker_id] = con.id;
        });

        let {
          pending: pendingLimit,
          shortlists: shortlistLimit,
        } = this.props.application.limits;
        if (pendingLimit === null) {
          pendingLimit = { remaining: null, total: null };
        }
        if (shortlistLimit === null) {
          shortlistLimit = { remaining: null, total: null };
        }

        this.setState({
          pendingLimit,
          shortlistLimit,
          conversationsMap,
          numMatchingSeekers: matching.data.num_seekers_matching,
          showPushOffer: matching.data.show_push_offer,
          isHidden: this.props.application.hidden,
        });
        if (this.state.isExpanded) {
          this.markApplicationsAsRead();
        }
      }),
    );
  }

  unhideApplication() {
    apiPost('/job/unhide', {
      id: this.props.application.id,
    }).then(() => {
      this.setState({ isHidden: false });
      this.props.onSuccess(this.props.t('ApplicationHolder.jobactivatedagain'));
    });
  }

  markApplicationsAsRead() {
    apiPost('/application_read', {
      job_id: this.props.application.id,
    });
  }

  render() {
    const { t } = this.props;
    const { value } = this.state;
    const { skill, description, seasonal } = this.props.application.basic_info;
    const { num_expiring: numExpiring } = this.props.application.applications_info;

    const shortlistLimit = this.state.shortlistLimit
      ? this.state.shortlistLimit
      : { remaining: null, total: null };
    const pendingLimit = this.state.pendingLimit
      ? this.state.pendingLimit
      : { remaining: null, total: null };
    const {
      valid_until: validUntil,
      auto_consume_credit: autoConsumeCredit,
      is_pro: isPro,
      is_staffnow: isStaffnow,
      is_enterprise: isEnterprise,
    } = this.props.application.payment_info;
    const { handleEditJob } = this.props;
    const pending = this.state.seekers.pendingSeekers.length;
    const shortlisted = this.state.seekers.shortlistedSeekers.length;
    const rejected = this.state.seekers.rejectedSeekers.length;
    const total = pending + shortlisted + rejected;
    let pendingList = [];
    if (
      this.state.seekers.pendingSeekers.length > this.state.pendingLength
      && this.state.seekers.pendingSeekers[0]
    ) {
      pendingList = [
        ...this.state.seekers.pendingSeekers.slice(0, this.state.pendingLength),
      ];
    } else {
      pendingList = this.state.seekers.pendingSeekers;
    }
    let shortlistedList = [];
    if (
      this.state.seekers.shortlistedSeekers.length
        > this.state.shortlistLength
      && this.state.seekers.shortlistedSeekers[0]
    ) {
      shortlistedList = [
        ...this.state.seekers.shortlistedSeekers.slice(
          0,
          this.state.shortlistLength,
        ),
      ];
    } else {
      shortlistedList = this.state.seekers.shortlistedSeekers;
    }
    const addBadge = (elem, count) => (count > 0 ? (
      <Badge badgeContent={count} color="secondary">
        {elem}
      </Badge>
    ) : (
      elem
    ));

    const reopenJob = () => (
      <div>
        <p>
          {t('ApplicationHolder.reopenText1')}
          <br />
          <br />
          {t('ApplicationHolder.reopenText2')}
        </p>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => {
            this.unhideApplication();
          }}
          style={{ marginTop: '2px' }}
        >
          {t('ApplicationHolder.reopenCTA')}
        </Button>
      </div>
    );

    const addStateNotification = (
      hidden = false,
      expiring = 0,
      pendingLeft = 10,
    ) => {
      if (
        hidden === false
        && expiring === 0
        && pendingLeft > process.env.REACT_APP_PENDING_LIMIT
      ) {
        return null;
      }
      let warning = {
        icon: null,
        alt: null,
        backgroundColor: null,
        textColor: null,
      };

      if (hidden === true) {
        warning = {
          icon: <ErrorIcon />,
          alt: 'Error',
          iconStyle: { color: '#F44336', backgroundColor: '#fafafa' },
          parentStyle: {
            color: '#FFF',
            fontWeight: 'bold',
            backgroundColor: '#F44336',
          },
          msg: t('ApplicationHolder.hidden'),
        };
      } else if (pending >= 19
        && pendingLimit.total !== null) {
        warning = {
          icon: <WarningIcon />,
          alt: 'Warning',
          iconStyle: { color: '#FFC107', backgroundColor: '#fafafa' },
          parentStyle: {
            color: '#FFF',
            fontWeight: 'bold',
            backgroundColor: '#FFC107',
          },
          msg: t('ApplicationHolder.pendinglimit'),
        };
      } else if (expiring > 0) {
        warning = {
          icon: <WarningIcon />,
          alt: 'Warning',
          iconStyle: { color: '#FFC107', backgroundColor: '#fafafa' },
          parentStyle: {
            color: '#FFF',
            fontWeight: 'bold',
            backgroundColor: '#FFC107',
          },
          msg: t('ApplicationHolder.expiring'),
        };
      }

      return (
        <>
          <MediaQuery minDeviceWidth={800}>
            <Chip
              className="warning-badge"
              style={warning.parentStyle}
              avatar={(
                <Avatar alt={warning.alt} style={warning.iconStyle}>
                  {warning.icon}
                </Avatar>
              )}
              label={warning.msg}
            />
          </MediaQuery>
          <MediaQuery maxDeviceWidth={799}>
            <Chip
              className="warning-badge"
              style={warning.parentStyle}
              avatar={(
                <Avatar alt={warning.alt} style={warning.iconStyle}>
                  {warning.icon}
                </Avatar>
              )}
            />
          </MediaQuery>
        </>
      );
    };

    const warningBar = (msg, color = '#F44336') => (
      <AppBar
        position="static"
        style={{ backgroundColor: color, margin: '8px 0' }}
      >
        <Toolbar variant="dense">{msg}</Toolbar>
      </AppBar>
    );

    return (
      <>
        <WrappedAlertDialog
          ref={this.onboardShortlistDialog}
          message={{
            title: t('ApplicationHolder.onboardTitle'),
            text: t('ApplicationHolder.onboardText'),
            confirm: t('ApplicationHolder.onboardConfirm'),
            confirmColor: 'primary',
          }}
          hideCancel
          onConfirm={() => false}
        />
        <WrappedAlertDialog
          ref={this.confirmReject}
          message={{
            title: t('ApplicationHolder.rejectTitle'),
            text: t('ApplicationHolder.rejectText'),
            confirm: t('ApplicationHolder.rejectConfirm'),
            confirmColor: 'secondary',
          }}
          hideCancel={false}
          onConfirm={() => this.reject(this.state.rejectShortlisted)}
        />
        <ApplicationDetails
          reject={this.reject}
          shortlist={this.shortlist}
          profile={this.state.profileDetails}
          closer={this.closeProfile}
          user={this.props.user}
          ispro={isPro || isEnterprise}
        />
        <Accordion
          defaultExpanded={this.props.totalJobs === 1}
          onChange={this.onPanelExpanded}
          className="expansionPanel"
        >
          <AccordionSummary
            className="main-app-container"
            expandIcon={<ExpandMoreIcon className="expandPanelIcon" />}
          >
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                  <div>
                    {addBadge(
                      <Chip
                        className="skill-badge"
                        avatar={
                          seasonal ? (
                            <Avatar
                              alt="Seasonal"
                              style={{
                                backgroundColor: '#26a2f0',
                                color: '#fefefe',
                              }}
                            >
                              <WbSunny />
                            </Avatar>
                          ) : null
                        }
                        label={skill.display_name}
                      />,
                      pending,
                    )}
                  </div>
                  <div>
                    {addStateNotification(
                      this.state.isHidden,
                      numExpiring,
                      pendingLimit.remaining,
                    )}
                  </div>
                </div>
                <ShortlistCounter
                  shortlistLimit={shortlistLimit}
                  shortlisted={shortlisted}
                  t={t}
                  showCredits={this.props.showCredits}
                  applicationId={this.props.application.id}
                  statusAsClient={this.props.user.status_as_client}
                />
                {/* {!this.props.user.subscription_expires_at && validUntil ? ( */}
                <MediaQuery minDeviceWidth={800}>
                  <div
                    className="expandApplicationDate"
                    style={{ padding: '0 24px 0 0' }}
                  >
                    <span style={{ fontWeight: 'bold', marginRight: '5px' }}>
                      {t('ApplicationHolder.expires')}
                    </span>
                    {new Date(validUntil).toLocaleDateString(
                      this.props.i18n.language,
                      {
                        day: 'numeric',
                        month: 'short',
                      },
                    )}
                  </div>
                  <div
                    className="expandApplicationRenew"
                    style={{ padding: '0 24px 0 0', fontWeight: 'bold' }}
                  >
                    {t('ApplicationHolder.renews')}
                    {autoConsumeCredit ? (
                      <span style={{ color: '#4CAF50', marginLeft: '5px' }}>
                        {t('ApplicationHolder.yes')}
                      </span>
                    ) : (
                      <span style={{ color: '#F44336', marginLeft: '5px' }}>
                        {t('ApplicationHolder.no')}
                      </span>
                    )}
                  </div>
                </MediaQuery>
                {/* ) : (
              false
            )} */}
                <button
                  style={{ paddingTop: '5px', color: 'rgba(0, 0, 0, 0.54)' }}
                  className="expand-edit-job"
                  onClick={(e) => {
                    handleEditJob(this.props.jobIndex);
                    e.stopPropagation();
                  }}
                  type="button"
                  tabIndex={0}
                >
                  <SettingsIcon />
                </button>
              </div>
              <div>
                <MediaQuery minDeviceWidth={800}>
                  <div
                    style={{
                      margin: '12px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {isPro ? (
                      <Chip
                        label="PRO"
                        style={{
                          marginRight: '12px',
                          color: '#26a2f0',
                          backgroundColor: '#fff',
                          border: '2px solid #26a2f0',
                        }}
                      />
                    ) : false}
                    {isStaffnow ? (
                      <Chip
                        label="StaffNow"
                        style={{
                          marginRight: '12px',
                          color: '#26a2f0',
                          backgroundColor: '#fff',
                          border: '2px solid #26a2f0',
                        }}
                        color="primary"
                      />
                    ) : false}
                    {description}
                  </div>
                </MediaQuery>
              </div>
            </div>
          </AccordionSummary>
          <Divider />
          <AppBar position="static" color="default">
            <MediaQuery minDeviceWidth={800}>
              <Tabs
                value={value}
                onChange={this.handleChange}
                // centered
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label={`${t('ApplicationHolder.pending')} (${pending})`} />
                <Tab
                  label={
                    `${t('ApplicationHolder.shortlisted')} (${shortlisted})`
                  }
                />
                {this.state.numMatchingSeekers >= 30
                && this.state.showPushOffer ? (
                  <Tab
                    label={
                      `${t('ApplicationHolder.suggested')
                      } (${this.state.numMatchingSeekers})`
                    }
                  />
                  ) : false}
                {/* <Tab label={`Απορριφθηκαν (${rejected})`} /> */}
                {/* <Tab label={`Ολοι (${total})`} /> */}
              </Tabs>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={799}>
              <Tabs
                value={value}
                onChange={this.handleChange}
                centered
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  label={(
                    <>
                      <NewIcon />
                      {pending}
                    </>
                  )}
                />
                <Tab
                  label={(
                    <>
                      <ShortlistedIcon />
                      {shortlisted}
                    </>
                  )}
                />
                {/* <Tab
                  label={
                    <Fragment>
                      <RejectedIcon />
                      {rejected}
                    </Fragment>
                  }
                /> */}
              </Tabs>
            </MediaQuery>
          </AppBar>
          <AccordionDetails className="applications-row-holder">
            <div className="applications-row" ref={(appRow) => { this.appRow = appRow; }}>
              {(value === 0 || value === 3)
                && (this.state.seekers.pendingSeekers.length ? (
                  <>
                    {this.state.isHidden
                      ? warningBar(t('ApplicationHolder.reviewall'))
                      : pending
                        && (pending >= 19 && pendingLimit.total !== null)
                        && warningBar(
                          t('ApplicationHolder.pendingsoon'),
                          '#FFC107',
                        )}
                    {pendingList.map((application) => (
                      <ApplicantCard
                        key={application.id}
                        application={application}
                        status={1}
                        shortlist={this.shortlist}
                        reject={this.reject}
                        clicker={this.openProfile}
                      />
                    ))}
                    {this.state.seekers.pendingSeekers.length
                    > this.state.pendingLength ? (
                      <Chip
                        avatar={(
                          <Avatar
                            style={{
                              backgroundColor: '#26a2f0',
                              color: '#fff',
                              fontWeight: 'bold',
                            }}
                          >
                            <ExpandMoreIcon />
                          </Avatar>
                        )}
                        label={t('ApplicationHolder.more')}
                        onClick={() => this.loadMore(1)}
                        style={{
                          backgroundColor: '#26a2f0',
                          color: '#fff',
                          fontWeight: 'bold',
                          marginTop: '24px',
                          'flex-basis': '100%',
                        }}
                      />
                      ) : false}
                  </>
                ) : (
                  value === 0 && (
                    <h3
                      style={{
                        minHeight: '300px',
                        textAlign: 'center',
                        width: '100%',
                        paddingTop: '24px',
                      }}
                    >
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {total > 0 ? (
                        this.state.isHidden === false ? (
                          t('ApplicationHolder.nonewapplications')
                        ) : (
                          reopenJob()
                        )
                      ) : this.state.isHidden === false ? (
                        <span>
                          {t('ApplicationHolder.noapplications1')}
                          <br />
                          <br />
                          {t('ApplicationHolder.noapplications2')}
                          <br />
                          <br />
                          {t('ApplicationHolder.noapplications3')}
                        </span>
                      ) : (
                        reopenJob()
                      )}
                    </h3>
                  )
                ))}
              {(value === 1 || value === 3)
                && (this.state.seekers.shortlistedSeekers.length ? (
                  <>
                    {shortlistedList.map((application) => (
                      <ApplicantCard
                        key={application.id}
                        application={application}
                        status={2}
                        clicker={this.openProfile}
                        reject={this.reject}
                        conversation={
                          this.state.conversationsMap[application.id]
                        }
                      />
                    ))}
                    {this.state.seekers.shortlistedSeekers.length
                    > this.state.shortlistLength ? (
                      <Chip
                        avatar={(
                          <Avatar
                            style={{
                              backgroundColor: '#26a2f0',
                              color: '#fff',
                              fontWeight: 'bold',
                            }}
                          >
                            <ExpandMoreIcon />
                          </Avatar>
                        )}
                        label={t('ApplicationHolder.more')}
                        onClick={() => this.loadMore(2)}
                        style={{
                          backgroundColor: '#26a2f0',
                          color: '#fff',
                          fontWeight: 'bold',
                          marginTop: '26px',
                          'flex-basis': '100%',
                        }}
                      />
                      ) : false}
                  </>
                ) : (
                  value === 1 && (
                    <h3
                      style={{
                        minHeight: '300px',
                        textAlign: 'center',
                        width: '100%',
                        paddingTop: '24px',
                      }}
                    >
                      {t('ApplicationHolder.noshortlists')}
                    </h3>
                  )
                ))}
              {value === 2 ? (
                <h3
                  style={{
                    minHeight: '300px',
                    textAlign: 'center',
                    width: '100%',
                    paddingTop: '24px',
                  }}
                >
                  {t('ApplicationHolder.numbermatching', {
                    numseekers: this.state.numMatchingSeekers,
                  })}
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => this.pushRequest()}
                    style={{ marginTop: '32px' }}
                  >
                    {t('ApplicationHolder.contactnow')}
                    {' '}
                    <SendIcon style={{ marginLeft: '16px' }} />
                  </Button>
                  <p
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      marginTop: '32px',
                    }}
                  >
                    {t('ApplicationHolder.addprefs1')}
                    {' '}
                    <span
                      style={{
                        color: '#26a2f0',
                        fontWeight: 'bold',
                        textDecoration: 'none',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        handleEditJob(this.props.jobIndex);
                        e.stopPropagation();
                      }}
                      role="button"
                      tabIndex={0}
                      aria-hidden
                    >
                      {t('ApplicationHolder.addprefs2')}
                    </span>
                    {' '}
                    {t('ApplicationHolder.addprefs3')}
                  </p>
                </h3>
              ) : false}
              {/* {(value === 2 || value === 3) &&
                (this.state.seekers.rejectedSeekers.length ? (
                  <Fragment>
                    {this.state.seekers.rejectedSeekers.map(application => (
                      <ApplicantCard
                        key={application.id}
                        application={application}
                        status={4}
                        clicker={this.openProfile}
                      />
                    ))}
                  </Fragment>
                ) : (
                  value === 2 && (
                    <Fragment>
                      <h3
                        style={{
                          minHeight: "300px",
                          textAlign: "center",
                          width: "100%",
                          paddingTop: "24px"
                        }}
                      >
                        Δεν έχετε απορρίψει κάποιο υποψήφιο
                      </h3>
                    </Fragment>
                  )
                ))} */}
            </div>
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
}

function ShortlistCounter({
  shortlistLimit,
  shortlisted,
  t,
  showCredits,
  applicationId,
  statusAsClient,
}) {
  const addStyle = (shortlistsRemaining) => {
    if (shortlistsRemaining === 0) {
      return { color: '#F44336' };
    }

    if (shortlistsRemaining === 1) {
      return { color: '#FFC107' };
    }

    return {};
  };

  const clickHandler = (e) => {
    if (statusAsClient === 'trial_over' || statusAsClient === 'on_trial') {
      showCredits();
      e.stopPropagation();
    } else if (shortlistLimit.total && shortlistLimit.remaining < 2) {
      showCredits(
        null,
        shortlistLimit.remaining === 0 ? 'no_shortlists' : 'shortlists',
        applicationId,
      );
      e.stopPropagation();
    }
  };

  return (
    <button
      className="expand-application-count"
      style={addStyle(shortlistLimit.remaining)}
      onClick={clickHandler}
      type="button"
      tabIndex={0}
    >
      {shortlistLimit.total
        ? `${shortlistLimit.total
          - shortlistLimit.remaining
        } / ${
          shortlistLimit.total}`
        : shortlisted}
      {/* {total - rejected}&nbsp; */}
      <MediaQuery maxDeviceWidth={799}>
        <ApplicationIcon />
      </MediaQuery>
      <MediaQuery minDeviceWidth={800}>
        {' '}
        {t('ApplicationHolder.shortlistedInfo')}
        {' '}
        {shortlistLimit.remaining < 2 && shortlistLimit.remaining !== null && (
          shortlistLimit.remaining === 0 ? (
            <ErrorIcon />
          ) : (
            <WarningIcon />
          )
        )}
        {/* {total - rejected !== 1
        ? t("ApplicationHolder.applications")
        : t("ApplicationHolder.application")} */}
      </MediaQuery>
    </button>
  );
}

export default withTranslation()(ApplicationHolder);
