import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Badge from '@mui/material/Badge';
import ChatIcon from '@mui/icons-material/ChatBubble';
import JobsIcon from '@mui/icons-material/BusinessCenter';
import CalendarIcon from '@mui/icons-material/Event';
import Profile from '@mui/icons-material/Person';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: -1,
      pathname: props.location.pathname,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location.pathname !== prevState.pathname) {
      let selectedTab = 0;
      if (nextProps.location.pathname.indexOf('/jobs') === 0) {
        selectedTab = 0;
      } else if (nextProps.location.pathname.indexOf('/messages') === 0) {
        selectedTab = 1;
      } else if (nextProps.location.pathname.indexOf('/interviews') === 0) {
        selectedTab = 2;
      } else if (nextProps.location.pathname.indexOf('/profile') === 0) {
        selectedTab = 3;
      }
      if (selectedTab !== prevState.value) {
        return { value: selectedTab };
      }
    }
    return false;
  }

  handleChange = (event, value) => {
    this.setState({ value });
    switch (value) {
      case 0:
        this.props.history.push('/jobs');
        break;
      case 1:
        this.props.history.push('/messages');
        break;
      case 2:
        this.props.history.push('/interviews');
        break;
      case 3:
        this.props.history.push('/profile');
        break;
      default:
    }
  };

  render() {
    const { t } = this.props;
    const { value } = this.state;
    const { unread, user } = this.props;
    const addBadge = (elem, count) => (count > 0 ? (
      <Badge
        badgeContent={count}
        color="secondary"
        style={{ fontWeight: 'bold' }}
      >
        {elem}
      </Badge>
    ) : (
      elem
    ));
    return user && user.onboarding_done === true ? (
      <BottomNavigation
        value={value}
        onChange={this.handleChange}
        showLabels
        style={{
          width: '100%', position: 'fixed', bottom: 0, zIndex: 3,
        }}
      >
        <BottomNavigationAction
          label={t('Navbar.jobs')}
          icon={addBadge(<JobsIcon />, unread.applications)}
        />
        <BottomNavigationAction
          label={t('Navbar.messages')}
          icon={addBadge(<ChatIcon />, unread.conversations)}
        />
        <BottomNavigationAction
          label={t('Navbar.interviews')}
          icon={addBadge(<CalendarIcon />, 0)}
        />
        <BottomNavigationAction
          label={t('Navbar.profile')}
          icon={<Profile />}
        />
      </BottomNavigation>
    ) : false;
  }
}

export default withTranslation()(Navbar);
