export default {
  translation: {
    App: {
      update: 'Aggiorna la pagina per utilizzare la nuova versione di MyJobNow o clicca qui',
      trialnotice: 'Hai un periodo di prova di 7 giorni',
    },
    Error: {
      message: 'Ops... Si è verificato un errore',
      return: 'Ritorna alla pagina iniziale',
    },
    Jobs: {
      purchasesuccess: 'Acquisto di crediti riuscito',
      success: 'Successo',
      firstpositionTitle: 'Congratulazioni, hai appena creato la tua prima offerta!',
      firstpositionMsg1: "L'offerta è stata pubblicata per gli utenti della nostra applicazione che stanno cercando questa competenza. Presto inizierai a ricevere candidature che ti saranno notificate via email.",
      firstpositionMsg2: 'Una volta ricevute le prime candidature, sarai in grado di valutare i profili dei candidati e di selezionarli in modo da poterli contattare.',
      firstpositionButton: 'Chiudi',
      title: 'Offerte',
      createjob: 'Crea una nuova offerta',
      nojobsopen: 'Non hai offerte aperte',
      closedjobs: 'Offerte chiuse',
    },
    Messages: {
      all: 'Tutte',
      dayprefixneutral: 'il ',
      dayprefixfemale: 'la ',
      thecompany: "📅 L'azienda ",
      setaninterview: ' ha fissato un colloquio ',
      dateformat: 'dddd D MMMM, [alle] HH:mm',
      theaddressis: ". L'indirizzo è ",
      deleteconvanduserTitle: 'Elimina la conversazione e scarta il candidato',
      deleteconvanduserMsg: 'Se elimini la conversazione, tutte le candidature del candidato verranno automaticamente scartate.',
      deleteconvanduserBtn: 'Elimina',
      messages: 'Messaggi',
      position: 'Offerta',
      status: 'Stato',
      statusAll: 'Tutti',
      statusUnread: 'Non letti',
      statusNointerview: 'Senza colloquio',
      statusWithinterview: 'Con colloquio',
      statusNoreview: 'Senza valutazione',
      statusWithgoodreview: 'Con una valutazione positiva',
      noshortlist: 'Non hai ancora selezionato un candidato o hai eliminato tutte le conversazioni',
      bookinterview: 'Fissa un colloquio',
      reportuser: 'Segnala utente',
      deleteconversation: 'Elimina conversazione',
      selectconversation: 'Seleziona una conversazione',
    },
    NewCompany: {
      steps: 'Trova Azienda#Nome Azienda#Aggiungi telefono#Seleziona foto#Conferma',
      changeAddress: 'Cambia indirizzo',
      addName: 'Aggiungi il nome della tua azienda',
      changeName: 'Cambia nome',
      addPhone: 'Aggiungi il telefono aziendale',
      changePhone: 'Cambia telefono',
      selectPhoto: 'Seleziona foto',
      help: 'Potete aiutarmi ad aggiungere la mia azienda?',
      congratulations: 'Congratulazioni, hai appena aggiunto la tua azienda!',
      changesSaved: 'Le modifiche sono state salvate',
      openFirstjob: 'Crea la tua prima offerta',
      returnTojobs: 'Torna alle tue offerte',
      cantFindCompany: 'Non hai trovato la tua azienda?',
      previous: 'Precedente',
      confirm: 'Conferma',
      nextStep: 'Continua',
    },
    Payments: {
      success: 'Acquisto di crediti riuscito',
      error: "Si è verificato un errore durante l'addebito della carta",
      title: 'Acquista crediti MyJobNow',
      invoice: 'Dettagli di fatturazione',
      companyname: 'Nome azienda',
      companynameExample: 'es. MyJobNow',
      billing_address: 'Indirizzo',
      billing_addressExample: 'ad es. via Pagano 151',
      billing_city: 'Città',
      billing_cityExample: 'ad es. Milano',
      billing_zip_code: 'Codici postali',
      billing_zip_codeExample: 'ad es. 11523',
      companyphone: 'Telefono',
      companyphoneExample: 'ad es. 026777777',
      companyvat: 'Partita IVA',
      companyvatExample: 'ad es. IT88888888888',
      companyvatoffice: 'Ufficio delle imposte',
      companyvatofficeExample: 'es. Milano',
      cardinfo: 'Dettagli carta',
      discountcode: 'Codice sconto',
      redeem: 'utilizza',
      codeisredeemed: 'Hai attivato il codice sconto. Quando acquisti un credito ne riceverai un altro gratuitamente!',
      buy: 'Acquista crediti',
      continue: 'CONTINUA',
    },
    Profile: {
      on_trial: 'Periodo di prova',
      trial_over: 'Fine del periodo di prova',
      has_credits: 'Abbonamento attivo',
      no_credits: "Fine dell'abbonamento",
      banned: 'Account bloccato',
      heading: 'Profilo',
      logout: 'Disconnettersi',
      accountstatus: "Stato dell'account:",
      activecompanies: 'Aziende / negozi attivi:',
      addbusiness: "Aggiungi un'azienda",
      companyname: 'Nome',
      companyaddress: 'Indirizzo',
      manageusers: 'Gestione utenti:',
      adduser: 'Aggiungi utente',
      username: "Nome dell'utente",
      numbercompanies: 'Numero di aziende',
      isadmin: 'Amministra',
      language: 'Lingua',
    },
    Signin: {
      heading: 'Accedi al mio account',
      note1: '*Se non hai un account',
      note2: 'iscriviti qui',
      cta: 'Trova il personale che desideri',
      bullet1: 'Imposta le tue preferenze in pochi secondi',
      bullet2: 'Ricevi candidature in pochi minuti',
      bullet3: 'Copri le tue esigenze oggi stesso',
      tagline: 'Dimentica il fastidio della ricerca di personale e concentrati sulla crescita del tuo business.',
      terms: 'Condizioni d\'uso',
      privacy: 'Informativa sulla privacy',
      and: ' e la ',

    },
    Signout: {
      heading: 'Disconnettersi',
    },
    Signup: {
      heading: 'Creare un nuovo account',
      acceptterms1: '*Registrandoti accetti le',
      acceptterms2: "Condizioni d'uso",
      acceptterms3: 'e la',
      acceptterms4: 'Informativa sulla privacy',
      adblock: "*Disabilita il blocco degli annunci affinché l'applicazione funzioni",
      heading2: 'Cerchi personale?',
      description: 'Carica le offerte e ricevi le candidature.',
      carousel1: "Crea un'offerta di lavoro",
      carousel2: "Ricevi le candidature nell'app",
      carousel3: 'Rivedi i CV dei candidati',
      carousel4: 'Mettiti in contatto con loro per fissare un colloquio',
      pickcountry: 'Scegli il tuo Paese',
      greece: 'Grecia',
      italy: 'Italia',
    },
    Verify: {
      heading: 'Benvenuto in MyJobNow!',
      text1: "Si prega di confermare l'indirizzo e-mail con cui ti sei registrato.",
      text2: "Ti abbiamo appena inviato un'e-mail di conferma. Aprila e fai clic sul pulsante \"Conferma\".",
      text3: "* Se non hai ricevuto un'e-mail, ti preghiamo di controllare la cartella spam.",
    },
    AlertDialog: {
      cancel: 'Annulla',
    },
    ApplicantCard: {
      compatibility: '% corrispondenza ',
      details: 'Dettagli',
      contact: 'Contatta',
      reject: 'Scarta',
    },
    ApplicationDetails: {
      less6months: '<6 mesi',
      between612months: '6-12 mesi',
      between13years: '1-3 anni',
      between35years: '3-5 anni',
      less5years: '>5 anni',
      soon: ' presto',
      timein: 'in ',
      hours: ' ore',
      hour: ' ora',
      minutes: ' minuti',
      year: '1 ANNO',
      years: ' ANNI',
      month: '1 MESE',
      months: ' MESI',
      expires1: 'La candidatura scadrà',
      expires2: 'Se non rispondi, verrà automaticamente scartata.',
      compatibility: '% corrispondenza ',
      area: 'Area:',
      age: 'Età:',
      ageold: 'anni',
      tagline: 'Qualche parola su di me:',
      experience: 'Esperienza lavorativa:',
      uptonow: 'OGGI',
      noexperience: 'Non ha alcuna esperienza lavorativa',
      noexperiencedeclared: 'Non ha dichiarato alcuna esperienza lavorativa',
      education: 'Istruzione:',
      skills: 'Competenze:',
      languages: 'Lingue',
      langlevelbeginner: 'Principiante',
      langlevelintermediate: 'Intermedio',
      langleveladvanced: 'Avanzato',
      langlevelnative: 'Nativo',
      transportation: 'Veicolo di trasporto',
      motorbike: 'Motociclo',
      car: 'Automobile',
      licenses: 'Patenti di guida',
      licenseC: 'C',
      licenseD: 'D',
      cpc: 'CQC',
      dtc: 'Carta Tachigrafica',
      Forklift: 'Certificati per Muletto',
      forklift_license: 'Patentino Muletto',
      forklift_certificate: 'Certificato di Abilitazione Muletto',
      applytospeak: '*Seleziona la candidatura per contattare direttamente il candidato',
      contactdirectly: '*Contatta il candidato',
      reject: 'Scarta',
      shortlist: 'Seleziona',
      contactnow: 'Contatta',
      communication: 'Messaggi',
      cv: 'Scarica CV',
      history: 'Cronologia delle interazioni',
      shortlisted: 'Selezionato',
      reported: 'Segnalato',
      rejected: 'Scartato',
      rejected_after_interview: 'Scartato dopo il colloquio',
      expired: 'Candidatura scaduta',
      confirmed_interview: 'Colloquio confermato',
      came_to_interview: 'È venuto al colloquio',
    },
    ApplicationHolder: {
      crisp: 'Sono interessato ad acquistare il pacchetto StaffNow per la mia offerta.',
      onboardTitle: 'Congratulazioni, hai appena selezionato il primo candidato per la tua offerta!',
      onboardText: 'Ora puoi contattarli direttamente tramite chat o telefono per scoprire ulteriori informazioni e organizzare un colloquio.',
      onboardConfirm: 'Chiudi',
      rejectTitle: 'Scarta candidato',
      rejectText: 'Se scarti il candidato, non sarai più in grado di contattarlo.',
      rejectConfirm: 'Scarta',
      applications: 'Candidature',
      application: 'Candidatura',
      expires: 'Scadenza:',
      renews: 'Rinnovo:',
      yes: 'Sì',
      no: 'No',
      pending: 'In sospeso',
      shortlisted: 'Selezionato',
      shortlistedInfo: 'Selezioni',
      suggested: 'Consigliato',
      more: 'Espandi',
      reopenCTA: 'Riattiva offerta di lavoro',
      reopenText1: "L'offerta di lavoro è disattivata perché hai raggiunto il numero massimo di candidature in sospeso non verificate.",
      reopenText2: "È possibile riattivare l'offerta facendo clic sul pulsante qui sotto.",
      nonewapplications: 'Nessuna nuova candidatura',
      noapplications1: 'Non hai ancora ricevuto candidature.',
      noapplications2: 'Ti invieremo una notifica via e-mail quando riceverai la tua prima candidatura.',
      noshortlists: 'Non hai selezionato alcun candidato',
      numbermatching: 'Abbiamo trovato {{numseekers}} candidati che corrispondono alle tue preferenze',
      contactnow: 'Avvisali ora',
      addprefs1: 'Puoi aggiungere o rimuovere i criteri di preferenza in',
      addprefs2: 'impostazioni',
      addprefs3: 'della tua offerta di lavoro',
      hidden: "L'offerta di lavoro è nascosta",
      expiring: 'Candidature in scadenza presto',
      pendinglimit: 'Limite di candidature in sospeso',
      pendingsoon: 'Ti stai avvicinando al limite di candidature in sospeso. Valutale, in modo che la tua offerta non venga nascosta.',
      reviewall: 'Hai raggiunto il numero massimo di candidature in sospeso. Valutale per riceverne di più.',
      jobactivatedagain: "L'offerta di lavoro è nuovamente visibile a tutti i candidati",
      noapplications3: '*Le candidature scadranno automaticamente 48 ore dopo la ricezione',
    },
    BookCalendar: {
      time: 'Ora',
      choosedatetime: "Seleziona la data e l'ora del colloquio",
      pickaddress: "Seleziona l'indirizzo per il colloquio",
      add: 'Aggiungi',
      confirm: 'Conferma',
      cancel: 'Annulla',
      interviewText: 'Non hai fissato un colloquio',
      dateformat: 'ddd D MMM, [alle] HH:mm',
      notconfirmed: 'Non confermato',
      nofirstmessageTitle: 'Non hai ancora comunicato con il candidato',
      nofirstmessageMsg: 'Ti consigliamo di comunicare prima con il candidato per verificare qual è la sua disponibilità.',
      nofirstmessageBtn: 'Fissa un colloquio',
    },
    Chatlist: {
      moreconversations: 'MESSAGGI MENO RECENTI',
      noconversations: 'Non hai conversazioni con i filtri selezionati',
    },
    Chatmessages: {
      interviewtext: 'Può venire per un colloquio',
      dateformat: 'dddd D MMMM, [alle] HH:mm[?]',
      address: "L'indirizzo è",
      interviewconfirm: 'Sì, verrò al colloquio. Grazie',
      interviewreject: 'Sfortunatamente, non posso venire al colloquio',
      setinterview: 'Clicca qui per fissare un colloquio per',
      placeholder: 'Messaggio...',
    },
    ClosedJobs: {
      success: "L'offerta è stata riaperta",
      alert: "Una volta aperta l'offerta, riapparirà ai candidati e riceverai nuove candidature.",
      alertcredit: "Aprendo nuovamente l'offerta ti verrà addebitato un credito MyJobNow.",
      promptTitle: "Vuoi riaprire l'offerta?",
      promptConfirm: 'RIAPRI OFFERTA',
      open: 'RIAPRI',
    },
    CompanyFind: {
      interviewaddress: 'Nuovo indirizzo per il colloquio',
      addcompany: 'Aggiungi una nuova azienda',
      welcome: 'Benvenuto in MyJobNow!',
      startbyaddingcompany: 'Inizia aggiungendo la tua azienda',
      noresults: "Nessun risultato trovato. Prova a usare l'indirizzo.",
      nameoraddress: "Nome o indirizzo dell'azienda",
      instructions: "*Inserisci il nome o l'indirizzo della tua azienda nel campo per vedere i risultati della ricerca. Dopo aver individuato l'azienda o l'indirizzo nell'elenco dei risultati, fai clic sulla selezione.",
    },
    CompanyPreview: {
      confirmcompany: 'Verifica i dettagli aziendali',
      phone: 'Telefono:',
      address: 'Indirizzo',
    },
    CreditsNotice: {
      trialTitle: 'Sei in periodo di prova.',
      trialText: "L'offerta sarà aperta per <strong>7 giorni</strong> e potrai selezionare <strong>2 candidati</strong> usando MyJobNow.<br /><br />Acquista un credito MyJobNow in modo da poter selezionare <strong>candidati illimitati</strong> e mantenere l'offerta aperta per <strong>21 giorni</strong>.<br />",
      trialMsg: 'Ciao, \n\nVoglio acquistare crediti MyJobNow per la mia azienda',
      trialoverTitle: 'Fine della prova gratuita',
      trialoverText: 'Hai già selezionato 2 candidati offerto per il periodo di prova gratuita. <br /><br /> Per selezionare <strong> tutti i candidati </strong> acquista un credito MyJobNow e la tua offerta resterà aperta per <strong> 21 giorni </strong> . <br />',
      trialoverMsg: 'Ciao, \n\nVoglio acquistare crediti MyJobNow per la mia azienda',
      hassubTitle: 'Abbonamento attivo fino al {{xxx}}',
      hassubText: 'Complimenti, il tuo abbonamento è attivo! <span role="img" aria-label="smiling">🙂</span> <br /><br /> Puoi attivare offerte di lavoro e selezionare candidati illimitati. <br /><br /> Per rinnovare il tuo abbonamento avrai bisogno di acquistare uno dei nostri nuovi crediti MyJobNow. <br /><br /> Hai bisogno di aiuto?<br />',
      hassubMsg: 'Ciao, \n\nVoglio acquistare crediti MyJobNow per la mia azienda',
      hascreditsTitle: 'Hai {{count}} credito MyJobNow',
      hascreditsTitle_plural: 'Hai {{count}} crediti MyJobNow',
      hascreditsText: "Puoi acquistare più crediti per attivare ulteriori offerte di lavoro o per mantenere l'offerta esistente aperta per più giorni. <br /> <br /> Hai bisogno di aiuto? <br />",
      hascreditsMsg: 'Ciao, \n\nVoglio acquistare crediti MyJobNow per la mia azienda',
      nocreditsTitle: 'Non hai crediti MyJobNow',
      nocreditsText: 'Acquista più crediti per attivare offerte di lavoro e selezionare candidati illimitati. <br />',
      nocreditsMsg: 'Ciao, \n\nVoglio acquistare crediti MyJobNow per la mia azienda',
      bannedTitle: 'Fine del periodo di prova',
      bannedText: 'Siamo spiacenti. Il tuo periodo di prova è scaduto e la tua offerta di lavoro è stata chiusa <span role="img" aria-label="disappointed"> 😞 </span> <br /> <br /> Per pubblicare una nuova offerta di lavoro e selezionare candidati, devi <strong> acquistare crediti MyJobNow</strong>.<br />',
      bannedMsg: 'Ciao, \n\nVoglio acquistare crediti MyJobNow per la mia azienda',
      multiuserTitle: 'Pacchetto multi-utente',
      multiuserText: 'Per aggiungere nuovi utenti al tuo account, devi <strong>aggiornare il pacchetto di fatturazione</strong>.<br />',
      multiuserMsg: 'Ciao, \n\nVoglio aggiungere più utenti al mio account',
      noshortlistsTitle: 'Non sono rimaste candidature',
      noshortlistsText: "Hai utilizzato tutte le candidature incluse nella tua offerta di lavoro. È possibile acquistare candidature aggiuntive mentre l'offerta è aperta.",
      noshortlistsMsg: 'Ciao, \n\nvoglio acquistare più candidature per la mia offerta.',
      noshortlistsBTN: 'ACQUISTA CANDIDATURE',
      shortlistsTitle: 'Ti stai avvicinando al limite di candidature',
      shortlistsText: 'Una volta raggiunto il limite di candidature incluso nella tua offerta di lavoro, dovrai acquistare altri crediti per continuare a selezionare più candidati.',
      shortlistsMsg: 'Ciao, \n\nvoglio acquistare più candidature per la mia offerta.',
      shortlistsBTN: 'ACQUISTA CANDIDATURE',
      contact: 'Contatta',
      purchase: 'Acquista crediti',
    },
    Header: {
      profile: 'Profilo',
      signout: 'Disconnettersi',
      jobs: 'Offerte',
      messages: 'Messaggi',
      addcompany: 'Aggiungi una nuova azienda',
      verify: 'Verifica Email',
      interviews: 'Colloqui',
    },
    Feedback: {
      yes: 'Sì',
      no: 'No',
      report: 'Vuoi segnalare il candidato?',
      didtheycome: 'Il candidato è venuto al colloquio?',
      review: 'Come valuteresti il candidato?',
      positive: 'Positivo',
      negative: 'Negativo',
      reviewtitle: 'Valutazione del candidato',
      noshow: '(1) Non è venuto al colloquio',
      hire: 'Li assumerai?',
      delete: 'Vuoi cancellare il candidato?',
    },
    JobNew: {
      positionClosed: "L'offerta è chiusa",
      positionEdit: "L'offerta è stata modificata",
      positionNew: 'La nuova offerta di lavoro è stata pubblicata',
      closepositionTitle: "Sei sicuro di voler chiudere l'offerta?",
      closepositionText: "La chiusura sarà notificata a tutti i candidati e l'offerta non comparirà più nei risultati di ricerca.",
      closepositionBtn: 'Chiudi offerta',
      nophoneTitle: "Non è possibile aggiungere un numero di telefono o un'e-mail nella descrizione dell'offerta, né è possibile lasciarla vuota.",
      nophoneText: 'Una volta selezionato un candidato, è possibile inviargli i tuoi dettagli di contatto in un messaggio di chat privato.',
      nophoneBtn: 'Chiudi',
      create: 'Crea una nuova offerta di lavoro',
      edit: 'Modifica offerta di lavoro',
      active: 'Attiva',
      instructionsNew: "Seleziona la competenza e aggiungi una descrizione dell'offerta.",
      instructionsEdit: "Modifica le preferenze o disabilita l'offerta di lavoro.",
      requiredfields: 'Campi richiesti',
      skill: 'Competenze',
      pickskill: 'Seleziona una competenza',
      fulltime: 'Tempo pieno',
      parttime: 'Part-Time',
      typeofwork: 'Giornata lavorativa',
      seasonal: 'Stagionale',
      description: 'Descrizione',
      descriptionplaceholder: "Alcune informazioni sull'offerta",
      descriptionhelper: "Breve descrizione dell'offerta",
      havemotorbike: 'Ha una moto',
      licensecategory: 'Categoria di patente di guida',
      motorcycleA: "A' (Motociclo)",
      carB: "B' (Auto)",
      truckC: "C' (Camion)",
      busD: "D' (Bus)",
      trailerE: "E' (Veicolo con rimorchio)",
      required: '(*Richiesto)',
      cpc: 'Ha una Carta di Qualificazione Conducente (CQC)',
      dtc: 'Ha una Carta Tachigrafica Digitale',
      forklift_license: 'Patentino Muletto',
      forklift_certificate: 'Certificato di Abilitazione Muletto',
      hiddenpreferences: 'Preferenze nascoste',
      gender: 'Sesso',
      allgender: 'Tutti',
      male: 'Maschio',
      female: 'Femmina',
      exallgender: '(es.: Tutti)',
      agefrom: 'Età da',
      exagefrom: '(es.: 20)',
      ageto: 'Età fino a',
      exageto: '(es .: 35)',
      distance: 'Distanza',
      km2: '2 km',
      km5: '5 km',
      km10: '10 km',
      km20p: 'Più di 20 km',
      exdistance: '(es.: più di 20 km)',
      optionalfields: 'Campi facoltativi',
      shift: 'Turno',
      morning: 'Mattina',
      noon: 'Pomeriggio',
      night: 'Notte',
      rollingshift: 'Rotazione',
      shifthelp: '(Turno)',
      yearsworkexperience: 'Anni di esperienza lavorativa',
      notrequired: 'Non richiesto',
      year: 'anno',
      years: 'anni',
      inthespecificskill: '(Per questa competenza)',
      salaryfrom: 'Stipendio da',
      exsalaryfrom: '(es: €3)',
      salaryto: 'Stipendio fino a',
      exsalaryto: '(es: €10)',
      salaryper: 'al',
      hour: 'ora',
      day: 'giorno',
      month: 'mese',
      exsalaryper: '(es.: giorno)',
      showsalary: 'Mostra lo stipendio',
      education: 'Istruzione',
      educationdescription: '(Livello minimo)',
      languages: 'Lingue',
      exlanguages: '(es: inglese)',
      langlevelbeginner: 'Principiante',
      langlevelintermediate: 'Intermedio',
      langleveladvanced: 'Avanzato',
      langlevelnative: 'Nativo',
      language: 'Lingua',
      picklanguage: 'Scegli la lingua',
      languagelevel: 'Livello',
      languageadd: 'Aggiungi',
      languageedit: 'Modifica',
      languageremove: 'Elimina',
      autorenew: 'Rinnovo automatico',
      cancel: 'Annulla',
      publish: 'Pubblica',
      save: 'Salva',
      next: 'Continua',
      back: 'Precedente',
      enablePro: 'Abilita le Funzioni PRO',
      procv: 'Scarica il CV in formato PDF',
      proshortlist: 'Seleziona Automaticamente',
      prochat: 'Messaggio Automatico dopo la Selezione',
      prointerview: 'Intervista automatica via Chat',
      probooking: 'Fissa automaticamente un appuntamento',
      upgradeRequest: 'Ciao, \n\nVorrei acquistare MyJobNow PRO',
      havecar: 'Ha una auto',
      isexternal: 'È un lavoro sul territorio',
      proheadline: 'MyJobNow PRO Automazioni',
    },
    MobileScreen: {
      title: "Scarica l'app sul tuo cellulare!",
      cta: 'SCARICA',
      text: "Per offrirti la migliore esperienza, MyJobNow su dispositivo mobile è supportato solo dall'app nativa.",
    },
    Navbar: {
      jobs: 'Offerte',
      messages: 'Messaggi',
      profile: 'Profilo',
      interviews: 'Colloqui',
    },
    PhotoUpload: {
      uploadphoto: 'Carica una foto',
    },
    Pricing: {
      month1: '1 MESE',
      plusvat: '+ IVA',
      unlimitedpositions: 'Offerte di lavoro illimitate',
      unlimitedshortlists: 'Seleziona candidati illimitati',
      select: 'Seleziona',
      cancel: 'Annulla',
      months3: '3 MESI',
      freedays15: '15 GIORNI GRATIS',
      months12: '12 MESI',
      freemonths2: '2 MESI GRATIS',
    },
    PricingCredits: {
      jobpost1: '1 OFFERTA DI LAVORO',
      positionfordays: '{{count}} offerta di lavoro per {{days}} giorni',
      positionfordays_plural: '{{count}} offerte di lavoro per {{days}} giorni',
      skillsΝumberΤitle: 'Numero di selezioni per competenza',
      checkSkillsNumber: 'Trova qui il numero di selezioni per competenza',
      shortListsLabel: 'elenchi',
      select: 'Seleziona',
      cancel: 'Annulla',
      jobposts3: '3 OFFERTE DI LAVORO',
      discount: 'sconto',
      job: '{{count}} OFFERTA DI LAVORO',
      job_plural: '{{count}} OFFERTE DI LAVORO',
      shortlist: '{{count}} CANDIDATURA',
      shortlist_plural: '{{count}} CANDIDATURE',
      vat: 'IVA',
      loading: 'Caricamento Prodotti',
    },
    ReferFriend: {
      alertTitle: '1 offerta di lavoro gratuita per te, 1offerta di lavoro gratuita per chi inviti!',
      alertText: 'Il tuo codice di invito è: {{refcode}}',
      alertBtn: 'Chiudi',
      title: 'Guadagna offerte di lavoro gratuite per ogni nuovo utente che inviti su MyJobNow!',
      text: "Quando <strong>acquistano</strong> un'offerta di lavoro, entrambi riceverete un'altra offerta di lavoro gratuita.",
      instructions: 'Istruzioni',
    },
    ReportUserDialog: {
      noshow: 'Non è venuto al colloquio',
      noreply: 'Non risponde ai messaggi',
      fakeprofile: 'Profilo inaccurato',
      badbehavior: 'Comportamento inappropriato',
      other: 'Altro',
      reportuserTitle: 'Segnala utente',
      reportuserText: 'Scegli il motivo della segnalazione',
      reason: 'Motivo',
      description: 'Descrizione',
      placeholder: 'Il motivo per cui vuoi segnalare il candidato',
      sorry: "Ci dispiace per quanto accaduto, <strong>tuttavia non accettiamo più segnalazioni per colloqui che non siano stati confermati dal candidato via app.</strong><br /><br />Ti consigliamo di programmare i colloqui tramite l'app, <strong>toccando l'icona del calendario</strong>, per avvisare i candidati dell'appuntamento.",
      cancel: 'Annulla',
      report: 'SEGNALAZIONE',
      future: 'Questo colloquio è previsto per un orario o un giorno futuro.',
    },
    UserManage: {
      edituser: "L'utente è stato modificato",
      newuser: 'È stato aggiunto un nuovo utente',
      adduserTitle: 'Aggiungi utente',
      edituserTitle: 'Modifica utente',
      addemail: "Aggiungi l'e-mail dell'utente e seleziona le aziende a cui dovrà accedere.",
      editemail: "Modifica l'accesso dell'utente all'azienda",
      active: 'Attivo',
      isadmin: 'Amministratore (accesso a tutte le aziende)',
      companies: 'Aziende',
      cancel: 'Annulla',
      add: 'Aggiungi',
      edit: 'Salva',
    },
    Interviews: {
      title: 'Colloqui',
      reschedule: 'RIPROGRAMMA',
      messages: 'MESSAGGI',
      review: 'VALUTA',
      nointerviews1: 'Non hai ancora proposto un colloquio a nessun candidato',
      nointerviews2: 'Puoi proporre colloqui attraverso la schermata delle conversazioni',
      filter: 'Filtro',
      filterAll: 'Tutti',
      filterConfirmed: 'Confermato',
      filterNotConfirmed: 'Non confermato',
      filterNoreview: 'Senza valutazione',
      filterWithgoodreview: 'Con valutazione positiva',
    },
    CompanySwitcher: {
      search: 'Filtro',
    },
  },
};
