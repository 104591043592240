import { Component, createRef } from 'react';
import 'react-chat-elements/dist/main.css';
import MediaQuery from 'react-responsive';
import { ActionCableProvider } from 'react-actioncable-provider';
import { withTranslation } from 'react-i18next';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Avatar from '@mui/material/Avatar';
import ArrowBack from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import CalendarIcon from '@mui/icons-material/Event';
import ReportIcon from '@mui/icons-material/Report';
import ReportUserDialog from '../Components/ReportUserDialog';
import ApplicationDetails from '../Components/ApplicationDetails';
import BookCalendar from '../Components/BookCalendar';
import { apiGet, apiDelete } from '../Lib/requests';
import { trackEvent } from '../Lib/analytics';

import './Messages.css';
import InterviewFeedbackDialog from '../Components/InterviewFeedbackDialog';
import AlertDialog from '../Components/AlertDialog';
import CompanySwitcher from '../Components/CompanySwitcher';
import Chatmessages from '../Components/Chatmessages';
import ChatList from '../Components/Chatlist';

const WrappedAlertDialog = withTranslation('translation', { withRef: true })(
  AlertDialog,
);

const WrappedBookCalendar = withTranslation('translation', { withRef: true })(
  BookCalendar,
);

const WrappedChatMessages = withTranslation('translation', { withRef: true })(
  Chatmessages,
);

const WrappedReportUserDialog = withTranslation('translation', {
  withRef: true,
})(ReportUserDialog);

const WrappedInterviewFeedbackDialog = withTranslation('translation', {
  withRef: true,
})(InterviewFeedbackDialog);

const setConversationStatusIcon = (conv) => {
  let statusIcon = false;
  if (conv.interview) {
    if (conv.interview.feedback !== null) {
      statusIcon = conv.interview.feedback === 'positive' ? (
        <ThumbUpIcon style={{ color: '#4caf50', fontSize: '18px' }} />
      ) : (
        <ThumbDownIcon style={{ color: '#f44336', fontSize: '18px' }} />
      );
    } else if (conv.interview.took_place === false) {
      statusIcon = (
        <ThumbDownIcon style={{ color: '#f44336', fontSize: '18px' }} />
      );
    } else if (conv.interview.status === 'rejected') {
      statusIcon = (
        <CalendarIcon style={{ color: '#f44336', fontSize: '18px' }} />
      );
    } else if (conv.interview.status === 'pending') {
      statusIcon = (
        <CalendarIcon style={{ color: '#ffc107', fontSize: '18px' }} />
      );
    } else if (new Date(conv.interview.date) < new Date()) {
      statusIcon = (
        <ThumbsUpDownIcon style={{ color: '#26a2f0', fontSize: '18px' }} />
      );
    } else if (conv.interview.status === 'accepted') {
      statusIcon = (
        <CalendarIcon style={{ color: '#4caf50', fontSize: '18px' }} />
      );
    }
  }

  return (
    <>
      {statusIcon}
      {' '}
      {conv.name}
      <p style={{ color: '#777', fontSize: '11px', margin: '0' }}>
        (
        {conv.skill.display_name_greek}
        )
      </p>
    </>
  );
};

const getConversationStatus = (conv) => {
  let status = null;
  if (conv.interview) {
    if (conv.interview.feedback === 'positive') {
      status = 5;
    } else if (
      conv.interview.took_place === null
      && conv.interview.status === 'accepted'
      && new Date(conv.interview.date) < new Date()
    ) {
      status = 4;
    } else if (
      conv.interview.status === 'pending'
      || (conv.interview.status === 'accepted'
        && new Date(conv.interview.date) > new Date())
    ) {
      status = 3;
    } else if (conv.interview.status === 'rejected') {
      status = 2;
    }
  } else {
    status = 2;
  }
  return status;
};

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      conversations: [],
      activeConversation: null,
      profileDetails: false,
      filterSkills: [],
      selectedSkill: 0,
      selectedStatus: 0,
      suggestDate: null,
    };
    this.makeactive = this.makeactive.bind(this);
    this.newMessage = this.newMessage.bind(this);
    this.onInterview = this.onInterview.bind(this);
    this.onInterviewDateDetect = this.onInterviewDateDetect.bind(this);
    this.interviewFeedback = createRef();
    this.reallyDeleteUserConversation = this.reallyDeleteUserConversation.bind(
      this,
    );

    this.reportUserDialog = createRef();
    this.bookCalendar = createRef();
    this.confirmDelete = createRef();
    this.chatMessages = createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const preloadConversationId = nextProps.match.params.id
      ? nextProps.match.params.id
      : null;

    if (
      prevState.activeConversation
      && preloadConversationId !== prevState.activeConversation.id
    ) {
      // eslint-disable-next-line max-len
      const selectedConv = prevState.conversations.find((conv) => conv.id.toString() === preloadConversationId);
      return { activeConversation: selectedConv };
    }
    return null;
  }

  componentDidMount() {
    this.refreshConversations();
  }

  shouldComponentUpdate(nextProps) {
    let companyChanged = false;
    let receivedCompanyUsers = false;

    if (this.props.companyUsers) {
      receivedCompanyUsers = nextProps.companyUsers !== this.props.companyUsers;
    }

    if (
      this.props.user
      && this.props.user.default_company
      && this.props.user.default_company.id
    ) {
      companyChanged = nextProps.user.default_company.id
        !== this.props.user.default_company.id;
    }
    if (
      nextProps.unread !== this.props.unread
      || companyChanged
      || receivedCompanyUsers
    ) {
      this.refreshConversations(null, companyChanged);
    }

    return true;
  }

  refreshConversations = (preloadedId = null, companyChanged = false) => {
    this.props.loader.show();
    const preloadConversationId = preloadedId
      ? preloadedId.toString()
      : this.props.match.params.id;
    apiGet('/conversations').then((res) => {
      const uniqueFilterSkills = new Map();
      let resetState = false;
      let { activeConversation } = this.state;
      const conversations = res?.data?.map((conv) => {
        uniqueFilterSkills.set(conv.skill.id, conv.skill.display_name);
        const conversationObject = {
          avatar: conv.photo_url,
          alt: conv.name,
          title: setConversationStatusIcon(conv),
          date: new Date(conv.updated_at),
          subtitle: conv.last_message,
          jobSeekerId: conv.job_seeker_id,
          jobSkill: conv.skill,
          status: getConversationStatus(conv),
          interview: conv.interview,
          receiver_seen_at: conv.receiver_seen_at,
          unread:
          // eslint-disable-next-line no-nested-ternary
            conv.id.toString() === preloadConversationId
              ? null
              : conv.read
                ? null
                : true,
          id: conv.id,
        };
        if (
          conversationObject.id.toString() === preloadConversationId
          && resetState === false
        ) {
          resetState = true;
          activeConversation = conversationObject;
          // this.setState({ activeConversation: conversationObject });
        }
        return conversationObject;
      }) || [];
      const filterSkills = [
        [0, this.props.t('Messages.all')],
        ...uniqueFilterSkills,
      ];
      this.props.markAsRead('conversations');
      let resetFilters = null;
      if (companyChanged) {
        resetFilters = { selectedSkill: 0, selectedStatus: 0 };
      }
      this.setState({
        loaded: true,
        conversations,
        filterSkills,
        activeConversation,
        ...resetFilters,
      });
      if (!preloadConversationId) {
        trackEvent('User_Screen_Conversations', {
          user_id: this.props.user.id,
          conversations: this.state.conversations.length,
        });
      }
      this.props.loader.hide();
    });
  };

  makeactive = (conversation) => {
    this.setState({ activeConversation: conversation });
    this.props.history.push(`/messages/${conversation.id}`);
    this.refreshConversations(conversation.id);
    if (
      conversation.interview
      && conversation.interview.status === 'accepted'
      && new Date(conversation.interview.date) < new Date()
      && conversation.interview.took_place === null
    ) {
      this.interviewFeedback.current.handleClickOpen();
    }
    trackEvent('User_Screen_ConversationThread', {
      user_id: this.props.user.id,
      conversation_id: conversation.id,
    });
  };

  onInterview = () => {
    this.refreshConversations();
  };

  onInterviewDateDetect = (detectedDate) => {
    this.bookCalendar.current.handleDatePass(detectedDate);
    this.setState({ suggestDate: detectedDate });
  };

  newMessage = (conversationId, latestMessage) => {
    // eslint-disable-next-line max-len
    const conversationIndex = this.state.conversations.findIndex((conv) => conv.id === conversationId);

    const otherConversations = this.state.conversations.filter(
      (conv) => conv.id !== conversationId,
    );

    this.setState((prevState) => ({
      conversations: [
        {
          ...prevState.conversations[conversationIndex],
          subtitle: latestMessage,
        },
        ...otherConversations,
      ],
    }));
    this.props.markAsRead('conversations');
  };

  avatarClick = () => {
    const { jobSeekerId } = this.state.activeConversation;
    apiGet('/job_seeker/view_details_as_employer', {
      id: jobSeekerId,
    }).then((res) => {
      this.openProfile(res.data);
    });
  };

  deleteUserConversation = () => {
    this.confirmDelete.current.handleClickOpen();
  };

  reallyDeleteUserConversation = () => {
    apiDelete('/conversation', {
      conversation_id: this.state.activeConversation.id,
    }).then(() => {
      this.refreshConversations();
      this.props.history.push('/messages');
    });
  };

  handleChange = (event) => {
    if (event.target.name === 'skills') {
      this.setState({ selectedSkill: event.target.value });
    } else if (event.target.name === 'statuses') {
      this.setState({ selectedStatus: event.target.value });
    }
  };

  reportUser = () => {
    let interviewStatus = false;
    let interviewIsFuture = false;

    if (
      typeof this.state.activeConversation.interview === 'object'
      && this.state.activeConversation.interview !== null
    ) {
      interviewStatus = this.state.activeConversation.interview.status === 'accepted';
      interviewIsFuture = new Date(this.state.activeConversation.interview.date) > new Date();
    }

    this.reportUserDialog.current.handleClickOpen(
      this.state.activeConversation.id,
      interviewStatus,
      interviewIsFuture,
    );
  };

  openProfile = (profile) => {
    profile.isPending = false;
    profile.isShortlisted = true;
    profile.conversation = null;
    profile.hideCV = true;
    this.setState({ profileDetails: profile });
  };

  closeProfile = () => {
    this.setState({ profileDetails: false });
  };

  bookMeeting = (date, address = null) => {
    const prefix = date.day() === 6
      ? this.props.t('Messages.dayprefixneutral')
      : this.props.t('Messages.dayprefixfemale');
    let msg = this.props.t('Messages.thecompany')
      + this.props.user.default_company.name
      + this.props.t('Messages.setaninterview')
      + prefix;
    msg += date.format(this.props.t('Messages.dateformat'));
    msg
      += `${this.props.t('Messages.theaddressis')
    + (address
      ? address.address_text
      : this.props.user.default_company.location.address_text)
      } 🤝`;
    this.chatMessages.current.sendMeetingRequest(
      msg,
      this.state.activeConversation.jobSeekerId,
      date.format(),
      address,
    );
  };

  render() {
    const token = localStorage.getItem('mjnkodikos');
    const { t } = this.props;
    return (
      <ActionCableProvider
        url={`${process.env.REACT_APP_MJN_SOCKETS}/cable?X-MYJOBNOW-FIR=${token}&amp;account-type=employer`}
      >
        <div style={{ flexGrow: 1, maxHeight: 'calc(100vh - 88px)' }}>
          <WrappedReportUserDialog ref={this.reportUserDialog} />
          <WrappedInterviewFeedbackDialog
            ref={this.interviewFeedback}
            conversation={this.state.activeConversation}
            refresh={this.refreshConversations}
            history={this.props.history}
          />
          <WrappedAlertDialog
            ref={this.confirmDelete}
            message={{
              title: t('Messages.deleteconvanduserTitle'),
              text: t('Messages.deleteconvanduserMsg'),
              confirm: t('Messages.deleteconvanduserBtn'),
              confirmColor: 'secondary',
            }}
            hideCancel
            onConfirm={this.reallyDeleteUserConversation}
          />
          <ApplicationDetails
            profile={this.state.profileDetails}
            closer={this.closeProfile}
            user={this.props.user}
          />
          <MediaQuery minDeviceWidth={800}>
            <div style={{ display: 'flex' }}>
              <div>
                <CompanySwitcher
                  user={this.props.user}
                  unread={this.props.companiesUnread}
                  type="conversations"
                  refreshUser={this.props.refreshUser}
                />
              </div>
              <div
                className="fixed-fixer"
                style={{
                  display: 'flex',
                  flex: 1,
                  width: 0,
                }}
              >
                <div style={{ width: '30%' }}>
                  <h1>{t('Messages.messages')}</h1>
                  {this.state.conversations.length ? (
                    <div>
                      <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                          <FormControl style={{ width: '100%' }}>
                            <InputLabel shrink htmlFor="skill-helper">
                              {t('Messages.position')}
                            </InputLabel>
                            <Select
                              value={this.state.selectedSkill}
                              onChange={this.handleChange}
                              input={<Input name="skill" id="skill-helper" />}
                              displayEmpty
                              name="skills"
                            >
                              {this.state.filterSkills.map((skill) => (
                                <MenuItem key={skill[0]} value={skill[0]}>
                                  {skill[1]}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div style={{ flex: 1 }}>
                          <FormControl style={{ width: '100%' }}>
                            <InputLabel shrink htmlFor="statuses-helper">
                              {t('Messages.status')}
                            </InputLabel>
                            <Select
                              value={this.state.selectedStatus}
                              onChange={this.handleChange}
                              input={
                                <Input name="statuses" id="statuses-helper" />
                              }
                              displayEmpty
                              name="statuses"
                            >
                              <MenuItem value={0}>
                                {t('Messages.statusAll')}
                              </MenuItem>
                              <MenuItem value={1}>
                                {t('Messages.statusUnread')}
                              </MenuItem>
                              <MenuItem value={2}>
                                {t('Messages.statusNointerview')}
                              </MenuItem>
                              <MenuItem value={3}>
                                {t('Messages.statusWithinterview')}
                              </MenuItem>
                              <MenuItem value={4}>
                                {t('Messages.statusNoreview')}
                              </MenuItem>
                              <MenuItem value={5}>
                                {t('Messages.statusWithgoodreview')}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <ChatList
                        conversations={this.state.conversations
                          .filter((conv) => (this.state.selectedSkill
                            ? conv.jobSkill.id === this.state.selectedSkill
                            : true))
                          .filter((conv) => {
                            if (this.state.selectedStatus >= 2) {
                              return conv.status === this.state.selectedStatus;
                            } if (this.state.selectedStatus === 1) {
                              return conv.unread === true;
                            }
                            return true;
                          })}
                        selected={this.state.activeConversation}
                        makeactive={this.makeactive}
                        className={
                          this.props.user.companies.length
                            ? ' singlecompany' // " multicompany"
                            : ' singlecompany'
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      {this.state.loaded ? (
                        <p>{t('Messages.noshortlist')}</p>
                      ) : false}
                    </div>
                  )}
                </div>
                <div style={{ width: '70%', paddingLeft: '10px' }}>
                  <div>
                    {this.state.activeConversation ? (
                      <>
                        <div
                          style={{
                            borderBottom: '2px solid #ddd',
                            overflow: 'hidden',
                            paddingBottom: '46px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            // cursor: "pointer",
                            position: 'relative',
                          }}
                          // onClick={this.avatarClick}
                        >
                          <Avatar
                            src={this.state.activeConversation.avatar}
                            onClick={this.avatarClick}
                            style={{ cursor: 'pointer' }}
                          />
                          <div
                            style={{
                              marginTop: '12px',
                              marginLeft: '12px',
                              cursor: 'pointer',
                            }}
                            onClick={this.avatarClick}
                            role="button"
                            tabIndex={0}
                            aria-hidden
                          >
                            {this.state.activeConversation.title}
                          </div>
                          <div
                            style={{
                              position: 'absolute',
                              left: '2px',
                              bottom: '10px',
                              color: '#26a2f0',
                              cursor: 'pointer',
                            }}
                            title={t('Messages.bookinterview')}
                          >
                            <WrappedBookCalendar
                              ref={this.bookCalendar}
                              color="primary"
                              pickTime={this.bookMeeting}
                              interview={
                                this.state.activeConversation.interview
                              }
                              lastMessage={
                                this.state.activeConversation.subtitle
                              }
                              isMobile={false}
                              company={this.props.user.default_company}
                              suggestDate={this.state.suggestDate}
                              country={this.props.user.country_code}
                            />
                          </div>
                          <span
                            title={t('Messages.reportuser')}
                            style={{
                              position: 'absolute',
                              right: '40px',
                              top: '56px',
                              color: '#26a2f0',
                              cursor: 'pointer',
                            }}
                            onClick={this.reportUser}
                            role="button"
                            tabIndex={0}
                            aria-hidden
                          >
                            <ReportIcon
                              color="secondary"
                              style={{ cursor: 'pointer' }}
                            />
                          </span>
                          <span
                            title={t('Messages.deleteconversation')}
                            style={{
                              position: 'absolute',
                              right: '8px',
                              top: '56px',
                              color: '#26a2f0',
                              cursor: 'pointer',
                            }}
                            onClick={this.deleteUserConversation}
                            role="button"
                            tabIndex={0}
                            aria-hidden
                          >
                            <DeleteIcon
                              color="secondary"
                              style={{ cursor: 'pointer' }}
                            />
                          </span>
                        </div>
                        <div style={{ clear: 'both' }} />
                        <WrappedChatMessages
                          ref={this.chatMessages}
                          activeConversation={this.state.activeConversation.id}
                          receiverSeen={
                            this.state.activeConversation.receiver_seen_at
                          }
                          userId={this.props.user.id}
                          companyUsers={this.props.companyUsers}
                          onMessage={this.newMessage}
                          onInterview={this.onInterview}
                          onInterviewDateDetect={this.onInterviewDateDetect}
                          className={
                            this.props.user.companies.length
                              ? ' singlecompany' // " multicompany"
                              : ' singlecompany'
                          }
                        />
                      </>
                    ) : null}
                    {this.state.conversations.length ? (
                      <div>{t('Messages.selectconversation')}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={799}>
            {!this.state.activeConversation ? (
              <>
                <CompanySwitcher
                  user={this.props.user}
                  unread={this.props.companiesUnread}
                  type="conversations"
                  refreshUser={this.props.refreshUser}
                />
                <h1>{t('Messages.messages')}</h1>
                <ChatList
                  conversations={this.state.conversations}
                  selected={this.state.activeConversation}
                  makeactive={this.makeactive}
                  className={
                    this.props.user
                    && this.props.user.companies
                    && this.props.user.companies.length
                      ? ' multicompany'
                      : ' singlecompany'
                  }
                />
              </>
            ) : (
              <>
                <div
                  style={{
                    borderBottom: '2px solid #ddd',
                    overflow: 'hidden',
                    marginBottom: '12px',
                    paddingBottom: '6px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <a
                    style={{
                      color: '#26a2f0',
                    }}
                    href="/messages"
                    onClick={(e) => {
                      this.props.history.push('/messages');
                      e.preventDefault();
                    }}
                  >
                    <ArrowBack />
                  </a>
                  <Avatar
                    src={this.state.activeConversation.avatar}
                    onClick={this.avatarClick}
                  />
                  <div
                    style={{
                      color: '#26a2f0',
                      cursor: 'pointer',
                    }}
                    title={t('Messages.bookinterview')}
                  >
                    <WrappedBookCalendar
                      ref={this.bookCalendar}
                      color="primary"
                      pickTime={this.bookMeeting}
                      interview={this.state.activeConversation.interview}
                      isMobile
                      company={this.props.user.default_company}
                      suggestDate={this.state.suggestDate}
                      country={this.props.user.country_code}
                    />
                  </div>
                  <span
                    title={t('Messages.reportuser')}
                    style={{
                      color: '#26a2f0',
                      cursor: 'pointer',
                    }}
                    onClick={this.reportUser}
                    role="button"
                    tabIndex={0}
                    aria-hidden
                  >
                    <ReportIcon
                      color="secondary"
                      style={{ cursor: 'pointer' }}
                    />
                  </span>
                  <span
                    style={{
                      color: '#26a2f0',
                      cursor: 'pointer',
                    }}
                    onClick={this.deleteUserConversation}
                    role="button"
                    tabIndex={0}
                    aria-hidden
                  >
                    <DeleteIcon color="secondary" />
                  </span>
                </div>
                <div style={{ clear: 'both' }} />
                <WrappedChatMessages
                  activeConversation={this.state.activeConversation.id}
                  userId={this.props.user.id}
                  companyUsers={this.props.companyUsers}
                  onMessage={this.newMessage}
                  onInterview={this.onInterview}
                  onInterviewDateDetect={this.onInterviewDateDetect}
                  ref={this.chatMessages}
                  receiverSeen={this.state.activeConversation.receiver_seen_at}
                  className={
                    this.props.user.companies.length
                      ? ' multicompany'
                      : ' singlecompany'
                  }
                />
              </>
            )}
          </MediaQuery>
        </div>
      </ActionCableProvider>
    );
  }
}

export default withTranslation()(Messages);
