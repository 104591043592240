// @flow
import bugsnag from 'bugsnag-js';

import React from 'react';
import ReactDOM from 'react-dom';
import createPlugin from 'bugsnag-react';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import './i18n';
import App from './App';
import { unregister } from './registerServiceWorker';

const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BUGSNAG,
  appVersion: process.env.REACT_APP_VERSION,
  notifyReleaseStages: ['production', 'staging'],
}); // eslint-disable-line no-alert
window.bugsnagClient = bugsnagClient;
const ErrorBoundary = bugsnagClient.use(createPlugin(React));
const domRoot = document.getElementById('root');
if (domRoot === null) {
  throw new Error('no root element found');
} else {
  ReactDOM.render(
    <ErrorBoundary>
      <Router>
        <App />
      </Router>
    </ErrorBoundary>,
    domRoot,
  );
  unregister();
}
