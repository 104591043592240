import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { trackEvent } from '../Lib/analytics';
import { resetCrispSession } from '../Lib/crispUtils';

class Signout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    trackEvent('User_Action_Logout');

    resetCrispSession();

    window.setTimeout(() => {
      localStorage.removeItem('mjnkodikos');
      localStorage.removeItem('mjnuser');
      this.props.firebase.auth().signOut();
      window.setTimeout(() => {
        window.location.reload();
      });
    }, 500);
  }

  componentWillUnmount() {
    // this.props.firebase.auth().signOut();
  }

  render() {
    const { t } = this.props;
    return <h1>{t('Signout.heading')}</h1>;
  }
}

export default withTranslation()(Signout);
