import axios from 'axios';

const baseApiAddress = `${process.env.REACT_APP_MJN_API}/api/`;

function getToken() {
  return localStorage.getItem('mjnkodikos');
}

export function notAuthorized(e) {
  console.log('AXIOS Error', e);
}

axios.interceptors.request.use(
  (config) => {
    if (
      config.url.indexOf(process.env.REACT_APP_MJN_API) === 0
      && !config.headers['X-MYJOBNOW-FIR']
    ) {
      const token = getToken();
      if (token) {
        // config.headers["myjobnow-token"] = token;
        config.headers['x-myjobnow-fir'] = token;
      }
    }

    return config;
  },
  (error) => Promise.reject(error),
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      console.log('NETWORK ERROR');
    } else if (error.response.status === 401) {
      window.refreshToken();
    }
    return Promise.reject(error);
  },
);

export function apiGet(url, data = {}, version = 'v1', client = 'web') {
  const baseApiAddressVersion = `${baseApiAddress + version}`;
  const URL = baseApiAddressVersion + url;
  return axios.get(URL, {
    headers: {
      'account-type': 'employer',
      client,
    },
    params: data,
  }).catch((error) => {
    console.log(error.response);
  });
}

export function apiDownload(
  url,
  data = {},
  version = 'v1',
  responseType = 'blob',
) {
  const baseApiAddressVersion = `${baseApiAddress + version}`;
  const URL = baseApiAddressVersion + url;
  return axios.get(URL, {
    responseType,
    headers: {
      'account-type': 'employer',
    },
    params: data,
  });
}

export function apiGetMulti(urls, version = 'v1', client = '') {
  return axios.all(urls.map((url) => apiGet(url, {}, version, client)));
}

export function apiPost(url, data, version = 'v1') {
  const baseApiAddressVersion = `${baseApiAddress + version}`;
  const URL = baseApiAddressVersion + url;
  return axios.post(URL, data, {
    headers: {
      'account-type': 'employer',
    },
  });
}

export function apiPut(url, data, version = 'v1') {
  const baseApiAddressVersion = `${baseApiAddress + version}`;

  const URL = baseApiAddressVersion + url;
  return axios.put(URL, data);
}

export function apiDelete(url, data, version = 'v1') {
  const baseApiAddressVersion = `${baseApiAddress + version}`;

  const URL = baseApiAddressVersion + url;
  return axios.delete(URL, { data });
}
