import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import logo from '../logo.svg';

class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    let { language } = this.props.i18n;
    if (language && language.includes('-')) {
      [language] = language.split('-');
    }
    return (
      <div id="sign_container" style={{ textAlign: 'center', margin: '24px 0' }}>
        <div className="sign-up-text">
          <img src={logo} style={{ width: '120px' }} alt="Logo" />
          <h1>{t('Signin.heading')}</h1>
          <p style={{ fontSize: '12px' }}>
            {t('Signin.note1')}
            {' '}
            <a
              onClick={(e) => {
                this.props.history.push('/signup');
                e.preventDefault();
              }}
              href="/signup"
              style={{
                color: '#26a2f0',
                textDecoration: 'none',
                fontWeight: 'bold',
              }}
            >
              {t('Signin.note2')}
            </a>
          </p>
          <div className="terms">
            <p>
              <a
                href={`https://www.myjobnow.com/terms-of-service-${language}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Signin.terms')}
              </a>
              {t('Signin.and')}
              <a
                href={`https://www.myjobnow.com/privacy-policy-${language}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Signin.privacy')}
              </a>
            </p>
          </div>
        </div>
        <div className="logo-sign">
          <div className="logo-sign-text">
            <h1>{t('Signin.cta')}</h1>
            <ul>
              <li>{t('Signin.bullet1')}</li>
              <li>{t('Signin.bullet2')}</li>
              <li>{t('Signin.bullet3')}</li>
            </ul>
            <p>{t('Signin.tagline')}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Signin);
