import { Component } from 'react';

const sendPageChange = (pathname, search = '') => {
  const page = pathname + search;
  try {
    window.GA.set({ page });
    window.GA.pageview(page);
  } catch (e) {
    console.log('GA not initialized');
  }
};
class GAnalytics extends Component {
  componentDidMount() {
    sendPageChange(
      this.props.location.pathname,
      this.props.location.search,
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname
      || this.props.location.search !== prevProps.location.search
    ) {
      sendPageChange(
        this.props.location.pathname,
        this.props.location.search,
      );
    }
  }

  render() {
    return null;
  }
}

export default GAnalytics;
