import { Component } from 'react';
import { Input, Button, MessageList } from 'react-chat-elements';
import { ActionCable } from 'react-actioncable-provider';
import SendIcon from '@mui/icons-material/Send';
import moment from 'moment';
import * as chrono from 'chrono-node';
import { trackEvent } from '../Lib/analytics';
import { apiGet } from '../Lib/requests';
import 'moment/locale/el';
import 'moment/locale/it';
import { hideCrispChatBox, showCrispChatBox } from '../Lib/crispUtils';

const onDisconnected = () => {
  window.location.reload(true);
};

class ChatMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInterviewGuess: false,
      userId: this.props.userId,
      conversation_id: this.props.activeConversation
        ? this.props.activeConversation
        : null,
      messages: [],
    };
    this.onReceived = this.onReceived.bind(this);
    this.lastMessageIsFromUserId = null;
  }

  componentDidMount() {
    if (window.innerWidth < 800) {
      hideCrispChatBox();
    }
    this.getConversation(this.props.activeConversation);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.conversation_id !== nextProps.activeConversation) {
      return true;
    }

    if (this.state.messages.length !== nextState.messages.length) {
      return true;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeConversation !== prevProps.activeConversation) {
      this.getConversation(this.props.activeConversation);
    }
  }

  componentWillUnmount() {
    showCrispChatBox();
  }

  onConnected = () => {
    this.roomChannel.perform('read', {
      conversation_id: this.state.conversation_id,
    });
  };

  getConversation(conversationId) {
    apiGet(`/conversation/messages?id=${conversationId}`).then((res) => {
      const messages = res.data.map((msg) => {
        const a = this.formatMessage(msg);
        return a;
      });
      this.lastMessageIsFromUserId = null;
      this.setState({
        messages,
        conversation_id: conversationId,
        showInterviewGuess: false,
      });
    });
  }

  standardiseText = (msg) => {
    const { t } = this.props;
    let textmsg = '';
    if (msg.type.name === 'text') {
      textmsg = msg.type.text;
    } else if (msg.type.name === 'interview') {
      textmsg = `📅 ${
        t('Chatmessages.interviewtext')
      } ${
        moment(msg.type.date).format(t('Chatmessages.dateformat'))}`;
      if (msg.type.location && msg.type.location.address_text) {
        textmsg
          += `\r\n ${
            t('Chatmessages.address')
          } ${
            msg.type.location.address_text}`;
      }
    } else if (msg.type.name === 'interview_response') {
      textmsg = msg.type.response
        ? `📅 ${t('Chatmessages.interviewconfirm')}`
        : `📅 ${t('Chatmessages.interviewreject')}`;
    } else if (
      msg.type.name === 'question'
      || msg.type.name === 'question_response'
    ) {
      textmsg = msg.type.text;
    }
    return textmsg;
  };

  isMessageRead = (timestamp, isOwn) => {
    const lastReadTime = new Date(this.props.receiverSeen);
    if (!isOwn) {
      return null;
    }
    if (new Date(timestamp) <= lastReadTime) {
      return 'read';
    }
    // "sent"||"received"||"read"
    return 'sent';
  };

  isOwnMessage = (userId, isOwnMessage = false) => {
    const isDifferentUser = userId !== this.lastMessageIsFromUserId;
    this.lastMessageIsFromUserId = userId;

    if (isOwnMessage) {
      return { name: '🤖 MyJobNow Auto' };
    } if (this.props.companyUsers && this.props.companyUsers.length > 1) {
      const companyUser = this.props.companyUsers.find((cu) => cu.id === userId);

      if (companyUser) {
        if (isDifferentUser) {
          return { name: `${companyUser.first_name} ${companyUser.last_name}` };
        }
        return { name: null };
      }
    } else if (userId === this.state.userId) {
      return { name: null };
    }
    return null;
  };

  formatMessage = (message) => {
    const isOwnMessage = this.isOwnMessage(
      message.user_id,
      message.is_auto_message,
    );
    const msg = {
      // avatar: "urltologo",
      title: isOwnMessage ? isOwnMessage.name : null,
      position: isOwnMessage ? 'right' : 'left',
      type: 'text',
      notch: false,
      text: this.standardiseText(message),
      date: new Date(message.timestamp),
      status: this.isMessageRead(message.timestamp, isOwnMessage),
    };
    return msg;
  };

  onReceived = (message) => {
    if (message.action === 'new_message') {
      const msg = this.formatMessage(message.payload.msg);

      this.props.onMessage(this.state.conversation_id, msg.text);

      this.setState((prevState) => ({
        messages: [...prevState.messages, msg],
      }));

      // Mark as read
      if (message.payload.msg.user_id !== this.state.userId) {
        this.roomChannel.perform('read', {
          conversation_id: this.state.conversation_id,
        });
      }
    } else if (message.action === 'new_interview') {
      this.props.onInterview(message.payload.interview);
    } else if (message.action === 'interview_response') {
      this.props.onInterview(message.payload.interview_response);
    } else if (
      message.action === 'new_read_receipt'
      && message.payload.read_receipt.sender_id !== this.state.userId
    ) {
      this.markAllAsRead();
    }
  };

  markAllAsRead = () => {
    this.setState((prevState) => {
      const updatedMessages = prevState.messages.map((m) => {
        if (m.position === 'right') {
          m.status = 'read';
        }
        return m;
      });
      return { messages: updatedMessages };
    });
  };

  sendMessage = () => {
    const message = this.newMessage.state.value;
    if (this.state.messages.length === 0) {
      trackEvent('User_Action_FirstChatSend', {
        user_id: this.state.userId,
        conversation_id: this.state.conversation_id,
      });
    }
    // Call perform or send
    this.roomChannel.perform('send_message', {
      text: message,
      conversation_id: this.state.conversation_id,
    });
    this.newMessage.setState({ value: '' });

    /* #i18nME */
    const proposeMeeting = chrono.en.parseDate(message);
    if (proposeMeeting) {
      this.setState({ showInterviewGuess: proposeMeeting });
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  sendMeetingRequest = (msg, seekerId, date, address = null) => {
    this.roomChannel.perform('create_interview', {
      date,
      location: address,
      conversation_id: this.state.conversation_id,
    });

    trackEvent('User_Action_SetInterview', {
      user_id: this.state.userId,
      persona: 'employer',
      date,
    });
  };

  handleEnter = (e) => {
    if (e.charCode === 13) {
      if (e.shiftKey) {
        return false;
      }
      this.sendMessage();
      e.preventDefault();
      e.stopPropagation();
    }
    return null;
  };

  render() {
    const { t } = this.props;
    if (this.state.conversation_id !== this.props.activeConversation) {
      return <div>loading</div>;
    }
    return (
      <div className={`message-holder${this.props.className}`}>
        <ActionCable
          ref={(roomChannel) => { this.roomChannel = roomChannel; }}
          channel={{
            channel: 'ConversationChannel',
            conversation_id: this.state.conversation_id,
          }}
          onReceived={this.onReceived}
          onConnected={this.onConnected}
          onDisconnected={onDisconnected}
        />
        <MessageList
          className={
            (this.state.showInterviewGuess
              ? 'message-list-interview'
              : 'message-list') + this.props.className
          }
          // lockable={true}
          toBottomHeight="1"
          // onScroll={console.log("scroooool")}
          dataSource={this.state.messages.map((msg) => ({
            ...msg,
            text: msg.text
              ? msg.text.split('\n').map((item) => (
                <span key={item}>
                  {item}
                  <br />
                </span>
              ))
              : '',
          }))}
        />
        <button
          style={{
            display: this.state.showInterviewGuess ? 'block' : 'none',
            backgroundColor: '#ffc107',
            padding: '8px',
            marginTop: '8px',
            height: '50px',
            borderRadius: '8px',
            cursor: 'pointer',
            border: 0,
          }}
          onClick={() => {
            this.props.onInterviewDateDetect(this.state.showInterviewGuess);
            this.setState({ showInterviewGuess: false });
          }}
          type="button"
          tabIndex={0}
        >
          {t('Chatmessages.setinterview')}
          {' '}
          {this.state.showInterviewGuess
            ? moment(this.state.showInterviewGuess).format(
              t('Chatmessages.dateformat'),
            )
            : ''}
        </button>
        <Input
          ref={(newMessage) => { this.newMessage = newMessage; }}
          placeholder={t('Chatmessages.placeholder')}
          multiline
          onKeyPress={this.handleEnter}
          rightButtons={(
            <Button
              color="#26a2f0"
              backgroundColor="white"
              text={(
                <>
                  {/* <div>Αποστολή</div> */}
                  <SendIcon />
                </>
              )} // "Αποστολή"
              onClick={this.sendMessage}
            />
          )}
        />
      </div>
    );
  }
}

export default ChatMessages;
