import { useTranslation } from 'react-i18next';

export function ErrorPage() {
  const { t } = useTranslation();
  return (
    <div style={{ textAlign: 'center', margin: '24px 0' }}>
      <h2>
        {t('Error.message')}
        {' '}
        <span role="img" aria-label="disappointed">
          😞
        </span>
      </h2>
      {' '}
      <a
        onClick={(e) => {
          // eslint-disable-next-line react/no-this-in-sfc
          this.props.history.push('/');
          e.preventDefault();
        }}
        href="/"
        style={{
          color: '#26a2f0',
          textDecoration: 'none',
          fontWeight: 'bold',
        }}
      >
        {t('Error.return')}
      </a>
    </div>
  );
}

export default ErrorPage;
