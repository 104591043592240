import { Component, createRef } from 'react';
import DatePicker from 'react-datepicker';
import Button from '@mui/material/Button';
import moment from 'moment';
import CompanyFind from './CompanyFind';
import CalendarIcon from './CalendarIcon';

import 'react-datepicker/dist/react-datepicker.css';

class BookCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: props.suggestDate ? moment(props.suggestDate) : moment(),
      addressList: [props.company],
      interviewAddress: props.company.id,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleAddress = this.handleAddress.bind(this);
    this.pickAddress = this.pickAddress.bind(this);
    this.dpicker = createRef();
  }

  componentDidMount() {
    if (localStorage) {
      const addressList = JSON.parse(
        localStorage.getItem('interviewAddresses'),
      );
      if (addressList) {
        if (addressList.find((a) => a.id === this.props.company.id)) {
          const defaultAddress = JSON.parse(
            localStorage.getItem('interviewDefault'),
          );
          this.setState((prevState) => ({
            addressList,
            interviewAddress: defaultAddress || prevState.interviewAddress,
          }));
        } else {
          this.setState({
            addressList: [this.props.company, ...addressList],
          });
        }
      }
    }
  }

  handleChange(date) {
    if (
      ` ${
        this.state.startDate.hour()
      }:${
        this.state.startDate.minute()}`
      !== ` ${date.hour()}:${date.minute()}`
    ) {
      // this.props.pickTime(date);
    }
    this.setState({
      startDate: date,
    });
  }

  handleConfirm() {
    const interviewAddress = this.state.addressList.find(
      (address) => address.id === this.state.interviewAddress
        && address.id !== this.props.company.id,
    );
    this.props.pickTime(
      this.state.startDate,
      interviewAddress && interviewAddress.location
        ? interviewAddress.location
        : null,
    );
    this.dpicker.current.setOpen(false);
  }

  handleCancel() {
    this.dpicker.current.setOpen(false);
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleDatePass(suggestDate) {
    this.setState({ startDate: moment(suggestDate) }, () => this.dpicker.current.setOpen(true));
  }

  handleAddress(e, data) {
    if (data && data.location && data.location.latitude) {
      const newAddress = {
        id: data.google_place_id,
        name: data.name,
        location: {
          latitude: data.location.latitude,
          longitude: data.location.longitude,
          address_text: data.address,
        },
      };

      this.setState((prevState) => {
        const newAddressList = [...prevState.addressList, newAddress];
        if (localStorage) {
          localStorage.setItem(
            'interviewAddresses',
            JSON.stringify(newAddressList),
          );
          localStorage.setItem('interviewDefault', JSON.stringify(newAddress.id));
        }
        return {
          addressList: newAddressList,
          interviewAddress: newAddress.id,
        };
      });
    }
  }

  pickAddress(e) {
    this.setState({ interviewAddress: e.target.value });
    if (e.target.value !== 'new' && localStorage) {
      localStorage.setItem('interviewDefault', JSON.stringify(e.target.value));
    }
  }

  render() {
    const { t, isMobile } = this.props;
    let popWidth = '420px';
    if (isMobile) {
      popWidth = '90%';
    }
    return (
      <DatePicker
        ref={this.dpicker}
        selected={this.state.startDate}
        onChange={this.handleChange}
        shouldCloseOnSelect={false}
        customInput={(
          <CalendarIcon
            interview={this.props.interview}
            lastMessage={this.props.lastMessage}
            t={t}
            isMobile={isMobile}
          />
        )}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={30}
        dateFormat="LLL"
        timeCaption={t('BookCalendar.time')}
        locale={this.props.i18n.language}
        placeholderText={t('BookCalendar.choosedatetime')}
        minDate={moment().subtract(0, 'day')}
        withPortal
      >
        <div
          id="datepickerbuttons"
          style={{ clear: 'both', maxWidth: popWidth }}
        >
          <div
            id="datepickeraddress"
            style={{ popWidth, margin: '0 auto', textAlign: 'center' }}
          >
            <h3>{t('BookCalendar.pickaddress')}</h3>
            <select
              // I am not sure if ever existed an attribute named native.
              // native={isMobile ? 'true' : 'false'}
              name="address"
              value={this.state.interviewAddress}
              onChange={this.pickAddress}
            >
              {this.state.addressList.map((comp) => (
                <option value={comp.id} key={comp.id}>
                  {comp.name}
                  {' '}
                  -
                  {comp.location.address_text}
                </option>
              ))}
              <option value="new" key="new">
                +
                {' '}
                {t('BookCalendar.add')}
              </option>
            </select>
            {this.state.interviewAddress === 'new' ? (
              <CompanyFind
                placeSelected={this.handleAddress}
                onboarding_done
                isInterview
                country={this.props.country}
              />
            ) : (
              <div style={{ height: '120px' }}>&nbsp;</div>
            )}
          </div>
          <div
            id="interviewButtons"
            style={{ textAlign: 'right', margin: '16px 16px', width: popWidth }}
          >
            <Button
              onClick={this.handleConfirm}
              color="primary"
              variant="contained"
            >
              {t('BookCalendar.confirm')}
            </Button>
            <Button onClick={this.handleCancel} color="secondary">
              {t('BookCalendar.cancel')}
            </Button>
          </div>
        </div>
      </DatePicker>
    );
  }
}

export default BookCalendar;
