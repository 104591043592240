export default {
  translation: {
    App: {
      update: 'Ανανεώστε τη σελίδα για να χρησιμοποιήσετε τη νέα έκδοση του MyJobNow ή πατήστε εδώ',
      trialnotice: 'Είστε σε δοκιμαστική περίοδο 4 ημερών',
    },
    Error: {
      message: 'Ωχ... κάποιο σφάλμα προέκυψε',
      return: 'Επιστροφή στη κεντρική σελίδα',
    },
    Jobs: {
      purchasesuccess: 'Επιτυχής αγορά αγγελιών',
      success: 'Επιτυχία',
      firstpositionTitle: 'Συγχαρητήρια μόλις δημιουργήσατε την πρώτη σας θέση εργασίας!',
      firstpositionMsg1: 'Η θέση έχει δημοσιευθεί στους χρήστες της εφαρμογής μας που ψάχνουν για τη συγκεκριμένη ειδικότητα. Σε λίγη ώρα θα αρχίσετε να δέχεστε αιτήσεις από ενδιαφερόμενους και θα ειδοποιηθείτε μέσω email.',
      firstpositionMsg2: 'Μόλις λάβετε τις πρώτες αιτήσεις, θα μπορείτε να αξιολογήσετε τα προφίλ των ενδιαφερόμενων και να τους επιλέξετε ώστε να έρθετε σε επικοινωνία μαζί τους.',
      firstpositionButton: 'Κλεισιμο',
      title: 'Θέσεις Εργασίας',
      createjob: 'Δημιουργια Θεσης',
      nojobsopen: 'Δεν έχετε κάποια θέση εργασίας ανοιχτή.',
      closedjobs: 'Κλειστές Θέσεις',
    },
    Messages: {
      all: 'Όλες',
      dayprefixneutral: 'το ',
      dayprefixfemale: 'την ',
      thecompany: '📅 Η επιχείρηση ',
      setaninterview: ' όρισε συνέντευξη ',
      dateformat: 'DD/MM/YYYY, στις HH:mm',
      theaddressis: '. Η διεύθυνση είναι ',
      deleteconvanduserTitle: 'Διαγραφή συνομιλίας και απόρριψη υποψηφίου',
      deleteconvanduserMsg: 'Εφόσον διαγράψετε τη συνομιλία, θα απορριφθούν αυτόματα όλες οι επιλεγμένες αιτήσεις του υποψηφίου.',
      deleteconvanduserBtn: 'Διαγραφη',
      messages: 'Συνομιλίες',
      position: 'Θέση',
      status: 'Κατάσταση',
      statusAll: 'Όλες',
      statusUnread: 'Μη Αναγνωσμένες',
      statusNointerview: 'Χωρίς Ραντεβού',
      statusWithinterview: 'Με Ραντεβού',
      statusNoreview: 'Χωρίς Αξιολόγηση',
      statusWithgoodreview: 'Με Θετική Αξιολόγηση',
      noshortlist: 'Δεν έχετε επιλέξει ακόμα κάποιο υποψήφιο ή έχετε διαγράψει όλες τις συνομιλίες',
      bookinterview: 'Ορίστε συνάντηση',
      reportuser: 'Αναφορά Χρήστη',
      deleteconversation: 'Διαγραφή συνομιλίας',
      selectconversation: 'Επιλέξτε μια συνομιλία',
    },
    NewCompany: {
      steps: 'Εύρεση Εταιρείας#Όνομα Εταιρείας#Προσθήκη Τηλεφώνου#Επιλογή Φωτογραφίας#Επιβεβαίωση',
      changeAddress: 'Αλλαγή Διεύθυνσης',
      addName: 'Προσθέστε το όνομα της επιχείρησης σας',
      changeName: 'Αλλαγή Ονόματος',
      addPhone: 'Προσθέστε το τηλέφωνο της επιχείρησης σας',
      changePhone: 'Αλλαγή Τηλεφώνου',
      selectPhoto: 'Επιλογή Φωτογραφίας',
      help: 'Μπορείτε να με βοηθήσετε να προσθέσω την επιχείρηση μου;',
      congratulations: 'Συγχαρητήρια, μόλις προσθέσατε την επιχείρηση σας!',
      changesSaved: 'Οι αλλαγές σας αποθηκεύτηκαν',
      openFirstjob: 'Ανοιξτε την πρωτη σας θεση εργασιας',
      returnTojobs: 'Επιστροφη στις θεσεις εργασιας σας',
      cantFindCompany: 'Δεν βρίσκετε την επιχείρηση σας;',
      previous: 'Προηγουμενο',
      confirm: 'Επιβεβαιωση',
      nextStep: 'Επομενο',
    },
    Payments: {
      success: 'Επιτυχής αγορά αγγελιών',
      error: 'Υπήρξε κάποιο σφάλμα στη χρέωση',
      title: 'Αγορά Αγγελιών MyJobNow',
      invoice: 'Στοιχεία Τιμολόγησης',
      companyname: 'Επωνυμία',
      companynameExample: 'π.χ. MyJobNow IKE',
      billing_address: 'Οδός & Αριθμός',
      billing_addressExample: 'π.χ. Μακεδόνων 8',
      billing_city: 'Πόλη',
      billing_cityExample: 'π.χ. Αθήνα',
      billing_zip_code: 'T.K.',
      billing_zip_codeExample: 'π.χ. 11523',
      companyphone: 'Τηλέφωνο',
      companyphoneExample: 'π.χ. 2106777777',
      companyvat: 'Α.Φ.Μ.',
      companyvatExample: 'π.χ. 888888888',
      companyvatoffice: 'Δ.Ο.Υ.',
      companyvatofficeExample: 'π.χ. ΙΓ Αθηνών',
      cardinfo: 'Στοιχεία Κάρτας',
      discountcode: 'Κωδικός Έκπτωσης',
      redeem: 'εξαργυρωση',
      codeisredeemed: 'Έχετε ενεργοποιήσει των κωδικό έκπτωσης. Με το που αγοράσετε μία αγγελία, θα λάβετε άλλη μία δώρο!',
      buy: 'Αγορα Αγγελιων',
      continue: 'ΣΥΝΕΧΕΙΑ',
    },
    Profile: {
      on_trial: 'Δοκιμαστική Περίοδο',
      trial_over: 'Τέλος Δοκιμαστικής Περιόδου',
      has_credits: 'Ενεργή Συνδρομή',
      no_credits: 'Τέλος Συνδρομής',
      banned: 'Κλειδωμένος Λογαριασμός',
      heading: 'Προφίλ',
      logout: 'Αποσύνδεση',
      accountstatus: 'Κατάσταση λογαριασμού:',
      activecompanies: 'Ενεργές Επιχειρήσεις / Καταστήματα:',
      addbusiness: 'Προσθηκη Καταστηματος',
      companyname: 'Όνομα',
      companyaddress: 'Διεύθυνση',
      manageusers: 'Διαχείριση Χρηστών:',
      adduser: 'Προσθηκη Χρηστη',
      username: 'Όνομα Χρήστη',
      numbercompanies: 'Αριθμός Καταστημάτων',
      isadmin: 'Διαχειριστής',
      language: 'Γλώσσα',
    },
    Signin: {
      heading: 'Είσοδος στο λογαριασμό μου',
      note1: '*Αν δεν έχετε λογαριασμό κάντε',
      note2: 'εγγραφή εδώ',
      cta: 'Βρες το προσωπικό που θέλεις',
      bullet1: 'Ρύθμισε τις προτιμήσεις σου σε δευτερόλεπτα',
      bullet2: 'Λάβε αιτήσεις από τα πρώτα λεπτά',
      bullet3: 'Κάλυψε τις ανάγκες σου σήμερα',
      tagline: 'Ξέχνα την ταλαιπωρία από τη διαδικασία αναζήτησης προσωπικού και αφοσιώσου στην ανάπτυξη της επιχείρησής σου.',
      terms: 'Όροι χρήσης',
      privacy: 'πολιτική απορρήτου',
      and: ' και ',
    },
    Signout: {
      heading: 'Αποσύνδεση',
    },
    Signup: {
      heading: 'Εγγραφή στην εφαρμογή',
      acceptterms1: '*Κάνοντας εγγραφή αποδέχεστε τους',
      acceptterms2: 'όρους χρήσης',
      acceptterms3: 'και την',
      acceptterms4: 'πολιτική απορρήτου',
      adblock: '*Απενεργοποιήστε το adblock για να δουλέψει η εφαρμογή',
      heading2: 'Ψάχνετε Προσωπικό;',
      description: 'Ανεβάστε θέσεις εργασίας και λάβετε αιτήσεις από υποψηφίους.',
      carousel1: 'Ανεβάστε Αγγελία',
      carousel2: 'Λάβετε αιτήσεις στην εφαρμογή',
      carousel3: 'Αξιολογήστε τα βιογραφικά των υποψηφίων',
      carousel4: 'Επικοινωνήστε μαζί τους να κλείσετε ραντεβού',
      pickcountry: 'Επιλέξτε χώρα',
      greece: 'Ελλάδα',
      italy: 'Ιταλία',
    },
    Verify: {
      heading: 'Καλώς Ορίσατε στο MyJobNow!',
      text1: 'Παρακαλούμε να επιβεβαιώσετε τη διεύθυνση email με την οποία κάνατε την εγγραφή σας.',
      text2: 'Μόλις σας στείλαμε ένα email επιβεβαίωσης το οποίο πρέπει να το ανοίξετε και να πατήσετε στο κουμπί "επιβεβαίωση".',
      text3: '*Σε περίπτωση που δεν έχετε λάβει κάποιο email, παρακαλώ κοιτάξετε το φάκελο spam.',
    },
    AlertDialog: {
      cancel: 'Ακυρωση',
    },
    ApplicantCard: {
      compatibility: '% Συμβατότητα ',
      details: 'Λεπτομερειες',
      contact: 'Επικοινωνια',
      reject: 'Απορριψη',
    },
    ApplicationDetails: {
      less6months: '<6 μήνες',
      between612months: '6-12 μήνες',
      between13years: '1-3 έτη',
      between35years: '3-5 έτη',
      less5years: '>5 έτη',
      soon: ' σύντομα',
      timein: 'σε ',
      hours: ' ώρες',
      hour: ' ώρα',
      minutes: ' λεπτά',
      year: '1 ΕΤΟΣ',
      years: ' ΕΤΗ',
      month: '1 ΜΗΝΑ',
      months: ' ΜΗΝΕΣ',
      expires1: 'Η αίτηση θα λήξει',
      expires2: 'Αν δεν απαντήσετε, θα απορριφθεί αυτόματα.',
      compatibility: '% συμβατότητα',
      area: 'Περιοχή:',
      age: 'Ηλικία:',
      ageold: 'ετών',
      tagline: 'Λίγα λόγια για μένα:',
      experience: 'Προϋπηρεσία:',
      uptonow: 'ΣΗΜΕΡΑ',
      noexperience: 'Δεν έχει προϋπηρεσία',
      noexperiencedeclared: 'Δεν έχει δηλώσει κάποια προϋπηρεσία',
      education: 'Εκπαίδευση:',
      skills: 'Δεξιότητες:',
      languages: 'Γλώσσες - Επίπεδο',
      langlevelbeginner: 'Βασικό',
      langlevelintermediate: 'Μεσαίο',
      langleveladvanced: 'Προχωρημένο',
      langlevelnative: 'Μητρική',
      transportation: 'Μεταφορικό Όχημα',
      motorbike: 'Μηχανάκι',
      car: 'Αυτοκίνητο',
      licenses: 'Διπλώματα Οδήγησης',
      licenseC: 'Γ',
      licenseD: 'Δ',
      cpc: 'ΠΕΙ',
      dtc: 'Ψηφιακή Κάρτα Ταχογράφου',
      Forklift: 'Πιστοποιητικά Ανυψωτικού Μηχανήματος (Κλάρκ)',
      forklift_license: 'Δίπλωμα Χειριστή Ανυψωτικού Μηχανήματος (Κλάρκ)',
      forklift_certificate: 'Βεβαίωση αναγγελίας Χειρισμού Ανυψωτικού Μηχανήματος (Κλάρκ)',
      applytospeak: '*Κάντε επιλογή στην αίτηση ώστε να επικοινωνήσετε απευθείας με τον ενδιαφερόμενο',
      contactdirectly: '*Επικοινωνήστε απευθείας με τον ενδιαφερόμενο',
      reject: 'Απορριψη',
      shortlist: 'Επιλογη',
      contactnow: 'Επικοινωνηστε',
      communication: 'Επικοινωνια',
      cv: 'Βιογραφικο',
      history: 'Ιστορικό',
      shortlisted: 'Επιλέχθηκε',
      reported: 'Έγινε αναφορά',
      rejected: 'Απορρίφθηκε',
      rejected_after_interview: 'Απορρίφθηκε μετά από ραντεβού',
      expired: 'Έληξε η αίτηση',
      confirmed_interview: 'Επιβεβαιωμένο ραντεβού',
      came_to_interview: 'Ήρθε στο ραντεβού',
    },
    ApplicationHolder: {
      crisp: 'Ενδιαφέρομαι να αγοράσω το πακέτο StaffNow για τη θέση μου.',
      onboardTitle: 'Συγχαρητήρια, μόλις επιλέξατε τον πρώτο υποψήφιο για τη θέση εργασίας σας!',
      onboardText: 'Τώρα μπορείτε να επικοινωνήσετε απευθείας μαζί του μέσω chat ή τηλεφώνου ώστε να μάθετε παραπάνω πληροφορίες και να κανονίσετε μια συνάντηση.',
      onboardConfirm: 'Κλεισιμο',
      rejectTitle: 'Απόρριψη υποψηφίου',
      rejectText: 'Εφόσον απορρίψετε τον υποψήφιο, δεν θα μπορείτε πλέον να επικοινωνήστε μαζί του.',
      rejectConfirm: 'Απορριψη',
      applications: 'Αιτήσεις',
      application: 'Αίτηση',
      expires: 'Λήξη:',
      renews: 'Ανανέωση:',
      yes: 'Ναι',
      no: 'Όχι',
      pending: 'Σε αναμονη',
      shortlisted: 'Επιλεγμενοι',
      shortlistedInfo: 'Επιλογές',
      suggested: 'Προτεινομενοι',
      more: 'Περισσότερες',
      reopenCTA: 'Επανενεργοποιηση',
      reopenText1: 'Η θέση εργασίας σας έχει απενεργοποιηθεί επειδή φτάσατε το μέγιστο αριθμό αιτήσεων χωρίς να τις αξιολογήσετε.',
      reopenText2: 'Μπορείτε να ενεργοποιήσετε ξανά τη θέση πατώντας στο παρακάτω κουμπί.',
      nonewapplications: 'Δεν υπάρχουν νέες αιτήσεις',
      noapplications1: 'Αυτή τη στιγμή δεν έχετε δεχθεί κάποια αίτηση.',
      noapplications2: 'Όλες οι αιτήσεις εμφανίζονται έδω. Θα σας ειδοποιήσουμε μέσω email μόλις γίνει η πρώτη αίτηση από ενδιαφερόμενο.',
      noshortlists: 'Δεν έχετε επιλέξει κάποιο υποψήφιο',
      numbermatching: 'Βρήκαμε {{numseekers}} υποψήφιους που ταιριάζουν στις προτιμήσεις σας',
      contactnow: 'Ειδοποιηστε τους τωρα',
      addprefs1: 'Μπορείτε να προσθέσετε ή να αφαιρέσετε κριτήρια προτιμήσεων στις',
      addprefs2: 'ρυθμίσεις',
      addprefs3: 'της αγγελίας σας',
      hidden: 'Η αγγελία είναι κρυμμένη',
      expiring: 'Αιτήσεις λήγουν σύντομα',
      pendinglimit: 'Όριο μη αξιολογημένων αιτήσεων',
      pendingsoon: 'Πλησιάζετε το όριο μη αξιολογημένων αιτήσεων. Αξιολογήστε τις ώστε να μην κρυφτεί η θέση σας.',
      reviewall: 'Έχετε φτάσει το μέγιστο αριθμό μη αξιολογημένων αιτήσεων. Αξιολογήστε τις ώστε να μπορέσετε να λάβετε παραπάνω.',
      jobactivatedagain: 'Η αγγελία εμφανίζεται ξανά σε όλους τους υποψηφίους',
      noapplications3: '*Η κάθε αίτηση λήγει αυτόματα 48 ώρες αφού τη λάβετε.',
    },
    BookCalendar: {
      time: 'Ωρα',
      choosedatetime: 'Επιλέξτε τη μέρα και ώρα του ραντεβού',
      pickaddress: 'Επιλέξτε τη διεύθυνση για το ραντεβού',
      add: 'Προσθήκη',
      confirm: 'Επιβεβαιωση',
      cancel: 'Ακυρωση',
      interviewText: 'Δεν έχετε ορίσει συνάντηση',
      dateformat: 'DD/MM/YYYY, στις HH:mm',
      notconfirmed: 'Δεν έχει επιβεβαιωθεί',
      nofirstmessageTitle: 'Δεν έχετε επικοινωνήσει ακόμα με τον υποψήφιο',
      nofirstmessageMsg: 'Προτείνουμε να συνεννοηθείτε πρώτα με τον υποψήφιο ώστε να σιγουρέψετε ότι είναι διαθέσιμος.',
      nofirstmessageBtn: 'Οριστε συναντηση',
    },
    Chatlist: {
      moreconversations: 'ΠΑΛΙΟΤΕΡΕΣ ΣΥΝΟΜΙΛΙΕΣ',
      noconversations: 'Δεν έχετε συνομιλίες με τα επιλεγμένα φίλτρα',
    },
    Chatmessages: {
      interviewtext: 'Μπορείτε να έρθετε για συνάντηση',
      dateformat: 'DD/MM/YYYY, στις HH:mm;',
      address: 'Η διεύθυνση είναι',
      interviewconfirm: 'Ναι, θα έρθω στη συνάντηση. Ευχαριστώ',
      interviewreject: 'Δυστυχώς, δεν μπορώ να έρθω στη συνάντηση',
      setinterview: 'Πατήστε εδώ για να ορίσετε συνάντηση για',
      placeholder: 'Μήνυμα...',
    },
    ClosedJobs: {
      success: 'Η θέση άνοιξε ξανά',
      alert: 'Εφόσον ανοίξετε τη θέση, θα εμφανίζεται ξανά στους υποψηφίους και θα δέχεστε νέες αιτήσεις.',
      alertcredit: 'Ανοίγοντας τη θέση ξανά θα χρεωθείτε για μια αγγελία MyJobNow.',
      promptTitle: 'Θέλετε να ανοίξετε τη θέση ξανά;',
      promptConfirm: 'Ανοιγμα Θεσης',
      open: 'ΑΝΟΙΓΜΑ',
    },
    CompanyFind: {
      interviewaddress: 'Νέα διεύθυνση για ραντεβού',
      addcompany: 'Προσθήκη νέας εταιρείας',
      welcome: 'Καλώς Ορίσατε στο MyJobNow!',
      startbyaddingcompany: 'Ξεκινήστε προσθέτοντας την επιχείρηση σας',
      noresults: 'Δεν βρέθηκαν αποτελέσματα. Δοκιμάστε με τη διεύθυνση.',
      nameoraddress: 'Όνομα ή Διεύθυνση της επιχείρησης',
      instructions: '*Εισάγετε το όνομα ή τη διεύθυνση της επιχείρησης σας στο πεδίο ώστε να δείτε τα αποτελέσματα της αναζήτησης. Μόλις εντοπίσετε την επιχείρηση ή τη διεύθυνση στη λίστα αποτελεσμάτων, πατήστε πάνω στην επιλογή.',
    },
    CompanyPreview: {
      confirmcompany: 'Επιβεβαιώστε τα στοιχεία της εταιρείας',
      phone: 'Τηλέφωνο:',
      address: 'Διεύθυνση',
    },
    CreditsNotice: {
      trialTitle: 'Είστε σε δοκιμαστική περίοδο.',
      trialText: 'Η θέση σας θα είναι ανοιχτή για <strong>4 ημέρες</strong> και μπορείτε να μιλήσετε με <strong>1 υποψήφιο</strong> μέσω του MyJobNow.<br /><br />Αγοράστε μία αγγελία MyJobNow ώστε να μπορείτε να μιλήσετε με <strong>παραπάνω υποψήφιους</strong> και η θέση να παραμείνει ανοιχτή για <strong>21 ημέρες</strong>.<br />',
      trialMsg: 'Καλησπέρα,\n\nθέλω να αγοράσω αγγελίες στο MyJobNow για την επιχείρηση μου',
      trialoverTitle: 'Τέλος Δωρεάν Χρήσης',
      trialoverText: 'Έχετε ήδη επιλέξει των 1 υποψήφιο που σας προσφέρει η δωρεάν δοκιμαστική περίοδος.<br /><br />Γιά να μιλήσετε με <strong>όλους τους υποψήφιους</strong> αγοράστε μία αγγελία MyJobNow και η θέση σας θα παραμείνει ανοιχτή για <strong>21 ημέρες</strong>.<br />',
      trialoverMsg: 'Καλησπέρα,\n\nθέλω να αγοράσω αγγελίες στο MyJobNow για την επιχείρηση μου',
      hassubTitle: 'Ενεργή συνδρομή μέχρι τις {{xxx}}',
      hassubText: 'Συγχαρητήρια, η συνδρομή σας είναι ανοιχτή! <span role="img" aria-label="smiling">🙂</span><br /><br />Μπορείτε να ανοίξετε θέσεις εργασίας και να μιλήσετε με απεριόριστους υποψήφιους.<br /><br />Για να ανανεώσετε τη χρήση σας θα πρέπει να αγοράσετε ένα από τα καινούργια μας πακέτα με μονάδες αγγελιών.<br /><br />Θέλετε βοήθεια;<br />',
      hassubMsg: 'Καλησπέρα,\n\nθέλω να αγοράσω αγγελίες στο MyJobNow για την επιχείρηση μου',
      hascreditsTitle: 'Έχετε {{count}} MyJobNow αγγελία',
      hascreditsTitle_plural: 'Έχετε {{count}} MyJobNow αγγελίες',
      hascreditsText: 'Μπορείτε να αγοράσετε παραπάνω αγγελίες για να ανοίξετε επιπλέον θέσεις ή να παραμείνει ανοιχτή η θέση εργασίας σας για παραπάνω μέρες.<br /><br />Θέλετε βοήθεια;<br />',
      hascreditsMsg: 'Καλησπέρα,\n\nθέλω να αγοράσω αγγελίες στο MyJobNow για την επιχείρηση μου',
      nocreditsTitle: 'Δεν έχετε άλλες αγγελίες MyJobNow',
      nocreditsText: 'Αγοράστε παραπάνω αγγελίες ώστε να ανοίξετε θέσεις εργασίας και να μπορείτε να μιλήσετε με παραπάνω υποψήφιους.<br />',
      nocreditsMsg: 'Καλησπέρα,\n\nθέλω να αγοράσω αγγελίες στο MyJobNow για την επιχείρηση μου',
      bannedTitle: 'Τέλος Δοκιμαστικής Περιόδου',
      bannedText: 'Λυπούμαστε. Η δοκιμαστική περίοδος σας έληξε και έχει κλείσει η αγγελία σας <span role="img" aria-label="disappointed">😞</span><br /><br />Για να δημοσιεύσετε μια νέα θέση εργασίας και να μιλήσετε με υποψηφίους πρέπει να <strong>αναβαθμίσετε το πακέτο χρέωσης</strong> σας.<br />',
      bannedMsg: 'Καλησπέρα,\n\nθέλω να αγοράσω αγγελίες στο MyJobNow για την επιχείρηση μου',
      multiuserTitle: 'Πακέτο Πολλαπλών Χρηστών',
      multiuserText: 'Για να προσθέσετε νέους χρήστες στην επιχείρηση σας πρέπει να <strong>αναβαθμίσετε το πακέτο χρέωσης</strong> σας.<br />',
      multiuserMsg: 'Καλησπέρα,\n\nθέλω να προσθέσω επιπλέον χρήστες στην επιχείρηση μου',
      noshortlistsTitle: 'Δεν έχετε άλλες επιλογές υποψηφίων',
      noshortlistsText: 'Έχετε κάνει όλες τις επιλογές υποψηφίων που περιέχει η αγγελία σας.  Μπορεί να αγοράσετε περισσότερες επιλογές όσο είναι ανοιχτή η αγγελία.',
      noshortlistsMsg: 'Καλησπέρα,\n\nθέλω να αγοράσω παραπάνω επιλογές υποψηφίων για την αγγελία μου',
      noshortlistsBTN: 'ΑΓΟΡΑ ΕΠΙΛΟΓΩΝ',
      shortlistsTitle: 'Πλησιάζετε το όριο των επιλογών',
      shortlistsText: 'Μόλις φτάσετε στο όριο των επιλογών που περιέχει η αγγελία σας, θα πρέπει να αγοράσετε παραπάνω επιλογές ώστε να συνεχίσετε να επιλέγετε υποψηφίους.',
      shortlistsMsg: 'Καλησπέρα,\n\nθέλω να αγοράσω παραπάνω επιλογές υποψηφίων για την αγγελία μου',
      shortlistsBTN: 'ΑΓΟΡΑ ΕΠΙΛΟΓΩΝ',
      contact: 'Επικοινωνια',
      purchase: 'Αγορα Αγγελιων',
    },
    Header: {
      profile: 'Προφίλ',
      signout: 'Αποσύνδεση',
      jobs: 'Θέσεις',
      messages: 'Μηνύματα',
      addcompany: 'Προσθήκη Εταιρείας',
      verify: 'Επιβεβαίωση email',
      interviews: 'Ραντεβού',
    },
    Feedback: {
      yes: 'Ναι',
      no: 'Οχι',
      report: 'Θέλετε να κάνετε αναφορά τον υποψήφιο;',
      didtheycome: 'Ήρθε ο υποψήφιος στη συνέντευξη;',
      review: 'Πως θα χαρακτηρίζατε τον υποψήφιο;',
      positive: 'Θετικα',
      negative: 'Αρνητικα',
      reviewtitle: 'Αξιολόγηση Υποψηφίου',
      noshow: '(1) Δεν ήρθε στο ραντεβού',
      hire: 'Θα προχωρήσετε σε πρόσληψη;',
      delete: 'Θέλετε να διαγράψετε τον υποψήφιο;',
    },
    JobNew: {
      positionClosed: 'Η θέση έκλεισε',
      positionEdit: 'Έγινε τροποποίηση στη θέση',
      positionNew: 'Καταχωρήθηκε η νέα θέση',
      closepositionTitle: 'Είστε σίγουροι ότι θέλετε να κλείσετε τη θέση;',
      closepositionText: 'Κλείνοντας τη θέση θα ειδοποιηθούν όλοι οι ενδιαφερόμενοι και δεν θα εμφανίζεται στην αναζήτηση.',
      closepositionBtn: 'Κλεισιμο Θεσης',
      nophoneTitle: 'Δεν μπορείτε να βάλετε τηλεφωνικό αριθμό ή email στην περιγραφή μιας θέσης ή να την αφήσετε κενή.',
      nophoneText: 'Εφόσον επιλέξετε έναν υποψήφιο μπορείτε να τους στείλετε τα στοιχεία επικοινωνίας σας σε προσωπικό μήνυμα στο chat.',
      nophoneBtn: 'Κλεισιμο',
      create: 'Δημιουργία νέας θέσης',
      edit: 'Προσαρμογή θέσης',
      active: 'Ενεργή',
      instructionsNew: 'Επιλέξτε την ειδικότητα και προσθέστε μια περιγραφή της θέσης.',
      instructionsEdit: 'Τροποποιήστε τις προτιμήσεις ή απενεργοποιήστε τη θέση.',
      requiredfields: 'Απαραίτητα Πεδία',
      skill: 'Ειδικότητα',
      pickskill: 'Επιλογή ειδικότητας',
      fulltime: 'Πλήρης',
      parttime: 'Μερική',
      typeofwork: 'Απασχόληση',
      seasonal: 'Σεζόν',
      description: 'Περιγραφή',
      descriptionplaceholder: 'Μερικές πληροφορίες για τη θέση',
      descriptionhelper: 'Σύντομη περιγραφή για τη θέση',
      havemotorbike: 'Να έχει δικό του μηχανάκι',
      licensecategory: 'Κατηγορία Διπλώματος',
      motorcycleA: "A' (Μοτοσυκλέτας)",
      carB: "Β' (Αυτοκινήτου)",
      truckC: "Γ' (Φορτηγού)",
      busD: "Δ' (Λεωφορείου)",
      trailerE: "Ε' (Καρότσα)",
      required: '(*Απαραίτητο)',
      cpc: 'Να έχει Πιστοποιητικό Επαγγελματικής Ικανότητας (ΠΕΙ)',
      dtc: 'Να έχει Κάρτα Ψηφιακού Ταχογράφου',
      forklift_license: 'Δίπλωμα Χειριστή Ανυψωτικού Μηχανήματος (Κλάρκ)',
      forklift_certificate: 'Βεβαίωση αναγγελίας Χειρισμού Ανυψωτικού Μηχανήματος (Κλάρκ)',
      hiddenpreferences: 'Κρυφές Προτιμήσεις',
      gender: 'Φύλο',
      allgender: 'Όλα',
      male: 'Άνδρας',
      female: 'Γυναίκα',
      exallgender: '(π.χ.: Όλα)',
      agefrom: 'Ηλικία από',
      exagefrom: '(π.χ.: 20)',
      ageto: 'Ηλικία έως',
      exageto: '(π.χ.: 35)',
      distance: 'Απόσταση',
      km2: '2 χλμ',
      km5: '5 χλμ',
      km10: '10 χλμ',
      km20p: '20+ χλμ',
      exdistance: '(π.χ.: 20+ χλμ)',
      optionalfields: 'Προαιρετικά Πεδία',
      shift: 'Ωράριο',
      morning: 'Πρωί',
      noon: 'Μεσημέρι',
      night: 'Βράδυ',
      rollingshift: 'Κυλιόμενο',
      shifthelp: '(Βάρδια)',
      yearsworkexperience: 'Έτη Εργασιακής Εμπειρίας',
      notrequired: 'Δεν απαιτείται',
      year: 'έτος',
      years: 'έτη',
      inthespecificskill: '(Στη συγκεκριμένη ειδικότητα)',
      salaryfrom: 'Μισθός από',
      exsalaryfrom: '(π.χ.: 3€)',
      salaryto: 'Μισθός έως',
      exsalaryto: '(π.χ.: 10€)',
      salaryper: 'ανά',
      hour: 'ώρα',
      day: 'μέρα',
      month: 'μήνα',
      exsalaryper: '(π.χ.: Μέρα)',
      showsalary: 'Να φαίνεται ο μισθός',
      education: 'Εκπαίδευση',
      educationdescription: '(Ελάχιστο επίπεδο)',
      languages: 'Γλώσσες',
      exlanguages: '(π.χ.: Αγγλικά)',
      langlevelbeginner: 'Βασικό',
      langlevelintermediate: 'Μεσαίο',
      langleveladvanced: 'Προχωρημένο',
      langlevelnative: 'Μητρική',
      language: 'Γλώσσα',
      picklanguage: 'Επιλογή Γλώσσας',
      languagelevel: 'Επίπεδο',
      languageadd: 'Προσθηκη',
      languageedit: 'Αλλαγη',
      languageremove: 'Αφαιρεση',
      autorenew: 'Αυτόματη Ανανέωση',
      cancel: 'Ακυρωση',
      publish: 'Δημοσιευση',
      save: 'Αποθηκευση',
      next: 'Επομενο',
      back: 'Προηγουμενο',
      enablePro: 'Ενεργοποίηση ρυθμίσεων PRO',
      procv: 'Βιογραφικά σε μορφή PDF',
      proshortlist: 'Αυτόματη Επιλογή Υποψηφίων',
      prochat: 'Αυτόματο Μήνυμα Επιλογής',
      prointerview: 'Αυτόματη Συνέντευξη μέσω Chat',
      probooking: 'Αυτόματος Προγραμματισμός Ραντεβού',
      upgradeRequest: 'Καλησπέρα,\n\nΘέλω να αναβαθμίσω την αγγελία μου σε MyJobNow PRO',
      havecar: 'Να έχει δικό του αυτοκίνητο',
      isexternal: 'Είναι εξωτερική θέση',
      proheadline: 'MyJobNow PRO Αυτοματισμοί',
    },
    MobileScreen: {
      title: 'Κατεβάστε την εφαρμογή στο κινητό σας!',
      cta: 'ΚΑΤΕΒΑΣΤΕ',
      text: 'Για να σας προσφέρουμε την καλύτερη δυνατή εμπειρία το MyJobNow στα κινητά υποστηρίζεται μόνο από την αντίστοιχη εφαρμογή.',
    },
    Navbar: {
      jobs: 'Θέσεις',
      messages: 'Μηνύματα',
      profile: 'Προφίλ',
      interviews: 'Ραντεβού',
    },
    PhotoUpload: {
      uploadphoto: 'Ανεβάστε Φωτογραφία',
    },
    Pricing: {
      month1: '1 ΜΗΝΑΣ',
      plusvat: '+ΦΠΑ',
      unlimitedpositions: 'Απεριόριστες Θέσεις Εργασίας',
      unlimitedshortlists: 'Απεριόριστες επιλογές υποψηφίων',
      select: 'Επιλογη',
      cancel: 'Ακυρωση',
      months3: '3 ΜΗΝΕΣ',
      freedays15: '15 ΜΕΡΕΣ ΔΩΡΟ',
      months12: '12 ΜΗΝΕΣ',
      freemonths2: '2 ΜΗΝΕΣ ΔΩΡΟ',
    },
    PricingCredits: {
      jobpost1: '1 ΑΓΓΕΛΙΑ',
      positionfordays: '{{count}} Θέση εργασίας για {{days}} μέρες',
      positionfordays_plural: '{{count}} Θέσεις εργασίας για {{days}} μέρες',
      skillsΝumberΤitle: 'Αριθμός επιλογών ανά ειδικότητα',
      checkSkillsNumber: 'Δες εδώ τον αριθμό των επιλογών ανά ειδικότητα',
      shortListsLabel: 'επιλογές',
      select: 'Επιλογη',
      cancel: 'Ακυρωση',
      jobposts3: '3 ΑΓΓΕΛΙΕΣ',
      discount: 'έκπτωση',
      job: '{{count}} ΑΓΓΕΛΙΑ',
      job_plural: '{{count}} ΑΓΓΕΛΙΕΣ',
      shortlist: '{{count}} ΕΠΙΛΟΓΗ ΥΠΟΨΗΦΙΩΝ',
      shortlist_plural: '{{count}} ΕΠΙΛΟΓΕΣ ΥΠΟΨΗΦΙΩΝ',
      vat: 'ΦΠΑ',
      loading: 'Φόρτωση Προϊόντων',
    },
    ReferFriend: {
      alertTitle: '1 Αγγελία δώρο για σας, 1 δώρο για όποιον καλέσετε!',
      alertText: 'Ο κωδικός πρόσκλησης σας είναι: {{refcode}}',
      alertBtn: 'Κλεισιμο',
      title: 'Κερδίστε δωρεάν αγγελίες για κάθε καινούργιο χρήστη που προσκαλείτε στο MyJobNow!',
      text: '*Με το που <strong>αγοράσει</strong> μια αγγελία ο χρήστης που προσκαλέσατε, θα λάβετε και οι δύο δώρο από μία αγγελία.',
      instructions: 'Οδηγιες',
    },
    ReportUserDialog: {
      noshow: 'Δεν ήρθε στο ραντεβού',
      noreply: 'Δεν απαντάει στα μηνύματα',
      fakeprofile: 'Ανακριβές προφίλ',
      badbehavior: 'Κακή συμπεριφορά',
      other: 'Άλλο',
      reportuserTitle: 'Αναφορά Χρήστη',
      reportuserText: 'Επιλέξτε την αιτία αναφοράς',
      reason: 'Αιτία',
      description: 'Περιγραφή',
      placeholder: 'Ο λόγος που θέλετε να κάνετε αναφορά για τον χρήστη',
      sorry: 'Λυπούμαστε που συνέβη, <strong>όμως δεν δεχόμαστε αναφορές για ραντεβού που δεν έχουν επιβεβαιωθεί μέσω της εφαρμογής από τους υποψηφίους.</strong><br /><br />Προτείνουμε να ορίζετε συνεντεύξεις μέσα από την εφαρμογή, <strong>πατώντας το εικονίδιο ημερολογίου</strong>, ώστε να ειδοποιούμε τους υποψηφίους για το ραντεβού.',
      cancel: 'Ακυρωση',
      report: 'ΑΝΑΦΟΡΑ',
      future: 'Το συγκεκριμένο ραντεβού έχει προγραμματιστεί για μελλοντική ώρα ή μέρα.',
    },
    UserManage: {
      edituser: 'Έγινε τροποποίηση του χρήστη',
      newuser: 'Καταχωρήθηκε νέος χρήστης',
      adduserTitle: 'Προσθήκη Χρήστη',
      edituserTitle: 'Τροποποίηση Χρήστη',
      addemail: 'Προσθέστε το email του χρήστη και επιλέξτε τα καταστήματα στα οποία θα έχει πρόσβαση.',
      editemail: 'Τροποποιήστε την πρόσβαση του χρήστη στα καταστήματα.',
      active: 'Ενεργή',
      isadmin: 'Διαχειριστής (έχει πρόσβαση σε όλα τα καταστήματα)',
      companies: 'Καταστήματα',
      cancel: 'Ακυρωση',
      add: 'Προσθηκη',
      edit: 'Αποθηκευση',
    },
    Interviews: {
      title: 'Ραντεβού',
      reschedule: 'επαναπρογραμματισμος',
      messages: 'μηνυματα',
      review: 'αξιολογηση',
      nointerviews1: 'Δεν έχετε προτείνει ακόμα ραντεβού σε κάποιο υποψήφιο',
      nointerviews2: 'Μπορείτε να ορίσετε ραντεβού μέσα από τα μηνύματα με τον υποψήφιο.',
      filter: 'Φίλτρο',
      filterAll: 'Όλα',
      filterConfirmed: 'Επιβεβαιωμένα',
      filterNotConfirmed: 'Μη Επιβεβαιωμένα',
      filterNoreview: 'Χωρίς Αξιολόγηση',
      filterWithgoodreview: 'Με Θετική Αξιολόγηση',
    },
    CompanySwitcher: {
      search: 'Αναζήτηση',
    },
  },
};
