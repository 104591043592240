import { Component, createRef, forwardRef } from 'react';
import MediaQuery from 'react-responsive';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import { withTranslation } from 'react-i18next';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AlertDialog from './AlertDialog';
import { trackEvent } from '../Lib/analytics';
import { apiGetMulti, apiPost } from '../Lib/requests';
import PickLanguage from './PickLanguage';
import { crispShowMessage, hideCrispChatBox, showCrispChatBox } from '../Lib/crispUtils';

let availableLanguages = [];
let educationLevelsSorted = [];

const Transition = forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

const validateDescription = (text) => {
  const whiteSpace = text.replace(/^\s+|\s+$|\s+(?=\s)/g, '');
  const phoneRE = /(1\s*[-/.]?)?(\((\d{3})\)|(\d{3}))\s*[-/.]?\s*(\d{3})\s*[-/.]?\s*(\d{4})\s*(([xX]|[eE][xX][tT])\.?\s*(\d+))*/;
  const phoneRE2 = /(1\s*[-/.]?)?(\((\d{3})\)|(\d{3}))\s*[-/.]?\s*(\d{4})\s*[-/.]?\s*(\d{3})\s*(([xX]|[eE][xX][tT])\.?\s*(\d+))*/;
  const emailRE = /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}/;
  return (
    phoneRE.test(text)
    || phoneRE2.test(text)
    || emailRE.test(text)
    || (whiteSpace.length === 0 && text.length !== 0)
  );
};

const WrappedAlertDialog = withTranslation('translation', { withRef: true })(
  AlertDialog,
);

const WrappedLanguageDialog = withTranslation('translation', { withRef: true })(
  PickLanguage,
);

class JobNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      allSkills: [],
      skill: '',
      description: '',
      seasonal: false,
      open: false,
      id: null,
      auto_consume_credit: true,
      fulltime: 'true',
      experience: '',
      salaryMin: '',
      salaryMax: '',
      salaryInterval: '',
      salaryVisible: true,
      requiredFieldsError: false,
      shift: '',
      languages: [],
      minimum_education_level: '',
      gender: 'any',
      ageMin: '',
      ageMax: '',
      ageError: false,
      distance: 'null',
      requires_moped: true,
      requires_car: false,
      is_external: false,
      drivers_license: {
        category: '',
        req_cpc: false,
        req_tacho_card: false,
      },
      forklift: {
        forklift_license: false,
        forklift_certificate: false,
      },
    };
    this.alertDialog = createRef();
    this.validDialog = createRef();
    this.languageDialog = createRef();
  }

  componentDidMount() {
    apiGetMulti([
      '/skills_available',
      '/spoken_languages_available',
      '/education_levels',
    ]).then(
      axios.spread((skills, langs, educationLevels) => {
        availableLanguages = langs.data;
        educationLevelsSorted = educationLevels.data.sort(
          (a, b) => a.level - b.level,
        );
        this.setState({ allSkills: skills.data });
      }),
    );
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleClickOpen = (editJob = false, storedJob = false) => {
    if (editJob) {
      const salaryAmount = editJob.public_requirements.salary !== null
      && editJob.public_requirements.salary.amount !== null
        ? editJob.public_requirements.salary.amount.toString()
        : '';
      this.setState({
        open: true,
        skill: editJob.basic_info.skill.id,
        description: editJob.basic_info.description,
        seasonal: editJob.basic_info.seasonal,
        id: editJob.id,
        auto_consume_credit: editJob.payment_info.auto_consume_credit,
        fulltime:
          editJob.public_requirements.fulltime !== null
            ? editJob.public_requirements.fulltime.toString()
            : 'true',
        experience:
          editJob.public_requirements.required_experience !== null
            ? editJob.public_requirements.required_experience.toString()
            : '',
        salaryMin:
          editJob.public_requirements.salary !== null
          && editJob.public_requirements.salary.min !== null
            ? editJob.public_requirements.salary.min.toString()
            : salaryAmount,
        salaryMax:
          editJob.public_requirements.salary !== null
          && editJob.public_requirements.salary.max !== null
            ? editJob.public_requirements.salary.max.toString()
            : '',
        salaryInterval:
          editJob.public_requirements.salary !== null
          && editJob.public_requirements.salary.interval !== null
            ? editJob.public_requirements.salary.interval
            : '',
        salaryVisible:
          editJob.public_requirements.salary_visible !== null
            ? editJob.public_requirements.salary_visible
            : true,
        shift:
          editJob.public_requirements.shift !== null
            ? editJob.public_requirements.shift
            : '',
        requires_moped:
          editJob.public_requirements.requires_moped !== null
            ? editJob.public_requirements.requires_moped
            : null,
        requires_car:
          editJob.public_requirements.requires_car !== null
            ? editJob.public_requirements.requires_car
            : null,
        is_external:
          editJob.public_requirements.is_external !== null
            ? editJob.public_requirements.is_external
            : null,
        drivers_license:
          editJob.public_requirements.drivers_license !== undefined
          && editJob.public_requirements.drivers_license !== null
          && editJob.public_requirements.drivers_license.category !== null
            ? editJob.public_requirements.drivers_license
            : {
              category: '',
              req_cpc: false,
              req_tacho_card: false,
            },
        forklift:
          editJob.public_requirements.certificates !== undefined
          && editJob.public_requirements.certificates !== null
          && editJob.public_requirements.certificates.forklift !== undefined
          && editJob.public_requirements.certificates.forklift !== null
            ? editJob.public_requirements.certificates.forklift
            : {
              forklift_license: false,
              forklift_certificate: false,
            },
        minimum_education_level:
          editJob.public_requirements.minimum_education_level !== null
            ? editJob.public_requirements.minimum_education_level.id
            : '',
        languages:
          editJob.public_requirements.languages !== null
            ? editJob.public_requirements.languages
            : [],
        gender:
          editJob.private_requirements !== null
          && editJob.private_requirements.gender !== null
            ? editJob.private_requirements.gender
            : 'any',
        ageMin:
          editJob.private_requirements !== null
          && editJob.private_requirements.age.min !== null
            ? editJob.private_requirements.age.min.toString()
            : '16',
        ageMax:
          editJob.private_requirements !== null
          && editJob.private_requirements.age.max !== null
            ? editJob.private_requirements.age.max.toString()
            : '65',
        distance:
          editJob.private_requirements !== null
          && editJob.private_requirements.distance !== null
          && editJob.private_requirements.distance !== 0
            ? editJob.private_requirements.distance.toString()
            : 'null',
      });
    } else if (storedJob) {
      storedJob.open = true;
      this.setState(storedJob);
    } else {
      this.setState({ open: true });
      trackEvent('EMP_Action_NewJobCreate', {
        user_id: this.props.user.id,
      });
    }
    if (window.innerWidth < 800) {
      hideCrispChatBox();
    }
  };

  handleClose = (dontLogCancelEvent = false) => {
    this.setState({
      activeStep: 0,
      open: false,
      skill: '',
      description: '',
      seasonal: false,
      id: null,
      auto_consume_credit: true,
      fulltime: 'true',
      experience: '',
      salaryMin: '',
      salaryMax: '',
      salaryInterval: '',
      salaryVisible: true,
      requiredFieldsError: false,
      shift: '',
      languages: [],
      minimum_education_level: '',
      gender: 'any',
      ageMin: '',
      ageMax: '',
      ageError: false,
      distance: 'null',
      requires_moped: true,
      requires_car: false,
      is_external: false,
      drivers_license: {
        category: '',
        req_cpc: false,
        req_tacho_card: false,
      },
      forklift: {
        forklift_license: false,
        forklift_certificate: false,
      },
    });

    if (dontLogCancelEvent === false) {
      trackEvent('EMP_Action_NewJobCancel', {
        user_id: this.props.user.id,
      });
    }

    showCrispChatBox();
  };

  addLanguage = (lang, isEdit) => {
    if (lang && lang.knowledge_level === '') {
      lang.knowledge_level = null;
    }
    if (isEdit === false) {
      this.setState((prevState) => ({ languages: [...prevState.languages, lang] }));
    } else {
      this.setState((prevState) => ({
        languages: prevState.languages.map((l) => (l.id === lang.id ? lang : l)),
      }));
    }
  };

  removeLanguage = (langId) => {
    this.setState((prevState) => ({
      languages: prevState.languages.filter((lang) => lang.id !== langId),
    }));
  };

  handleChange = (name) => (e) => {
    if (this.state.activeStep === 0) {
      this.setState({ requiredFieldsError: false });
    } else if (this.state.activeStep === 2) {
      this.setState({ ageError: false });
    }

    if (
      name === 'seasonal'
      || name === 'auto_consume_credit'
      || name === 'salaryVisible'
      || name === 'requires_moped'
      || name === 'requires_car'
      || name === 'is_external'
      || name === 'drivers_license.req_tacho_card'
      || name === 'drivers_license.req_cpc'
      || name === 'forklift.forklift_license'
      || name === 'forklift.forklift_certificate'
    ) {
      e.persist();
      if (
        name.includes('drivers_license.') === false
        && name.includes('forklift.') === false
      ) {
        this.setState({ [name]: e.target.checked });
      } else {
        const namekeys = name.split('.');
        this.setState((prevState) => ({
          [namekeys[0]]: {
            ...prevState[namekeys[0]],
            [namekeys[1]]: e.target.checked,
          },
        }));
      }
    } else if (name === 'description' && validateDescription(e.target.value)) {
      this.validDialog.current.handleClickOpen();
    } else if (name !== 'drivers_license.category') {
      this.setState({ [name]: e.target.value });
    } else {
      const namekeys = name.split('.');
      this.setState((prevState) => ({
        [namekeys[0]]: {
          ...prevState[namekeys[0]],
          [namekeys[1]]: e.target.value,
        },
      }));
    }

    if (name === 'skill') {
      trackEvent('EMP_Action_NewJobSelectSkill', {
        user_id: this.props.user.id,
        skill_id: e.target.value,
      });
    }
  };

  clearDescription = () => {
    this.setState({ description: '' });
  };

  handlePositionCloseClick = () => {
    this.alertDialog.current.handleClickOpen();
  };

  handlePositionCloseConfirm = () => {
    apiPost('/job/close', {
      id: this.state.id,
    }).then(() => {
      this.props.onSuccess(this.props.t('JobNew.positionClosed'));
      trackEvent('EMP_Action_ClosePosition', {
        user_id: this.props.user.id,
      });
    });
    this.handleClose(true);
  };

  handleSubmit = () => {
    const edit = this.state.id;
    const salaryIntervalFirstCond = this.state.salaryInterval
      || (this.state.salaryMin || this.state.salaryMax);
    apiPost(
      '/job',
      {
        id: this.state.id,
        auto_consume_credit: this.state.auto_consume_credit,
        basic_info: {
          description: this.state.description,
          skill: { id: this.state.skill },
          seasonal: this.state.seasonal,
        },
        public_requirements: {
          fulltime: this.state.fulltime,
          shift: this.state.shift ? this.state.shift : null,
          required_experience: this.state.experience
            ? this.state.experience
            : 0,
          requires_moped:
            this.state.skill === +process.env.REACT_APP_SKILLS_WITH_MOPED
              ? this.state.requires_moped
              : null,
          requires_car:
            this.state.skill === +process.env.REACT_APP_SKILLS_WITH_CAR
            && this.state.is_external === true
              ? this.state.requires_car
              : null,
          is_external:
            this.state.skill === +process.env.REACT_APP_SKILLS_WITH_EXTERNAL
              ? this.state.is_external
              : null,
          drivers_license:
            this.state.skill === +process.env.REACT_APP_SKILLS_WITH_LICENSE
            && this.state.drivers_license.category !== ''
              ? this.state.drivers_license
              : null,
          certificates: {
            forklift:
              this.state.skill
              === +process.env.REACT_APP_SKILLS_WITH_CERTIFICATES
                ? this.state.forklift
                : null,
          },
          salary: {
            min: this.state.salaryMin
              ? this.state.salaryMin.replace(',', '.')
              : null,
            max: this.state.salaryMax
              ? this.state.salaryMax.replace(',', '.')
              : null,
            interval: salaryIntervalFirstCond || null,
          },
          salary_visible: this.state.salaryVisible,
          languages: this.state.languages ? this.state.languages : [],
          minimum_education_level: this.state.minimum_education_level
            ? { id: this.state.minimum_education_level }
            : null,
        },
        private_requirements: {
          distance:
            this.state.distance && this.state.distance !== 'null'
              ? this.state.distance
              : null,
          gender: this.state.gender ? this.state.gender : 'any',
          age: {
            min:
              this.state.ageMin && this.state.ageMin >= 16
                ? this.state.ageMin
                : 16,
            max:
              this.state.ageMax && this.state.ageMax <= 65
                ? this.state.ageMax
                : 65,
          },
        },
      },
      'v3',
    )
      .then(() => {
        this.props.onSuccess(
          edit
            ? this.props.t('JobNew.positionEdit')
            : this.props.t('JobNew.positionNew'),
        );
        const eventName = edit
          ? 'EMP_Action_EditPosition'
          : 'EMP_Action_NewJobSave';

        const params = { user_id: this.props.user.id };
        if (!edit) {
          params.skill_id = this.state.skill;
        }

        trackEvent(eventName, params);
        this.handleClose(true);
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 402) {
          localStorage.setItem('jobnocredits', JSON.stringify(this.state));
          this.props.showCredits();
        }
        this.handleClose(true);
      });
  };

  getSteps = () => {
    const { t } = this.props;
    return [
      t('JobNew.requiredfields'),
      t('JobNew.optionalfields'),
      t('JobNew.hiddenpreferences'),
      'MyJobNow PRO',
    ];
  };

  getStepContent = (step) => {
    const { t } = this.props;
    switch (step) {
      case 0:
        return (
          <div className="wrapper-fields">
            <div className="required-fields">
              <DialogContentText className="group-title">
                {t('JobNew.requiredfields')}
              </DialogContentText>
              <div className="formGroup">
                <FormControl>
                  <InputLabel htmlFor="skill-native-helper">
                    {t('JobNew.skill')}
                  </InputLabel>
                  <Select
                    error={
                    this.state.requiredFieldsError && this.state.skill === ''
                  }
                    className="formSelect"
                    value={this.state.skill}
                    onChange={this.handleChange('skill')}
                    input={<Input id="skill-native-helper" />}
                    disabled={!!this.state.id}
                    variant="standard"
                  >
                    <MenuItem value="" disabled />
                    {this.state.allSkills.map((skill) => (
                      <MenuItem key={skill.id} value={skill.id}>
                        {skill.display_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {this.state.id ? false : (
                    <FormHelperText>{t('JobNew.pickskill')}</FormHelperText>
                  )}
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="fulltime-native-helper" />
                  <Select
                    className="formSelect"
                    value={this.state.fulltime}
                    onChange={this.handleChange('fulltime')}
                    input={<Input id="fulltime-native-helper" />}
                    variant="standard"
                  >
                    <MenuItem value="true">{t('JobNew.fulltime')}</MenuItem>
                    <MenuItem value="false">{t('JobNew.parttime')}</MenuItem>
                  </Select>
                  <FormHelperText>{t('JobNew.typeofwork')}</FormHelperText>
                </FormControl>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={this.state.seasonal}
                      onChange={this.handleChange('seasonal')}
                      value="seasonal"
                      color="primary"
                    />
                )}
                  label={t('JobNew.seasonal')}
                />
              </div>
              <div>
                {this.state.skill
                === +process.env.REACT_APP_SKILLS_WITH_EXTERNAL ? (
                  <>
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={this.state.is_external}
                          onChange={this.handleChange('is_external')}
                          value="is_external"
                          color="primary"
                        />
                      )}
                      label={t('JobNew.isexternal')}
                    />
                    <br />
                  </>
                  ) : false}
                {this.state.skill
                === +process.env.REACT_APP_SKILLS_WITH_MOPED ? (
                  <>
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={this.state.requires_moped}
                          onChange={this.handleChange('requires_moped')}
                          value="requires_moped"
                          color="primary"
                        />
                      )}
                      label={t('JobNew.havemotorbike')}
                    />
                    <br />
                  </>
                  ) : false}
                {this.state.skill === +process.env.REACT_APP_SKILLS_WITH_CAR
                && this.state.is_external === true ? (
                  <>
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={this.state.requires_car}
                          onChange={this.handleChange('requires_car')}
                          value="requires_car"
                          color="primary"
                        />
                      )}
                      label={t('JobNew.havecar')}
                    />
                    <br />
                  </>
                  ) : false}
                {this.state.skill
                === +process.env.REACT_APP_SKILLS_WITH_LICENSE ? (
                  <>
                    <FormControl className="formControl">
                      <InputLabel htmlFor="license-category-native-helper">
                        {t('JobNew.licensecategory')}
                      </InputLabel>
                      <Select
                        className="formSelect"
                        value={this.state.drivers_license.category}
                        onChange={this.handleChange('drivers_license.category')}
                        input={<Input id="license-category-native-helper" />}
                        variant="standard"
                      >
                        <MenuItem value="A">{t('JobNew.motorcycleA')}</MenuItem>
                        <MenuItem value="B">{t('JobNew.carB')}</MenuItem>
                        <MenuItem value="C">{t('JobNew.truckC')}</MenuItem>
                        <MenuItem value="D">{t('JobNew.busD')}</MenuItem>
                        <MenuItem value="E">{t('JobNew.trailerE')}</MenuItem>
                        ))
                      </Select>
                      <FormHelperText>{t('JobNew.required')}</FormHelperText>
                    </FormControl>
                    {['C', 'D', 'E'].includes(
                      this.state.drivers_license.category,
                    ) ? (
                      <>
                        <FormControlLabel
                          control={(
                            <Switch
                              checked={this.state.drivers_license.req_cpc}
                              onChange={this.handleChange(
                                'drivers_license.req_cpc',
                              )}
                              value="drivers_license.req_cpc"
                              color="primary"
                            />
                          )}
                          label={t('JobNew.cpc')}
                        />
                        <FormControlLabel
                          control={(
                            <Switch
                              checked={
                                this.state.drivers_license.req_tacho_card
                              }
                              onChange={this.handleChange(
                                'drivers_license.req_tacho_card',
                              )}
                              value="drivers_license.req_tacho_card"
                              color="primary"
                            />
                          )}
                          label={t('JobNew.dtc')}
                        />
                      </>
                      ) : (
                        <br />
                      )}
                    <br />
                  </>
                  ) : false}
                {this.state.skill
                === +process.env.REACT_APP_SKILLS_WITH_CERTIFICATES ? (
                  <>
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={this.state.forklift.forklift_license}
                          onChange={this.handleChange(
                            'forklift.forklift_license',
                          )}
                          value="forklift.forklift_license"
                          color="primary"
                        />
                      )}
                      label={t('JobNew.forklift_license')}
                    />
                    <FormControlLabel
                      control={(
                        <Switch
                          checked={this.state.forklift.forklift_certificate}
                          onChange={this.handleChange(
                            'forklift.forklift_certificate',
                          )}
                          value="forklift.forklift_certificate"
                          color="primary"
                        />
                      )}
                      label={t('JobNew.forklift_certificate')}
                    />
                  </>
                  ) : false}
              </div>
            </div>
            <div className="optional-fields">
              <TextField
                error={
                this.state.requiredFieldsError
                  && this.state.description === ''
              }
                id="multiline-flexible"
                label={t('JobNew.description')}
                multiline
                rows="1"
                value={this.state.description}
                placeholder={t('JobNew.descriptionplaceholder')}
                onChange={this.handleChange('description')}
                fullWidth
                margin="normal"
                className="data-hj-whitelist"
                helperText={t('JobNew.descriptionhelper')}
                variant="standard"
              />
              <div className="formGroup">
                <TextField
                  error={
                  this.state.requiredFieldsError
                    && this.validateSalary() === false
                }
                  label={t('JobNew.salaryfrom')}
                  value={this.state.salaryMin}
                  placeholder="3"
                  onChange={this.handleChange('salaryMin')}
                  margin="normal"
                  className="euro-icon data-hj-whitelist margin-right-ten"
                  helperText={t('JobNew.exsalaryfrom')}
                  variant="standard"
                />
                <TextField
                  error={
                  this.state.requiredFieldsError
                    && this.validateSalary() === false
                }
                  label={t('JobNew.salaryto')}
                  value={this.state.salaryMax}
                  placeholder="3"
                  onChange={this.handleChange('salaryMax')}
                  margin="normal"
                  className="euro-icon data-hj-whitelist margin-right-ten"
                  helperText={t('JobNew.exsalaryto')}
                  variant="standard"
                />
                <FormControl className="select-fixer">
                  <InputLabel htmlFor="salary-interval-native-helper">
                    {t('JobNew.salaryper')}
                  </InputLabel>
                  <Select
                    error={
                    this.state.requiredFieldsError
                      && this.validateSalary() === false
                  }
                    className="formSelect"
                    value={this.state.salaryInterval}
                    onChange={this.handleChange('salaryInterval')}
                    input={<Input id="salary-interval-native-helper" />}
                    variant="standard"
                  >
                    <MenuItem value="hourly">{t('JobNew.hour')}</MenuItem>
                    <MenuItem value="daily">{t('JobNew.day')}</MenuItem>
                    <MenuItem value="monthly">{t('JobNew.month')}</MenuItem>
                  </Select>
                  <FormHelperText>{t('JobNew.exsalaryper')}</FormHelperText>
                </FormControl>
              </div>
              <FormControlLabel
                className="salary-visible"
                control={(
                  <Switch
                    checked={this.state.salaryVisible}
                    onChange={this.handleChange('salaryVisible')}
                    value="active"
                    color="primary"
                  />
              )}
                label={t('JobNew.showsalary')}
              />
            </div>
          </div>
        );
      case 1:
        return (
          <div className="wrapper-fields">
            <div className="required-fields">
              <DialogContentText className="group-title">
                {t('JobNew.optionalfields')}
              </DialogContentText>
              <div className="formGroup">
                <FormControl className="optional-margin">
                  <InputLabel htmlFor="shift-native-helper">
                    {t('JobNew.shift')}
                  </InputLabel>
                  <Select
                    className="formSelect"
                    value={this.state.shift}
                    onChange={this.handleChange('shift')}
                    input={<Input id="shift-native-helper" />}
                    variant="standard"
                  >
                    <MenuItem value="morning">{t('JobNew.morning')}</MenuItem>
                    <MenuItem value="afternoon">{t('JobNew.noon')}</MenuItem>
                    <MenuItem value="evening">{t('JobNew.night')}</MenuItem>
                    <MenuItem value="rolling">
                      {t('JobNew.rollingshift')}
                    </MenuItem>
                  </Select>
                  <FormHelperText>{t('JobNew.shifthelp')}</FormHelperText>
                </FormControl>
                <FormControl className="experience-years optional-margin">
                  <InputLabel htmlFor="experience-native-helper">
                    {t('JobNew.yearsworkexperience')}
                  </InputLabel>
                  <Select
                    className="formSelectGroup special-form-group"
                    value={this.state.experience}
                    onChange={this.handleChange('experience')}
                    input={<Input id="experience-native-helper" />}
                    variant="standard"
                  >
                    <MenuItem value="0">{t('JobNew.notrequired')}</MenuItem>
                    <MenuItem value="1">
                      1
                      {t('JobNew.year')}
                    </MenuItem>
                    <MenuItem value="2">
                      2
                      {t('JobNew.years')}
                    </MenuItem>
                    <MenuItem value="3">
                      3
                      {t('JobNew.years')}
                    </MenuItem>
                    <MenuItem value="4">
                      4
                      {t('JobNew.years')}
                    </MenuItem>
                    <MenuItem value="5">
                      5+
                      {t('JobNew.years')}
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {t('JobNew.inthespecificskill')}
                  </FormHelperText>
                </FormControl>
                <FormControl className="optional-margin">
                  <InputLabel htmlFor="education-native-helper">
                    {t('JobNew.education')}
                  </InputLabel>
                  <Select
                    className="formSelect"
                    value={this.state.minimum_education_level}
                    onChange={this.handleChange('minimum_education_level')}
                    input={<Input id="education-native-helper" />}
                    variant="standard"
                  >
                    <MenuItem value="" disabled />
                    {educationLevelsSorted.map((education) => (
                      <MenuItem key={education.id} value={education.id}>
                        {education.display_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {t('JobNew.educationdescription')}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="optional-fields">
              <div className="lang-group">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid rgba(0,0,0,0.2)',
                    alignItems: 'center',
                    marginTop: '20px',
                  }}
                >
                  <DialogContentText style={{ fontWeight: '700' }}>
                    {t('JobNew.languages')}
                  </DialogContentText>
                  <Button
                    color="primary"
                    onClick={() => this.languageDialog.current.handleClickOpen(
                      null,
                      this.state.languages,
                    )}
                  >
                    {/* {t("JobNew.languageadd")} */}
                    <AddBoxIcon />
                  </Button>
                </div>
                {this.state.languages.map((lang) => (
                  <div className="formGroup" key={lang.id}>
                    <p>
                      {
                      availableLanguages.find((e) => e.id === lang.id)
                        .display_name
                    }
                      {' '}
                      (
                      {lang.knowledge_level
                        ? t(`JobNew.langlevel${lang.knowledge_level}`)
                        : t('JobNew.langlevelbeginner')}
                      )
                    </p>
                    <div>
                      <Button
                        color="primary"
                        onClick={() => this.languageDialog.current.handleClickOpen(lang)}
                      >
                        {/* {t("JobNew.languageedit")} */}
                        <CreateIcon style={{ fontSize: '20px' }} />
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => this.removeLanguage(lang.id)}
                      >
                        {/* {t("JobNew.languageremove")} */}
                        <DeleteIcon
                          style={{ color: 'red', fontSize: '20px' }}
                        />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="wrapper-fields">
            <div className="required-fields">
              <DialogContentText className="group-title">
                {t('JobNew.hiddenpreferences')}
              </DialogContentText>
              <div className="formGroup">
                <FormControl className="select-fixer">
                  <InputLabel htmlFor="gender-native-helper">
                    {t('JobNew.gender')}
                  </InputLabel>
                  <Select
                    className="formSelect"
                    value={this.state.gender}
                    onChange={this.handleChange('gender')}
                    input={<Input id="gender-native-helper" />}
                    variant="standard"
                  >
                    <MenuItem value="any">{t('JobNew.allgender')}</MenuItem>
                    <MenuItem value="male">{t('JobNew.male')}</MenuItem>
                    <MenuItem value="female">{t('JobNew.female')}</MenuItem>
                  </Select>
                  <FormHelperText>{t('JobNew.exallgender')}</FormHelperText>
                </FormControl>
                <TextField
                  error={this.state.ageError}
                  label={t('JobNew.agefrom')}
                  value={this.state.ageMin}
                  placeholder="16"
                  onChange={this.handleChange('ageMin')}
                  margin="normal"
                  className="data-hj-whitelist age-group"
                  helperText={t('JobNew.exagefrom')}
                  variant="standard"
                />
                <TextField
                  error={this.state.ageError}
                  label={t('JobNew.ageto')}
                  value={this.state.ageMax}
                  placeholder="65"
                  onChange={this.handleChange('ageMax')}
                  margin="normal"
                  className="data-hj-whitelist age-group-last"
                  helperText={t('JobNew.exageto')}
                  variant="standard"
                />
                <FormControl className="select-fixer distance-margin">
                  <InputLabel htmlFor="distance-native-helper">
                    {t('JobNew.distance')}
                  </InputLabel>
                  <Select
                    style={{ minWidth: '105px', marginRight: '16px' }}
                    value={this.state.distance}
                    onChange={this.handleChange('distance')}
                    input={<Input id="gender-native-helper" />}
                    variant="standard"
                  >
                    <MenuItem value="2000">{t('JobNew.km2')}</MenuItem>
                    <MenuItem value="5000">{t('JobNew.km5')}</MenuItem>
                    <MenuItem value="10000">{t('JobNew.km10')}</MenuItem>
                    <MenuItem value="null">{t('JobNew.km20p')}</MenuItem>
                  </Select>
                  <FormHelperText>{t('JobNew.exdistance')}</FormHelperText>
                </FormControl>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="wrapper-fields" style={{ display: 'block' }}>
            <h1>{t('JobNew.proheadline')}</h1>
            <FormControlLabel
              className="activatePro"
              control={(
                <Switch
                  checked={false}
                  onChange={this.handlePRO}
                  value="disabled"
                  color="primary"
                />
            )}
              label={t('JobNew.enablePro')}
            />
            <ul>
              <li>{t('JobNew.procv')}</li>
              <li>{t('JobNew.proshortlist')}</li>
              <li>{t('JobNew.prochat')}</li>
              <li>{t('JobNew.prointerview')}</li>
              <li>{t('JobNew.probooking')}</li>
            </ul>
          </div>
        );
      default:
        return 'Unknown step';
    }
  };

  handlePRO = () => {
    const { t } = this.props;
    try {
      crispShowMessage(t('JobNew.upgradeRequest'));
    } catch (e) {
      // continue regardless of error
    }
  };

  validateAge = () => +this.state.ageMin <= +this.state.ageMax;

  validateSalary = () => {
    // check minimum wages, check correct bracket
    const min = +this.state.salaryMin.replace(',', '.');
    const max = this.state.salaryMax
      ? +this.state.salaryMax.replace(',', '.')
      : min;

    if (min === 0 || this.state.salaryInterval === '') {
      return false;
    }
    if (min > max) {
      return false;
    } if (
      this.state.salaryInterval === 'hourly'
      && (min < 3 || min > 20)
    ) {
      return false;
    } if (
      this.state.salaryInterval === 'daily'
      && (min < 20 || min > 100)
    ) {
      return false;
    } return !(this.state.salaryInterval === 'monthly'
      && (min < 100 || min > 5000));
  };

  handleNext = () => {
    const { activeStep } = this.state;

    if (
      activeStep === 0
      && (this.validateSalary() === false
        || this.state.description === ''
        || this.state.skill === '')
    ) {
      this.setState({ requiredFieldsError: true });
      return;
    }

    if (activeStep === 2 && this.validateAge() === false) {
      this.setState({ ageError: true });
      return;
    }

    this.setState({
      activeStep: activeStep + 1,
      requiredFieldsError: false,
    });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  render() {
    const { t } = this.props;
    const steps = this.getSteps();
    const { activeStep } = this.state;
    const activeStepId = !this.state.id ? t('JobNew.publish') : t('JobNew.save');
    return (
      <div>
        <WrappedAlertDialog
          ref={this.alertDialog}
          message={{
            title: t('JobNew.closepositionTitle'),
            text: t('JobNew.closepositionText'),
            confirm: t('JobNew.closepositionBtn'),
            confirmColor: 'secondary',
          }}
          onConfirm={this.handlePositionCloseConfirm}
        />
        <WrappedAlertDialog
          ref={this.validDialog}
          message={{
            title: t('JobNew.nophoneTitle'),
            text: t('JobNew.nophoneText'),
            confirm: t('JobNew.nophoneBtn'),
            confirmColor: 'primary',
          }}
          hideCancel
          onConfirm={this.clearDescription}
        />
        <WrappedLanguageDialog
          ref={this.languageDialog}
          addLanguage={this.addLanguage}
          availableLanguages={availableLanguages}
        />
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          classes={{ paper: 'new-job-form' }}
          aria-labelledby="form-dialog-title"
          TransitionComponent={Transition}
          disableEnforceFocus
        >
          <DialogTitle id="form-dialog-title">
            {!this.state.id ? t('JobNew.create') : t('JobNew.edit')}
            {!this.state.id ? false : (
              <FormControlLabel
                className="activate-job"
                control={(
                  <Switch
                    checked
                    onChange={this.handlePositionCloseClick}
                    value="active"
                    color="secondary"
                  />
                )}
                label={t('JobNew.active')}
              />
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText className="form-title">
              {!this.state.id
                ? t('JobNew.instructionsNew')
                : t('JobNew.instructionsEdit')}
            </DialogContentText>
            <Stepper activeStep={activeStep}>
              {steps.map((label) => {
                const props = {};
                const labelProps = {};

                return (
                  <Step key={label} {...props}>
                    <StepLabel {...labelProps}>
                      {window.innerWidth < 800 ? '' : label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <div>All steps completed - you&quot;re finished</div>
              ) : (
                <div style={{ minHeight: '400px' }}>
                  {this.getStepContent(activeStep)}
                </div>
              )}
            </div>
          </DialogContent>

          <DialogActions>
            <MediaQuery minDeviceWidth={800}>
              <FormControlLabel
                className="renewJob"
                control={(
                  <Switch
                    checked={this.state.auto_consume_credit}
                    onChange={this.handleChange('auto_consume_credit')}
                    value="active"
                    color="primary"
                  />
                )}
                label={t('JobNew.autorenew')}
              />
            </MediaQuery>
            <Button onClick={this.handleClose} color="primary">
              {t('JobNew.cancel')}
            </Button>
            <Button color="inherit" disabled={activeStep === 0} onClick={this.handleBack}>
              {t('JobNew.back')}
            </Button>
            <Button
              onClick={
                activeStep === steps.length - 1
                  ? this.handleSubmit
                  : this.handleNext
              }
              color="primary"
              variant="contained"
              // disabled={
              //   !(
              //     this.state.skill &&
              //     this.state.description &&
              //     this.state.salaryInterval &&
              //     (this.state.salaryMax || this.state.salaryMin)
              //   )
              // }
            >
              {activeStep === steps.length - 1
                ? activeStepId
                : t('JobNew.next')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default JobNew;
