import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ApplicantCard({
  application,
  status,
  reject,
  clicker,
  conversation,
}) {
  const isExpiring = (originalClass, expiryTime) => {
    let elementClass = originalClass;
    if (status !== 1) return elementClass;
    const expiry = new Date(expiryTime);
    const now = new Date();
    const hour = 60 * 60 * 1000;
    if (expiry - now < 2 * hour) {
      elementClass += ' warning';
    }
    return elementClass;
  };
  const relevanceColor = (app) => {
    if (app.is_staffnow) return '#26a2f0';
    let color = '#f44336';
    if (app.relevance_score > 80) color = '#4caf50';
    else if (app.relevance_score > 60) color = '#ffc107';
    return color;
  };

  const { t } = useTranslation();

  const getApplicantName = () => {
    if (status === 1) {
      return application.first_name;
    }

    return `${application.first_name} ${application.last_name}`;
  };

  return (
    <Card className={isExpiring('applicant-card', application.expires_at)}>
      <CardMedia
        onClick={() => clicker(application.application_id, status)}
        style={{ height: 250, cursor: 'pointer' }}
        title="profile pic"
        image={application.photo_url}
      />
      <CardContent onClick={() => clicker(application.application_id, status)}>
        {getApplicantName()}
        <br />
        <br />
        {status === 1 && application.relevance_score !== undefined ? (
          <span
            style={{
              color: relevanceColor(application),
              fontWeight: application.is_staffnow ? 'bold' : 'normal',
            }}
          >
            {application.is_staffnow
              ? 'StaffNow '
              : application.relevance_score + t('ApplicantCard.compatibility')}
          </span>
        ) : false}
        {/* {application.video_url ? <Video /> : false} */}
        {/* {status === 2 && application.phone ? <Call /> : false} */}
        {status === 1 && application.relevance_score !== undefined ? false : (
          <div style={{ height: '28px' }}> </div>
        )}
      </CardContent>
      {status === 1 || status === 2 ? (
        <CardActions>
          {status === 1 ? (
            <Button
              size="small"
              color="primary"
              onClick={() => clicker(application.application_id, status)}
            >
              {t('ApplicantCard.details')}
            </Button>
          ) : (
            <>
              <Link
                to={`/messages/${conversation}`}
                style={{ textDecoration: 'none' }}
              >
                <Button size="small" color="primary">
                  {t('ApplicantCard.contact')}
                </Button>
              </Link>
              <Button
                size="small"
                color="secondary"
                onClick={() => reject(application.id, true)}
              >
                {t('ApplicantCard.reject')}
              </Button>
            </>
          )}
        </CardActions>
      ) : (
        ''
      )}
    </Card>
  );
}
