import { createRef } from 'react';
import { withTranslation, useTranslation, Trans } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import AlertDialog from './AlertDialog';

const WrappedAlertDialog = withTranslation('translation', { withRef: true })(
  AlertDialog,
);

function ReferFriend({ referralCode }) {
  const { t } = useTranslation();
  const instructionsDialog = createRef();
  return (
    <>
      <WrappedAlertDialog
        ref={instructionsDialog}
        message={{
          title: t('ReferFriend.alertTitle'),
          text: t('ReferFriend.alertText', {
            refcode: referralCode,
          }),
          confirm: t('ReferFriend.alertBtn'),
          confirmColor: 'primary',
        }}
        hideCancel
        onConfirm={() => false}
      />
      <div className="refer_friend">
        <Card
          raised
          style={{
            maxWidth: '900px',
            width: '100%',
            fontSize: '20px',
            margin: '0 auto',
            paddingBottom: '24px',
            marginBottom: '32px',
            textAlign: 'center',
          }}
        >
          <CardContent
            style={{
              paddingTop: '30px',
              paddingBottom: 0,
              color: '#555555',
              fontSize: '20px',
            }}
          >
            <div className="refer_friend_title">
              <img src="/gift-icon.svg" alt="gift" className="gift-icon" />
              <div
                style={{
                  margin: '14px 0',
                }}
              >
                {t('ReferFriend.title')}
              </div>
            </div>
            <div
              style={{
                margin: '14px auto',
                fontSize: '15px',
                color: '#9F9F9F',
                textAlign: 'center',
              }}
            >
              <Trans i18nKey="ReferFriend.text" />
            </div>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              className="pricing-button"
              onClick={() => instructionsDialog.current.handleClickOpen()}
              // style={{ marginTop: "32px" }}
            >
              {t('ReferFriend.instructions')}
            </Button>
          </CardActions>
        </Card>
      </div>
    </>
  );
}

export default ReferFriend;
