import { Component } from 'react';
import GoogleMapLoader from 'react-google-maps-loader';
import GooglePlacesSuggest from 'react-google-places-suggest';
import debounce from 'lodash/debounce';
import Input from '@mui/material/Input';
import { withTranslation } from 'react-i18next';
import { showCrispChatBox, hideCrispChatBox } from '../Lib/crispUtils';

const MY_API_KEY = process.env.REACT_APP_GOOGLE_GEOSERVICES_KEY;
const debouncingTimeInMs = 500;

class GoogleSuggest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      value: '',
      found: false,
    };
    this.searchPlaceAfterTimeout = debounce(this.searchCurrentValue, debouncingTimeInMs);
  }

  componentDidMount() {
    if (window.innerWidth < 800) {
      hideCrispChatBox();
    }
  }

  componentWillUnmount() {
    showCrispChatBox();
  }

  searchCurrentValue = () => {
    this.setState((prevState) => ({ search: prevState.value }));
  };

  handleInputChange = (e) => {
    this.setState({ value: e.target.value });
    this.searchPlaceAfterTimeout();
  };

  handleEnter = (e) => {
    if (this.state.found) {
      this.props.placeSelected(e);
    }
  };

  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    // Handle google maps api unloading
    if (!window.gmap) {
      window.gmap = window.google.maps;
    }
    this.setState({
      search: '',
      value: originalPrediction.description,
      found: true,
    });
    try {
      const holder = new window.gmap.places.PlacesService(
        window.document.getElementById('xaramida'),
      );

      const returnedFields = [
        'formatted_address',
        'formatted_phone_number',
        'geometry',
        'place_id',
        'price_level',
        'name',
        'photos',
        'rating',
        'types',
        'user_ratings_total',
      ];

      holder.getDetails(
        {
          placeId: originalPrediction.place_id,
          fields: returnedFields,
        },
        (place) => {
          let photoURLs = [];
          if (place.photos) {
            photoURLs = place.photos.map(
              (p) => `${p.getUrl({ maxWidth: 1000 })}&key=${MY_API_KEY}`,
            );
          }
          const isCompany = place.types.includes('establishment')
            || place.types.includes('point_of_interest');
          this.props.placeSelected(false, {
            name: isCompany && place.name ? place.name : '',
            phone: place.formatted_phone_number
              ? place.formatted_phone_number.replace(/\s/g, '')
              : '',
            photos: photoURLs,
            address: place.formatted_address ? place.formatted_address : '',
            selectedPhoto: '',
            location: {
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng(),
            },
            google_place_id: place.place_id ? place.place_id : '',
            isCompany,
            google_place_info: isCompany
              ? {
                price_level: place.price_level ? place.price_level : null,
                rating: place.rating ? place.rating : null,
                user_ratings_total: place.user_ratings_total
                  ? place.user_ratings_total
                  : null,
                types: place.types ? place.types : null,
              }
              : null,
          });
        },
      );
    } catch (e) {
      console.log(e);
      console.log('google places there was an error');
      this.props.placeSelected(false, {
        name: '',
        phone: '',
        photos: [],
        address: '',
        selectedPhoto: '',
        location: {
          latitude: 0,
          longitude: 0,
        },
      });
    }
  };

  render() {
    const { search, value } = this.state;
    const { onboardingDone } = this.props;
    const isInterview = this.props.isInterview || false;
    const { t } = this.props;

    function getPrediction(predictionAttr) {
      return (
        <div className="custom-wrapper">
          {predictionAttr
            ? predictionAttr.description
            : t('CompanyFind.noresults')}
        </div>
      );
    }

    return (
      <>
        {onboardingDone ? (
          <h2>
            {isInterview
              ? t('CompanyFind.interviewaddress')
              : t('CompanyFind.addcompany')}
          </h2>
        ) : (
          <div>
            <h2>{t('CompanyFind.welcome')}</h2>
            <h3>{t('CompanyFind.startbyaddingcompany')}</h3>
          </div>
        )}
        <GoogleMapLoader
          params={{
            key: MY_API_KEY,
            libraries: 'places,geocode',
          }}
          render={(googleMaps) => googleMaps && (
            <GooglePlacesSuggest
              googleMaps={googleMaps}
              autocompletionRequest={{
                input: search,
                // types: ["establishment", "street_address"],
                componentRestrictions: { country: [this.props.country] }, // , ["gr", "it"] "cy"] }

                // Optional options
                // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
              }}
              // Optional props
              onSelectSuggest={this.handleSelectSuggest}
              textNoResults={t('CompanyFind.noresults')} // null or "" if you want to disable the no results item
              customRender={(pr) => getPrediction(pr)}
            >
              <Input
                type="text"
                style={isInterview ? { width: '400px' } : {}}
                value={value}
                autoFocus
                className="company-search data-hj-whitelist"
                placeholder={t('CompanyFind.nameoraddress')}
                onChange={this.handleInputChange}
                onKeyPress={(e) => (e.charCode === 13 ? this.handleEnter(e) : false)}
              />
              <div id="xaramida" />
            </GooglePlacesSuggest>
          )}
        />
        {isInterview ? false : (
          <div id="onboard_instructions">{t('CompanyFind.instructions')}</div>
        )}
      </>
    );
  }
}

export default withTranslation()(GoogleSuggest);
