import { Component } from 'react';
import Carousel from 'nuka-carousel';
import { withTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { trackEvent } from '../Lib/analytics';
import logo from '../logo.svg';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryCode: null,
    };
  }

  componentDidMount() {
    this.fetchCountry().then((cc) => {
      if (cc && cc.country_code) {
        if (cc.country_code !== 'GR' && cc.country_code !== 'IT') {
          let country = null;
          if (this.props.i18n.language.includes('el')) {
            country = 'GR';
          } else if (this.props.i18n.language.includes('it')) {
            country = 'IT';
          }
          cc.country_code = country;
        }
      }
      const countryCode = cc.country_code ? cc.country_code : cc;
      this.setState({
        countryCode,
      });
      this.props.setCountry(countryCode);
      trackEvent('User_Action_HireStaff', { guessCountry: countryCode });
    });
  }

  fetchCountry = () => {
    if (this.state.countryCode) {
      return new Promise((resolve) => {
        resolve(this.countryCode);
      });
    }
    try {
      return fetch(
        'https://api.ipdata.co/?api-key=9eb1c6658c40f947d084e3e34bf9700e6ec23814fc96c494e8a0adf7',
      ).then((data) => data.json());
    } catch (e) {
      return new Promise((resolve) => {
        let country = null;
        if (this.props.i18n.language.includes('el')) {
          country = 'GR';
        } else if (this.props.i18n.language.includes('it')) {
          country = 'IT';
        }
        resolve(country);
      });
    }
  };

  handleChange = (e) => {
    this.props.setCountry(e.target.value);
    this.setState({ countryCode: e.target.value });
  };

  render() {
    const { t } = this.props;
    let { language } = this.props.i18n;
    if (language && language.includes('-')) {
      [language] = language.split('-');
    }
    return (
      <div id="sign_container" style={{ textAlign: 'center', margin: '24px 0' }}>
        <div className="sign-up-text">
          <img src={logo} style={{ width: '120px' }} alt="Logo" />
          <h1>{t('Signup.heading')}</h1>
          <p style={{ fontSize: '12px' }}>
            {t('Signup.acceptterms1')}
            {' '}
            <a
              href={`https://www.myjobnow.com/terms-of-service-${language}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#26a2f0',
                textDecoration: 'none',
                fontWeight: 'bold',
              }}
            >
              {t('Signup.acceptterms2')}
            </a>
            {' '}
            {t('Signup.acceptterms3')}
            {' '}
            <a
              href={`https://www.myjobnow.com/privacy-policy-${language}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#26a2f0',
                textDecoration: 'none',
                fontWeight: 'bold',
              }}
            >
              {t('Signup.acceptterms4')}
            </a>
            <span>
              <br />
              <strong>{t('Signup.adblock')}</strong>
            </span>
          </p>
          {this.state.countryCode ? (
            <div>
              <FormControl>
                <InputLabel htmlFor="age-simple">
                  {t('Signup.pickcountry')}
                </InputLabel>
                <Select
                  value={this.state.countryCode}
                  onChange={this.handleChange}
                  style={{ minWidth: '150px' }}
                  variant="standard"
                >
                  <MenuItem value="GR">
                    <span role="img" aria-label="greek flag">
                      🇬🇷
                    </span>
                    {' '}
                    {t('Signup.greece')}
                  </MenuItem>
                  <MenuItem value="IT">
                    <span role="img" aria-label="italian flag">
                      🇮🇹
                    </span>
                    {t('Signup.italy')}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          ) : null}
        </div>
        <div className="logo-sign">
          <div style={{ width: 'calc(50% - 24px)' }} />
          <div style={{ width: 'calc(50% - 24px)' }}>
            <div className="up-sign-text">
              <h1>{t('Signup.heading2')}</h1>
              <p>{t('Signup.description')}</p>
              <Carousel
                autoplay
                initialSlideHeight={175}
                width="200px"
                wrapAround
                withoutControls
                transitionMode="fade"
                style={{ margin: '12px auto 0' }}
              >
                <div>
                  <img
                    src="/emp_login_onboarding_post@3x.png"
                    alt="applications"
                    height="175"
                  />
                  <h3>{t('Signup.carousel1')}</h3>
                </div>
                <div>
                  <img
                    src="/emp_login_onboarding_applications@3x.png"
                    alt="applications"
                    height="175"
                  />
                  <h3>{t('Signup.carousel2')}</h3>
                </div>
                <div>
                  <img
                    src="/emp_login_onboarding_check_cvs@3x.png"
                    alt="applications"
                    height="175"
                  />
                  <h3>{t('Signup.carousel3')}</h3>
                </div>
                <div>
                  <img
                    src="/emp_login_onboarding_interview@3x.png"
                    alt="applications"
                    height="175"
                  />
                  <h3>{t('Signup.carousel4')}</h3>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Signup);
