import { Component, forwardRef } from 'react';
import { Trans } from 'react-i18next';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import MenuItem from '@mui/material/MenuItem';
import { apiPost } from '../Lib/requests';
import { hideCrispChatBox, showCrispChatBox } from '../Lib/crispUtils';

const Transition = forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

class ReportUserDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      conversation_id: null,
      reason: '',
      description: '',
      has_interview: false,
    };
  }

  reportReasons = () => [
    { id: 1, text: this.props.t('ReportUserDialog.noshow'), sort: 0 },
    { id: 4, text: this.props.t('ReportUserDialog.noreply'), sort: 1 },
    { id: 2, text: this.props.t('ReportUserDialog.fakeprofile'), sort: 2 },
    { id: 3, text: this.props.t('ReportUserDialog.badbehavior'), sort: 3 },
    { id: 0, text: this.props.t('ReportUserDialog.other'), sort: 4 },
  ];

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleClickOpen = (
    conversationId,
    hasInterview = false,
    isFuture = false,
  ) => {
    this.setState({
      open: true,
      conversation_id: conversationId,
      has_interview: hasInterview,
      is_future: isFuture,
    });
    if (window.innerWidth < 800) {
      hideCrispChatBox();
    }
  };

  handleChange = (name) => (e) => {
    if (name === 'reason') {
      this.setState({ reason: e.target.value, description: '' });
    } else {
      this.setState({ description: e.target.value });
    }
  };

  handleClose = () => {
    this.setState({
      open: false,
      conversation_id: null,
      reason: '',
      description: '',
      has_interview: false,
      is_future: false,
    });

    showCrispChatBox();
  };

  handleSubmit = () => {
    const reasonId = this.state.reason;
    apiPost('/user/report', {
      conversation_id: this.state.conversation_id,
      message:
        `(${
          this.reportReasons()[reasonId].id
        }) ${
          this.reportReasons()[reasonId].text
        } ${
          this.state.description}`,
    }).then(() => {
      this.handleClose();
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          TransitionComponent={Transition}
        >
          <DialogTitle id="form-dialog-title">
            {t('ReportUserDialog.reportuserTitle')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('ReportUserDialog.reportuserText')}
            </DialogContentText>
            <FormControl>
              <InputLabel htmlFor="skill-native-helper">
                {t('ReportUserDialog.reason')}
              </InputLabel>
              <Select
                // native
                autoWidth
                value={this.state.reason}
                onChange={this.handleChange('reason')}
                input={<Input id="skill-native-helper" />}
                style={{ width: '280px' }}
                variant="standard"
              >
                <MenuItem value="" disabled />
                {this.reportReasons().map((reason) => (
                  <MenuItem key={reason.id} value={reason.sort}>
                    {reason.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="multiline-flexible"
              label={t('ReportUserDialog.description')}
              multiline
              maxRows="4"
              minRows="2"
              variant="standard"
              value={this.state.description}
              placeholder={t('ReportUserDialog.placeholder')}
              onChange={this.handleChange('description')}
              fullWidth
              margin="normal"
              className="data-hj-whitelist"
              style={
                this.state.reason === 4
                  ? { visibility: 'visible' }
                  : { visibility: 'hidden' }
              }
            />
            <p
              style={
                this.state.reason === 0
                && (this.state.has_interview === false
                  || this.state.is_future === true)
                  ? { visibility: 'visible', color: '#f44336' }
                  : { visibility: 'hidden', color: '#f44336' }
              }
            >
              {this.state.is_future === true ? (
                <Trans i18nKey="ReportUserDialog.future" />
              ) : (
                <Trans i18nKey="ReportUserDialog.sorry" />
              )}
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {t('ReportUserDialog.cancel')}
            </Button>
            <Button
              onClick={this.handleSubmit}
              color="primary"
              variant="contained"
              disabled={
                this.state.reason === ''
                || (this.state.reason === 0
                  && (this.state.has_interview === false
                    || this.state.is_future === true))
              }
            >
              {t('ReportUserDialog.report')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ReportUserDialog;
