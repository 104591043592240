import { Component, forwardRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { Trans } from 'react-i18next';
import { trackEvent } from '../Lib/analytics';
import { crispShowMessage, hideCrispChatBox, showCrispChatBox } from '../Lib/crispUtils';

const Transition = forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

const onTrialTitle = (title) => (
  <>
    {title}
    {' '}
    <span role="img" aria-label="smiling">
      🙂
    </span>
    {' '}
    <br />
    <br />
  </>
);

const getTranslation = (translationKey) => <Trans i18nKey={translationKey} />;

const trialOverTitle = (title) => (
  <>
    {title}
    {' '}
    <span role="img" aria-label="disappointed">
      😞
    </span>
  </>
);
class CreditsNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      status: 'none',
      jobCredits: null,
      jobId: null,
    };
  }

  handleClose = () => {
    showCrispChatBox();
    this.setState({
      open: false,
      status: 'none',
      jobCredits: null,
      jobId: null,
    });
  };

  supportRequest = () => {
    try {
      crispShowMessage(this.messages()[this.state.status].support);
    } catch (e) {
      // continue regardless of error
    }
  };

  gotoPayments = (productType = 'job') => {
    let path = '/payments';
    if (productType === 'shortlist') {
      path += `/shortlist/${this.state.jobId}`;
    }
    this.props.history.push(path);
    this.handleClose();
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  openCreditsInfo = (
    status = 'none',
    jobCredits = false,
    subscriptionExpiresAt = null,
    jobId = null,
  ) => {
    if (this.props.location.pathname.indexOf('/payments') === 0) {
      return false;
    }
    if (status && status !== 'none') {
      if (window.innerWidth < 800) {
        hideCrispChatBox();
      }
      let statusMap = status;
      if (
        status === 'has_credits'
        && jobCredits !== false
        && subscriptionExpiresAt === null
      ) {
        statusMap = 'has_credits_new';
      }
      this.setState({
        open: true, status: statusMap, jobCredits, jobId,
      });
    }
    trackEvent('EMP_Screen_Paywall', {
      user_id: this.props.user.id,
      status: this.state.status,
    });

    return true;
  };

  messages = () => ({
    on_trial: {
      title: () => onTrialTitle(this.props.t('CreditsNotice.trialTitle')),
      text: () => getTranslation('CreditsNotice.trialText'),
      support: this.props.t('CreditsNotice.trialMsg'),
    },
    trial_over: {
      title: () => trialOverTitle(this.props.t('CreditsNotice.trialoverTitle')),
      text: () => getTranslation('CreditsNotice.trialoverText'),
      support: this.props.t('CreditsNotice.trialoverMsg'),
    },
    has_credits: {
      title: () => this.props.t('CreditsNotice.hassubTitle', {
        xxx: new Date(
          this.props.user.subscription_expires_at,
        ).toLocaleDateString(this.props.i18n.language, {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        }),
      }),
      text: () => getTranslation('CreditsNotice.hassubText'),
      support: this.props.t('CreditsNotice.hassubMsg'),
    },
    has_credits_new: {
      title: () => this.props.t('CreditsNotice.hascreditsTitle', {
        count: this.state.jobCredits,
      }),
      text: () => getTranslation('CreditsNotice.hascreditsText'),
      support: this.props.t('CreditsNotice.hascreditsMsg'),
    },
    no_credits: {
      title: () => this.props.t('CreditsNotice.nocreditsTitle'),
      text: () => getTranslation('CreditsNotice.nocreditsText'),
      support: this.props.t('CreditsNotice.nocreditsMsg'),
    },
    banned: {
      title: () => this.props.t('CreditsNotice.bannedTitle'),
      text: () => getTranslation('CreditsNotice.bannedText'),
      support: this.props.t('CreditsNotice.bannedMsg'),
    },
    multiuser: {
      title: () => this.props.t('CreditsNotice.multiuserTitle'),
      text: () => getTranslation('CreditsNotice.multiuserText'),
      support: this.props.t('CreditsNotice.multiuserMsg'),
    },
    no_shortlists: {
      title: () => this.props.t('CreditsNotice.noshortlistsTitle'),
      text: () => getTranslation('CreditsNotice.noshortlistsText'),
      support: this.props.t('CreditsNotice.noshortlistsMsg'),
    },
    shortlists: {
      title: () => this.props.t('CreditsNotice.shortlistsTitle'),
      text: () => getTranslation('CreditsNotice.shortlistsText'),
      support: this.props.t('CreditsNotice.shortlistsMsg'),
    },
    none: {
      title: () => '',
      text: () => '',
      support: '',
    },
  });

  render() {
    const message = this.messages()[this.state.status];
    const { t } = this.props;
    const { title } = message;

    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        disableEnforceFocus
      >
        <DialogTitle id="credits-dialog-title">{title()}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message.text()}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            color="primary"
            onClick={() => this.supportRequest()}
          >
            {t('CreditsNotice.contact')}
          </Button>
          {this.state.status.indexOf('shortlists') !== -1 ? (
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={() => this.gotoPayments('shortlist')}
            >
              {t('CreditsNotice.noshortlistsBTN')}
            </Button>
          ) : (
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={() => this.gotoPayments()}
            >
              {t('CreditsNotice.purchase')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default CreditsNotice;
