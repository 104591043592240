import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';

const isDefaultClass = (isDefault, originalClass, defaultClass) => {
  let genClass = originalClass;
  if (isDefault) {
    genClass += defaultClass;
  }
  return genClass;
};

const hasJobs = (numOpenJobs, originalClass) => {
  let genClass = originalClass;
  if (numOpenJobs === 0) {
    genClass += ' nojobs';
  }
  return genClass;
};

const addBadge = (elem, count) => (count > 0 ? (
  <Badge badgeContent={count} color="secondary">
    {elem}
  </Badge>
) : (
  elem
));

function CompanyBubble({
  company, unread, isDefault, click, numOpenJobs,
}) {
  return (
    <div
      key={company.id}
      className={isDefaultClass(
        isDefault,
        'company-switcher-bubble',
        'arrow-box',
      )}
      onClick={() => click(company.id)}
      role="button"
      tabIndex={0}
      aria-hidden
    >
      <div
        className={isDefaultClass(
          isDefault,
          'company-switcher-photo-holder',
          'company-switcher-default',
        )}
      >
        {addBadge(
          <Avatar
            src={company.photo_url}
            className={hasJobs(numOpenJobs, 'company-switcher-photo')}
          />,
          unread,
        )}
      </div>
      <div
        className={isDefaultClass(
          isDefault,
          'company-switcher-name',
          'company-switcher-name-white',
        )}
      >
        {company.name}
      </div>
    </div>
  );
}

export default CompanyBubble;
