import { Component } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Input from '@mui/material/Input';
import { withTranslation } from 'react-i18next';

import { apiPost, apiPut } from '../Lib/requests';
import { trackEvent, trackFBpixel } from '../Lib/analytics';
import CompanyFind from '../Components/CompanyFind';
import CompanyPreview from '../Components/CompanyPreview';
import PhotoUpload from '../Components/PhotoUpload';
import { crispShowMessage } from '../Lib/crispUtils';

function getSteps(t) {
  return t('NewCompany.steps').split('#');
}

function getStepContent(
  step,
  company,
  onboardingDone,
  changeHandler,
  t,
  userCountry,
) {
  const gridSize = window.innerWidth < 800 ? { h: 100, c: 2 } : { h: 160, c: 3 };
  switch (step) {
    case 0:
      return !company.id ? (
        <CompanyFind
          placeSelected={changeHandler}
          onboardingDone={onboardingDone}
          country={userCountry}
        />
      ) : (
        <>
          <h2>{t('NewCompany.changeAddress')}</h2>
          <Input
            type="text"
            autoFocus
            className="company-search data-hj-whitelist"
            value={company.address}
            onChange={(e) => changeHandler(e, 'address')}
            onKeyPress={(e) => (e.charCode === 13 ? changeHandler(e, 'address') : false)}
          />
        </>
      );
    case 1:
      return (
        <>
          <h2>
            {!company.id ? t('NewCompany.addName') : t('NewCompany.changeName')}
          </h2>
          <Input
            type="text"
            autoFocus
            className="company-search data-hj-whitelist"
            value={company.name}
            onChange={(e) => changeHandler(e, 'name')}
            onKeyPress={(e) => (e.charCode === 13 ? changeHandler(e, 'name') : false)}
          />
        </>
      );
    case 2:
      return (
        <>
          <h2>
            {!company.id
              ? t('NewCompany.addPhone')
              : t('NewCompany.changePhone')}
          </h2>
          <Input
            type="text"
            autoFocus
            className="company-search data-hj-whitelist"
            value={company.phone}
            onChange={(e) => changeHandler(e, 'phone')}
            onKeyPress={(e) => (e.charCode === 13 ? changeHandler(e, 'phone') : false)}
          />
        </>
      );
    case 3:
      return (
        <>
          <h2>{t('NewCompany.selectPhoto')}</h2>
          <ImageList cellheight={gridSize.h} cols={gridSize.c}>
            <ImageListItem key="upload" cols={1}>
              <PhotoUpload upload={(e) => changeHandler(e, 'uploadPhoto')} />
            </ImageListItem>
            <ImageListItem key="placeholder" cols={1}>
              <img
                src={
                `/company_default_${
                  Math.floor(Math.random() * 3) + 1
                }@3x.png`
              }
                style={{ cursor: 'pointer' }}
                onClick={(e) => changeHandler(e, 'uploadPhoto')}
                aria-hidden
                alt="company"
              />
            </ImageListItem>
            {company.photos.map((photo) => (
              <ImageListItem key={photo} cols={1}>
                <img
                  src={photo}
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => changeHandler(e, 'selectedPhoto')}
                  alt="company"
                  aria-hidden
                />
              </ImageListItem>
            ))}
          </ImageList>
        </>
      );
    case 4:
      return <CompanyPreview company={company} />;
    default:
      return 'Ooops';
  }
}

class NewCompany extends Component {
  constructor(props) {
    super(props);
    let editCompany = null;
    if (props.edit) {
      if (this.props.match.params.id) {
        editCompany = props.companies.find((c) => c.id.toString() === props.match.params.id);
      } else {
        editCompany = props.company;
      }
    }
    const company = editCompany
      ? {
        id: editCompany.id,
        name: editCompany.name,
        phone: editCompany.phone,
        selectedPhoto: editCompany.photo_url,
        photoUpload: '',
        photoBinary: '',
        photo_url: editCompany.photo_url,
        photos: [editCompany.photo_url],
        address: editCompany.location.address_text,
        location: {
          latitude: editCompany.location.latitude,
          longitude: editCompany.location.longitude,
        },
      }
      : {
        id: false,
        name: '',
        phone: '',
        photos: [],
        address: '',
        selectedPhoto: '',
        photoBinary: '',
        photo_url: '',
        location: {
          latitude: 0,
          longitude: 0,
        },
        google_place_id: '',
        google_place_info: null,
        isCompany: false,
      };

    this.state = {
      activeStep: 0,
      skipped: new Set(),
      foundPlace: !!editCompany,
      placeSubmitted: false,
      company,
    };
  }

  componentDidMount() {
    if (this.props.user.onboarding_done === false) {
      trackEvent('EMP_Screen_Onboarding_Start', {
        user_id: this.props.user.id,
      });
      trackFBpixel('Lead');
    } else {
      trackEvent('EMP_Action_AddCompany', {
        user_id: this.props.user.id,
      });
    }
  }

  supportRequest = () => {
    try {
      crispShowMessage(this.props.t('NewCompany.help'));
    } catch (e) {
      // continue regardless of error
    }
  };

  handleNext = () => {
    const { activeStep, company } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: activeStep + 1,
      skipped,
    });

    let analyticsEvent;
    let companyInput = null;
    switch (activeStep) {
      case 0:
        analyticsEvent = 'EMP_Screen_CompanyName';
        companyInput = company.name;
        break;
      case 1:
        analyticsEvent = 'EMP_Screen_CompanyTelephone';
        companyInput = company.phone;
        break;
      case 2:
        analyticsEvent = 'EMP_Screen_CompanyPhoto';
        companyInput = company.selectedPhoto;
        break;
      case 3:
        analyticsEvent = 'EMP_Screen_CompanyCheck';
        break;
      default:
        analyticsEvent = null;
    }

    if (analyticsEvent) {
      trackEvent(analyticsEvent, {
        user_id: this.props.user.id,
        input: companyInput,
      });
    }
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  handleChange = (e, field) => {
    if (e.charCode === 13) {
      this.handleNext();
    }
    const prevCompany = { ...this.state.company };
    if (e) {
      if (field === 'uploadPhoto') {
        const upload = e.target.src;
        this.setState({
          company: {
            ...prevCompany,
            selectedPhoto: upload,
            photoBinary: fetch(upload).then((r) => r.blob()),
          },
        });
        this.handleNext();
        trackEvent('EMP_Action_UploadPhoto', {
          user_id: this.props.user.id,
        });
      } else if (field === 'selectedPhoto') {
        this.setState({
          company: {
            ...prevCompany,
            [field]: e.target.src,
            photoBinary: fetch(
              `https://dev-proxy.myjobnow.com/${e.target.src}`,
            ).then((r) => r.blob()),
          },
        });
        this.handleNext();
        trackEvent('EMP_Action_PickCompanyGooglePhoto', {
          user_id: this.props.user.id,
        });
      } else {
        this.setState({
          company: { ...prevCompany, [field]: e.target.value },
        });
      }
    } else {
      this.setState({
        company: { ...prevCompany, ...field },
        foundPlace: true,
      });
    }
  };

  handleConfirm = () => {
    this.setState({ placeSubmitted: true });
    if (this.state.company.photoBinary) {
      this.state.company.photoBinary
        .then((photo) => {
          this.handleSave(photo);
        })
        .catch(() => {
          this.handleSave();
        });
    } else if (this.state.company.photoUpload) {
      this.handleSave(this.state.company.photoUpload);
    } else {
      this.handleSave();
    }
  };

  handleSave = (photo = null) => {
    const formData = new FormData();
    formData.append('name', this.state.company.name);
    formData.append('employer_role', 'HR');
    formData.append('phone', this.state.company.phone);
    formData.append(
      'location',
      JSON.stringify({
        address_text: this.state.company.address,
        latitude: this.state.company.location.latitude,
        longitude: this.state.company.location.longitude,
      }),
    );

    if (this.state.company.isCompany) {
      formData.append('google_place_id', this.state.company.google_place_id);
      formData.append(
        'google_place_info',
        JSON.stringify(this.state.company.google_place_info),
      );
    }
    if (photo) {
      formData.append('photo', photo);
    }
    if (this.state.company.id) {
      formData.append('id', this.state.company.id);
    }
    apiPost('/company', formData)
      .then((res) => {
        this.props.refreshUser(true);
        this.handleNext();
        trackEvent('EMP_Action_VerifyCompany', {
          user_id: this.props.user.id,
        });
        trackEvent('EMP_Screen_OnboardingFinished', {
          user_id: this.props.user.id,
        });
        trackFBpixel('CompleteRegistration');

        if (!this.state.company.id) {
          apiPut('/company/set_default', {
            id: res.data.id,
          }).then(() => {
            this.props.refreshUser();
          });
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 402) {
          this.props.showCredits();
        }
      });
  };

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  render() {
    const { t } = this.props;
    const steps = getSteps(t);
    const { activeStep } = this.state;
    return (
      <div id="company_find">
        <Card className="add-company-card">
          <Stepper activeStep={activeStep} id="stepper" style={{ padding: '16px' }}>
            {steps.map((label, index) => {
              const props = {};
              const labelProps = {};
              if (this.isStepSkipped(index)) {
                props.completed = false;
              }
              return (
                <Step key={label} {...props}>
                  <StepLabel {...labelProps}>
                    {window.innerWidth < 800 ? '' : label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <h2>
                  {!this.state.company.id
                    ? t('NewCompany.congratulations')
                    : t('NewCompany.changesSaved')}
                </h2>
                {/* <Button onClick={this.handleReset}>Reset</Button> */}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.props.history.push('/jobs', {
                    createJob: !this.state.company.id,
                  })}
                >
                  {!this.state.company.id
                    ? t('NewCompany.openFirstjob')
                    : t('NewCompany.returnTojobs')}
                </Button>
              </div>
            ) : (
              <div>
                <div className="step-holder">
                  {getStepContent(
                    activeStep,
                    this.state.company,
                    this.props.user.onboarding_done,
                    this.handleChange,
                    t,
                    this.props.user.country_code,
                  )}
                </div>
                {this.state.activeStep < 3 ? (
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '64px',
                      marginLeft: '16px',
                    }}
                  >
                    <button
                      onClick={() => this.supportRequest()}
                      style={{
                        fontSize: '16px',
                        color: '#26a2f0',
                        textDecoration: 'none',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        background: 'none',
                        border: 0,
                        padding: '0',
                      }}
                      type="button"
                      tabIndex={0}
                    >
                      {t('NewCompany.cantFindCompany')}
                    </button>
                  </div>
                ) : (
                  ''
                )}
                <div style={{ position: 'absolute', bottom: '16px', marginLeft: '10px' }}>
                  <Button disabled={activeStep === 0} onClick={this.handleBack}>
                    {t('NewCompany.previous')}
                  </Button>
                  {this.state.activeStep !== 3 || this.state.company.id ? (
                    <Button
                      id={activeStep === steps.length - 1
                        ? 'confirm-button'
                        : 'next-button'}
                      variant="contained"
                      color="primary"
                      disabled={
                        this.state.foundPlace === false
                        || this.state.placeSubmitted === true
                        || (!this.state.company.name
                          && this.state.activeStep === 1)
                        || (!this.state.company.phone
                          && this.state.activeStep === 2)
                      }
                      onClick={
                        activeStep === steps.length - 1
                          ? this.handleConfirm
                          : this.handleNext
                      }
                    >
                      {activeStep === steps.length - 1
                        ? t('NewCompany.confirm')
                        : t('NewCompany.nextStep')}
                    </Button>
                  ) : (
                    false
                  )}
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
    );
  }
}

export default withTranslation()(NewCompany);
