import { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { apiGet } from '../Lib/requests';
import { trackEvent, trackFBpixel } from '../Lib/analytics';
import './Jobs.css';
import AlertDialog from '../Components/AlertDialog';
import ApplicationsHolder from '../Components/ApplicationsHolder';
import ClosedJobs from '../Components/ClosedJobs';
import JobNew from '../Components/JobNew';
import MJNSnackbar from '../Components/Snackbar';
import CompanySwitcher from '../Components/CompanySwitcher';

const WrappedAlertDialog = withTranslation('translation', { withRef: true })(
  AlertDialog,
);

const WrappedJobNew = withTranslation('translation', { withRef: true })(JobNew);

class Jobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      showSnackbar: false,
      snackbarMessage: '',
      jobs: [],
      closedJobs: [],
    };
    this.firstJobDialog = createRef();
    this.newJobButton = createRef();
    this.handleEditJob = this.handleEditJob.bind(this);
    this.closeSnackBar = this.resetSnackBar.bind(this);
  }

  componentDidMount() {
    this.props.refreshUser(false, true);
    this.refreshJobs(true);
    if (this.props.location.state) {
      if (this.props.location.state.createJob) {
        this.handleClickOpen();
      } else if (this.props.location.state.subscriptionStarted) {
        this.props.history.replace('/jobs', { subscriptionStarted: false });
        const storedJob = JSON.parse(localStorage.getItem('jobnocredits'));
        if (storedJob) {
          this.handleClickOpen(storedJob);
          localStorage.removeItem('jobnocredits');
        }
        this.setState({
          showSnackbar: true,
          snackbarMessage: this.props.t('Jobs.purchasesuccess'),
          snackbarColor: 'green',
        });
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    let companyChanged = false;
    if (
      this.props.user
      && this.props.user.default_company
      && this.props.user.default_company.id
      && nextProps.user
      && nextProps.user.default_company
    ) {
      companyChanged = nextProps.user.default_company.id
        !== this.props.user.default_company.id;
    }
    if (nextProps.unread !== this.props.unread || companyChanged) {
      this.refreshJobs();
    }
    return true;
  }

  resetSnackBar = () => {
    this.setState({ showSnackbar: false, snackbarMessage: '' });
  };

  handleClickOpen = (storedJob = false) => {
    this.newJobButton.current.handleClickOpen(false, storedJob);
  };

  handleNewJob = (msg = null) => {
    let message = msg;
    if (this.state.jobs.length === 0 && this.state.closedJobs.length === 0) {
      trackFBpixel('StartTrial');
      this.firstJobDialog.current.handleClickOpen();
    }

    if (message === null) {
      message = this.props.t('Jobs.success');
    }

    this.refreshJobs();
    this.setState({
      showSnackbar: true,
      snackbarMessage: message || 'Success',
      snackbarColor: 'green',
    });
    this.props.refreshUser();
  };

  showSnackBar = (msg = null) => {
    this.setState({
      showSnackbar: true,
      snackbarMessage: msg || 'Success',
      snackbarColor: 'green',
    });
  };

  handleEditJob = (jobIndex) => {
    this.newJobButton.current.handleClickOpen(this.state.jobs[jobIndex]);
  };

  refreshJobs = (firstLoad = false) => {
    this.props.loader.show();
    apiGet('/user/posted_jobs', {}, 'v2').then((res) => {
      const sortedJobs = res.data.open.sort((a, b) => b.id - a.id);
      const closedJobs = res.data.closed.sort((a, b) => b.id - a.id);
      this.setState({ jobs: sortedJobs, closedJobs });
      // this.resetState();
      this.props.loader.hide();

      if (firstLoad) {
        this.setState({ loaded: true });
        const eventPayload = sortedJobs.reduce(
          (acc, job) => {
            if (job.open === false) {
              acc.closed += 1;
            } else {
              acc.open += 1;
              acc.with_pending += job.applications_info.num_pending;
              acc.with_shortlisted += job.applications_info.num_shortlisted;
            }
            return acc;
          },
          {
            user_id: this.props.user.id,
            open: 0,
            closed: 0,
            with_pending: 0,
            with_shortlisted: 0,
          },
        );
        trackEvent('EMP_Screen_Positions', eventPayload);
      }
    });
  };

  // resetState() {
  //   apiPost("/state/set_zero", {
  //     state_type: "applications"
  //   });
  // }

  render() {
    const { t } = this.props;
    return (
      <div id="jobsHolder">
        <WrappedAlertDialog
          ref={this.firstJobDialog}
          message={{
            title: t('Jobs.firstpositionTitle'),
            text: (
              <span>
                {t('Jobs.firstpositionMsg1')}
                <br />
                <br />
                {t('Jobs.firstpositionMsg2')}
              </span>
            ),
            confirm: t('Jobs.firstpositionButton'),
            confirmColor: 'primary',
          }}
          hideCancel
          onConfirm={() => false}
        />
        <div className="main-jobs-container">
          <CompanySwitcher
            user={this.props.user}
            unread={this.props.companiesUnread}
            type="applications"
            refreshUser={this.props.refreshUser}
          />
          <div className="fixed-fixer" style={{ width: '100%' }}>
            <div>
              <h1>{t('Jobs.title')}</h1>
              <MJNSnackbar
                show={this.state.showSnackbar}
                hide={this.closeSnackBar}
                message={this.state.snackbarMessage}
                color={this.state.snackbarColor}
              />
              <WrappedJobNew
                ref={this.newJobButton}
                onSuccess={this.handleNewJob}
                showCredits={this.props.showCredits}
                user={this.props.user}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handleClickOpen()}
                size="large"
                className="new-job-button"
              >
                {t('Jobs.createjob')}
              </Button>
              <div className="clearfix" />
              <div id="applications_container">
                {this.state.jobs.map((job, i) => (
                  <ApplicationsHolder
                    key={job.id}
                    application={job}
                    totalJobs={this.state.jobs.length}
                    jobIndex={i}
                    handleEditJob={this.handleEditJob}
                    refreshUser={this.props.refreshUser}
                    showCredits={this.props.showCredits}
                    user={this.props.user}
                    onSuccess={this.showSnackBar}
                  />
                ))}
              </div>
              {this.state.jobs.length === 0 && this.state.loaded === true ? (
                <h3 style={{ textAlign: 'center' }}>
                  {t('Jobs.nojobsopen')}
                </h3>
              ) : false}
            </div>
            {this.state.closedJobs.length !== 0
              && this.state.loaded === true ? (
                <div id="closed_jobs">
                  <h2 className="closed-title">{t('Jobs.closedjobs')}</h2>
                  {this.state.closedJobs.map((job) => (
                    <ClosedJobs
                      key={job.id}
                      job={job}
                      user={this.props.user}
                      showCredits={this.props.showCredits}
                      onSuccess={this.handleNewJob}
                    />
                  ))}
                </div>
              ) : false}
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(Jobs);
