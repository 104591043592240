import { Component } from 'react';
import { NavLink } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { withTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Badge from '@mui/material/Badge';
import Warning from '@mui/icons-material/Warning';
import Check from '@mui/icons-material/CheckCircle';
import Report from '@mui/icons-material/Report';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import logo from '../logo.png';
import './Header.css';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const {
      isAuthenticated, user, unread, showCredits,
    } = this.props;
    const addBadge = (elem, count) => (count > 0 ? (
      <Badge
        badgeContent={count}
        color="secondary"
        style={{ fontWeight: 'bold' }}
      >
        {elem}
      </Badge>
    ) : (
      elem
    ));
    const creditsCoin = (jobCredits) => (
      <div
        style={{ position: 'relative', margin: '8px auto 5px' }}
      >
        <img src="/coin.png" width="40" height="40" alt="coin" />
        <div
          style={{
            fontSize: '21px',
            position: 'absolute',
            width: '100%',
            textAlign: 'center',
            top: '8px',
            fontWeight: 'bold',
            color: '#fff',
            textShadow: '1px 1px #777',
          }}
        >
          {jobCredits}
        </div>
      </div>
    );
    const submenu = () => (
      <MediaQuery minDeviceWidth={800}>
        <div>
          <IconButton
            aria-owns={open ? 'menu-appbar' : null}
            aria-haspopup="true"
            onClick={this.handleMenu}
            color="inherit"
            style={{ float: 'right', margin: '10px', color: 'white' }}
            size="small"
          >
            <AccountCircle style={{ fontSize: '36px' }} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={this.handleClose}
          >
            <MenuItem
              onClick={this.handleClose}
              style={{ height: '1px', padding: '0px' }}
            />
            <NavLink to="/profile" className="menu-list-item">
              <MenuItem onClick={this.handleClose}>
                {t('Header.profile')}
              </MenuItem>
            </NavLink>
            <NavLink to="/signout" className="menu-list-item">
              <MenuItem onClick={this.handleClose}>
                {t('Header.signout')}
              </MenuItem>
            </NavLink>
          </Menu>
        </div>
      </MediaQuery>
    );
    return (
      <Paper elevation={6}>
        <div id="header_holder">
          <div id="header_center">
            <NavLink to="/" className="logo-link">
              <img
                id="mjn_logo"
                src={logo}
                width="80"
                alt="MyJobNow Logo"
                style={{ margin: '5px -5px 8px 10px' }}
              />
            </NavLink>
            {isAuthenticated && user && user.onboarding_done === true ? (
              <ul id="header_menu">
                <MediaQuery minDeviceWidth={800}>
                  <li className="header-item">
                    {addBadge(
                      <NavLink to="/jobs" activeClassName="selected">
                        {t('Header.jobs')}
                      </NavLink>,
                      unread.applications,
                    )}
                  </li>
                  <li className="header-item">
                    {addBadge(
                      <NavLink to="/messages" activeClassName="selected">
                        {t('Header.messages')}
                      </NavLink>,
                      unread.conversations,
                    )}
                  </li>
                  <li className="header-item">
                    {addBadge(
                      <NavLink to="/interviews" activeClassName="selected">
                        {t('Header.interviews')}
                      </NavLink>,
                      0,
                    )}
                  </li>
                </MediaQuery>
                {/* <li className="header-item">
                  <NavLink to="/company/create" activeClassName="selected">
                    New Company
                  </NavLink>
                </li> */}
                <button
                  className="header-item koubara"
                  onClick={showCredits}
                  tabIndex={0}
                  type="button"
                  style={{
                    // flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    width: '50px',
                    flexWrap: 'wrap',
                    marginTop: '0px',
                    // user.status_as_client === "on_trial" ? "-20px" : "-8px",
                    color: 'white',
                    cursor: 'pointer',
                    border: 0,
                  }}
                >
                  {/* <CreditKey style={{ fontSize: "32px", width: "100%" }} /> */}
                  {user.status_as_client === 'on_trial' && (
                    <Warning
                      style={{
                        fontSize: '32px',
                        color: '#FDD835',
                        margin: '10px auto',
                      }}
                    />
                  )}
                  {user.status_as_client === 'trial_over' && (
                    <Report
                      style={{
                        fontSize: '32px',
                        color: '#FF3D00',
                        margin: '10px auto',
                      }}
                    />
                  )}
                  {user.status_as_client === 'has_credits' && (user.subscription_expires_at ? (
                    <Check
                      style={{
                        fontSize: '32px',
                        color: '#00C853',
                        margin: '10px auto',
                      }}
                    />
                  ) : (
                    creditsCoin(user.job_credits)
                  ))}
                  {user.status_as_client === 'no_credits' && (
                    creditsCoin(user.job_credits)
                  )}
                </button>
                {submenu()}
              </ul>
            ) : isAuthenticated && (
              <ul id="header_menu">
                <MediaQuery minDeviceWidth={800}>
                  <li className="header-item">
                    {user.email_verified === true ? (
                      <NavLink to="/company/create" activeClassName="selected">
                        {t('Header.addcompany')}
                      </NavLink>
                    ) : (
                      <NavLink to="/verify" activeClassName="selected">
                        {t('Header.verify')}
                      </NavLink>
                    )}
                  </li>
                  {submenu()}
                </MediaQuery>
              </ul>
            )}
            <div className="clearfix" />
          </div>
        </div>
      </Paper>
    );
  }
}
export default withTranslation()(Header);
