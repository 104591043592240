import { Component } from 'react';
import TextField from '@mui/material/TextField';
import { withTranslation } from 'react-i18next';
import CompanyBubble from './CompanyBubble';
import { apiPut } from '../Lib/requests';

class CompanySwitcher extends Component {
  frequentCompanies = {};

  constructor(props) {
    super(props);
    this.state = {
      filter: '',
    };
  }

  componentDidMount() {
    if (localStorage) {
      const fc = JSON.parse(localStorage.getItem('frequentCompanies'));
      if (fc) {
        this.frequentCompanies = fc;
      }
    }
  }

  changeDefaultCompany = (companyId) => {
    apiPut('/company/set_default', {
      id: companyId,
    }).then(() => {
      if (localStorage) {
        this.frequentCompanies[`c${companyId}`] = Date.now();
        localStorage.setItem(
          'frequentCompanies',
          JSON.stringify(this.frequentCompanies),
        );
      }
      this.setState({ filter: '' });
      this.props.refreshUser();
      this.companySwitcher.scrollLeft = 0;
    });
  };

  handleChange = () => (e) => {
    this.setState({ filter: e.target.value });
  };

  render() {
    const { t } = this.props;
    if (
      !this.props.user.companies
      || !this.props.unread
      || !this.props.user.companies.length
      || !Object.keys(this.props.unread).length
    ) {
      return <span />;
    }
    const defaultCompany = this.props.user.default_company;

    const otherCompanies = this.props.user.companies;
    otherCompanies.sort((a, b) => {
      const checkA = this.props.unread[a.id]
        ? this.props.unread[a.id][this.props.type]
        : 0;
      const checkB = this.props.unread[b.id]
        ? this.props.unread[b.id][this.props.type]
        : 0;
      const sum = checkB - checkA;
      if (sum === 0) {
        const updatedB = this.frequentCompanies[`c${b.id}`] || b.id;
        const updatedA = this.frequentCompanies[`c${a.id}`] || a.id;
        return updatedB - updatedA;
      }
      return sum;
    });
    return (
      <div id="company_switcher" ref={(companySwitcher) => { this.companySwitcher = companySwitcher; }}>
        {this.props.user.companies && this.props.user.companies.length >= 5 ? (
          <TextField
            value={this.state.filter}
            placeholder={t('CompanySwitcher.search')}
            onChange={this.handleChange('filter')}
            margin="normal"
            className="company-search"
          />
        ) : null}
        <CompanyBubble
          key={defaultCompany.id}
          company={defaultCompany}
          unread={
            this.props.unread[defaultCompany.id]
              ? this.props.unread[defaultCompany.id][this.props.type]
              : 0
          }
          isDefault
          click={this.changeDefaultCompany}
        />
        {this.props.user.companies
          .filter((company) => company.name.toLowerCase().includes(this.state.filter.toLowerCase()))
          .map((c) => (
            <CompanyBubble
              key={c.id}
              company={c}
              unread={
                this.props.unread[c.id]
                  ? this.props.unread[c.id][this.props.type]
                  : 0
              }
              isDefault={false}
              numOpenJobs={
                this.props.unread[c.id]
                  ? this.props.unread[c.id].openJobs
                  : 1
              }
              click={this.changeDefaultCompany}
            />
          ))}
      </div>
    );
  }
}

export default withTranslation()(CompanySwitcher);
