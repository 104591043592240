import { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

class AlertDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleConfirm = () => {
    this.props.onConfirm();
    this.handleClose();
  };

  render() {
    let t = (a) => {
      console.log('givi', a);
      console.log(this.props);
      return 'Cancel';
    };
    if (this.props.t) {
      t = this.props.t;
    }
    return (
      <div>
        <Dialog
          fullScreen={this.props.fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {this.props.message.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{ whiteSpace: 'pre-line' }}
            >
              {this.props.message.text}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={this.props.fullScreen ? { justifyContent: 'center' } : null}>
            {!this.props.hideCancel ? (
              <Button onClick={this.handleClose} color="primary">
                {t('AlertDialog.cancel')}
              </Button>
            ) : (
              false
            )}
            <Button
              onClick={this.handleConfirm}
              color={
                this.props.message.confirmColor
                  ? this.props.message.confirmColor
                  : 'primary'
              }
              autoFocus
            >
              {this.props.message.confirm}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AlertDialog;
