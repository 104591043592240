import { Component, createRef } from 'react';
import MediaQuery from 'react-responsive';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import SettingsIcon from '@mui/icons-material/Settings';
import WbSunny from '@mui/icons-material/WbSunny';
import { withTranslation } from 'react-i18next';
import AlertDialog from './AlertDialog';
import { apiPost } from '../Lib/requests';
import { trackEvent } from '../Lib/analytics';

const WrappedAlertDialog = withTranslation('translation', { withRef: true })(
  AlertDialog,
);

class ClosedJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.job.id,
    };
    this.alertDialog = createRef();
  }

  handleSubmit = () => {
    apiPost('/job/open', {
      id: this.state.id,
    })
      .then(() => {
        this.props.onSuccess(this.props.t('ClosedJobs.success'));
        const eventName = 'EMP_Action_ReopenPosition';
        trackEvent(eventName, {
          user_id: this.props.user.id,
        });
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 402) {
          this.props.showCredits();
        }
      });
  };

  handlePositionReopenClick = () => {
    this.alertDialog.current.handleClickOpen();
  };

  render() {
    const { skill, description, seasonal } = this.props.job.basic_info;
    const { valid_until: validUntil } = this.props.job.payment_info;
    const { t } = this.props;
    let alertText = t('ClosedJobs.alert');
    if (
      !this.props.user.subscription_expires_at
      && new Date(validUntil) - 60 * 60 * 1000 < new Date()
    ) {
      alertText = `${t('ClosedJobs.alertcredit')} ${alertText}`;
    }
    return (
      <>
        <WrappedAlertDialog
          ref={this.alertDialog}
          message={{
            title: t('ClosedJobs.promptTitle'),
            text: alertText,
            confirm: t('ClosedJobs.promptConfirm'),
            confirmColor: 'primary',
          }}
          onConfirm={this.handleSubmit}
        />
        <Accordion
          defaultExpanded={false}
          onChange={() => false}
          onClick={this.handlePositionReopenClick}
          className="expansionPanel"
        >
          <AccordionSummary className="main-app-container">
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                paddingRight: '0',
              }}
            >
              <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                  <Chip
                    className="skill-badge"
                    avatar={
                      seasonal ? (
                        <Avatar
                          alt="Seasonal"
                          style={{
                            backgroundColor: '#26a2f0',
                            color: '#fefefe',
                          }}
                        >
                          <WbSunny />
                        </Avatar>
                      ) : null
                    }
                    label={skill.display_name}
                  />
                </div>
                <div className="expand-application-count">
                  {t('ClosedJobs.open')}
                </div>
                <button
                  style={{ paddingTop: '5px', color: 'rgba(0, 0, 0, 0.54)' }}
                  className="expand-edit-job"
                  onClick={(e) => {
                    this.handlePositionReopenClick();
                    e.stopPropagation();
                  }}
                  type="button"
                  tabIndex={0}
                >
                  <SettingsIcon />
                </button>
              </div>
              <div style={{ marginTop: '20px' }}>
                <MediaQuery minDeviceWidth={800}>
                  <div
                    style={{
                      marginLeft: '12px',
                      marginRight: '12px',
                      display: 'flex',
                    }}
                  >
                    {description}
                  </div>
                </MediaQuery>
              </div>
            </div>
          </AccordionSummary>
          <Divider />
        </Accordion>
      </>
    );
  }
}

export default withTranslation()(ClosedJobs);
