import { Component } from 'react';
import AddPhoto from '@mui/icons-material/AddAPhoto';
import { withTranslation } from 'react-i18next';

const handleDragover = (e) => {
  e.stopPropagation();
  e.preventDefault();
  e.dataTransfer.dropEffect = 'copy';
};

class PhotoUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
  }

  handleFileSelect = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const files = e.target.files || e.dataTransfer.files; // FileList object
    if (!files || !files.length || !files[0].type.match('image.*')) {
      console.log('IMAGE UPLOAD ERROR');
      return;
    }

    const reader = new FileReader();
    reader.onload = (photo) => {
      this.setState({ file: photo.target.result });
    };
    reader.readAsDataURL(files[0]);
  };

  render() {
    const { t } = this.props;
    return (
      <button
        id="photo_holder"
        onDragOver={handleDragover}
        onDrop={this.handleFileSelect}
        onClick={() => {
          if (!this.state.file) {
            this.photoInput.click();
          }
        }}
        type="button"
        tabIndex={0}
      >
        {this.state.file ? (
          <img
            src={this.state.file}
            alt="new"
            title="new"
            // width="300"
            style={{
              cursor: 'pointer',
              top: '50%',
              width: '100%',
              position: 'relative',
              transform: 'translateY(-50%)',
            }}
            onClick={(e) => this.props.upload(e, 'uploadedPhoto')}
            onLoad={(e) => this.props.upload(e, 'uploadedPhoto')}
            aria-hidden
          />
        ) : (
          <>
            <input
              ref={(photoInput) => { this.photoInput = photoInput; }}
              type="file"
              id="companyphotoupload"
              name="companyphotoupload"
              accept="image/*"
              onChange={this.handleFileSelect}
            />
            <label
              htmlFor="companyphotoupload"
              onClick={(e) => e.stopPropagation()}
              aria-hidden
            >
              <div>{t('PhotoUpload.uploadphoto')}</div>
              <AddPhoto id="add_photo_icon" />
            </label>
          </>
        )}
      </button>
    );
  }
}

export default withTranslation()(PhotoUpload);
