import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useTranslation } from 'react-i18next';

function CompanyPreview({ company }) {
  const { t } = useTranslation();
  return (
    <>
      <h2>{t('CompanyPreview.confirmcompany')}</h2>
      <Card className="company-preview">
        <CardMedia
          image={company.selectedPhoto}
          style={{ height: 0, paddingTop: '56.25%' }}
          title="company photo"
        />
        <CardContent>
          <h2>{company.name}</h2>
          <p>
            <strong>{t('CompanyPreview.phone')}</strong>
            {' '}
            {company.phone}
          </p>
          <p>
            <strong>{t('CompanyPreview.address')}</strong>
            {' '}
            {company.address}
          </p>
        </CardContent>
      </Card>
    </>
  );
}

export default CompanyPreview;
