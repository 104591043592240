import Card from '@mui/material/Card';
import { useTranslation } from 'react-i18next';

export function Verify() {
  const { t } = useTranslation();
  return (
    <div>
      <Card className="add-company-card">
        <h2>{t('Verify.heading')}</h2>
        <p>
          {t('Verify.text1')}
          <br />
          <br />
          {t('Verify.text2')}
          <br />
          <br />
          {t('Verify.text3')}
        </p>
      </Card>
    </div>
  );
}

export default Verify;
